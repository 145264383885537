import { ErrorModalService } from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import i18next, { t } from "i18next";
import { handleResponseNotOK } from "./handleResponseNotOk";

export const GetWorkflowActionState = async (signatureToken: string) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/v/1/lwms/external/action/state",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + signatureToken,
        },
      }
    );

    return await handleResponseNotOK(
      response,
      t("unable_to_check_wf_action_state")
    );
  } catch (e: any) {
    if (e.message && e.message === "handled_error") {
      throw new Error("Handled error");
    } else {
      new ErrorModalService(
        undefined,
        i18next.language,
        null,
        t("unable_to_check_wf_action_state")
      ).showErrorModal();
      console.log(e);
    }
  }
};