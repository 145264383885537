import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import i18n from "i18next";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Spin } from "antd";

import { applicationSettings } from "./appsettings";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "flag-icon-css/css/flag-icon.min.css";
import "antd/dist/antd.min.css";
import { SignatureProvider } from "./components/SigningComponent/SigningComponent";
import SignatureParametersContext from "./store/SignatureParametersContext";
import { HashRouter } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorHandling/ErrorBoundary";
import "@progress/kendo-theme-default/dist/all.css";

const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
const urlLanguage = urlParams.get("lang");
const originParam = urlParams.get("origin")?.split("#")[0];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: applicationSettings.ENABLED_LANGUAGES,
    fallbackLng: "en",

    detection: {
      order: ["path", "cookie", "navigator", "querystring", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "locales/{{lng}}/translation.json",
    },
  });

if(urlLanguage){
  i18n.changeLanguage(urlLanguage);
}

Storage.prototype.setObj = function (key: any, obj: any) {
  return this.setItem(key, JSON.stringify(obj));
};

Storage.prototype.getObj = function (key: any, obj: any) {
  if (this.getItem(key) === "[null]") {
    this.removeItem("SignatorLightSignatureProfiles");
    return;
  }
  try {
    if (this.getItem(key)) return JSON.parse(this.getItem(key)!);
  } catch (e) {
    console.log(e);
    this.removeItem("SignatorLightSignatureProfiles");
  }
};

if (originParam && originParam === "office") {
  try {
    const script = document.createElement("script");
    script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
    document.head.appendChild(script);
  } catch (e) {
    console.log(e);
  }
}

const loadingPage = (
  <div className="py-4 text-center">
    <Spin size="large" style={{ marginTop: "150px" }} />
  </div>
);

//TODO: ADD ORIGIN PARAM TO CONTEXT, AND READ IT ACROSS THE APP INSTEAD OF READING URLPARAMS ON NUMEROUS PLACES IN APP

ReactDOM.render(
  <SignatureParametersContext.Provider
    value={{
      predefinedSignatureOptions: false,
      signatureToken: undefined,
      pdfFile: undefined,
      pdfFileBase64: undefined,
      phoneNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      jurisdiction: undefined,
      signatureLevel: undefined,
      prepaid: undefined,
      storageId: undefined,
      targetIdentUrl: undefined,
      signatureProvider: SignatureProvider.SwisscomAis,
      comingFromThirdParty: false,
      backToDocAfterThirdPartySign: false,
      rejectUrl: undefined,
      isDocumentSigned: false,
      signatureType: "image",
      pdfFileName: i18next.t("untitled_document"),
      loadedInIframe: !!originParam,
      successfulSignature: !!(urlParams.get("signature_successful") && urlParams.get("signature_successful") === "true"),
      unsuccessfulSignature: !!(urlParams.get("signature_unsuccessful") && urlParams.get("signature_unsuccessful") === "true")
    }}
  >
    <HashRouter>
      <ErrorBoundary>
        <Suspense fallback={loadingPage}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </HashRouter>
  </SignatureParametersContext.Provider>,
  document.getElementById("root")
);
