import { ErrorModalService } from '@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService';
import { RefreshTokenMiddleware } from './RefreshTokenMiddleware';
import {basicPOSTRequestOptions} from '../appsettings';
import { getLoggedUserJwt } from '../classes/User';
import {handleResponseNotOK} from "./handleResponseNotOk";
import i18n, {t} from "i18next";

async function RejectInvite(rejectInvitationRequest: { id: string, organizationId: string}) {

    const refreshToken = await RefreshTokenMiddleware();

    if (refreshToken) {
        try {
            const body = {
                id: rejectInvitationRequest.id,
                organizationId: rejectInvitationRequest.organizationId
            };

            const response = await fetch(`${process.env.REACT_APP_URM_BASE_URL}${process.env.REACT_APP_ROLES_URL}/user/invite/reject`,
                basicPOSTRequestOptions(getLoggedUserJwt() ?? '', {...body})
            );

            return await handleResponseNotOK(response, t("error_rejecting_invite"), true);

        } catch (e: any) {
            if (e.message && e.message === 'handled_error') {
                throw new Error('Handled error');
            } else {
                new ErrorModalService(undefined, i18n.language, undefined, t("error_rejecting_invite")).showErrorModal();
                console.log(e.message);
                throw new Error('Error rejecting invite. Please try again');
            }
        }

    } else {
        console.log('Error refreshing token');
        throw new Error('Error refreshing token');
    }
}

export default RejectInvite;
