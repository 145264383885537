import AuthContext from "../../store/AuthContext";
import { useLocation } from "react-router-dom";
import React from "react";

const ProtectedRoute = (props: any) => {
  const authCtx = React.useContext(AuthContext);

  React.useEffect(() => {
    authCtx.setLoading(false);
  }, [])

  return props.children;
};

export default ProtectedRoute;
