import {
    ErrorModalService
} from '@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService';
import {basicGETRequestOptions} from '../appsettings';
import {RefreshTokenMiddleware} from './RefreshTokenMiddleware';
import {getLoggedUserJwt} from '../classes/User';
import i18n, {t} from "i18next";
import {handleResponseNotOK} from "./handleResponseNotOk";

export async function GetAllAppWithinCategory(
    userId: string | undefined,
) {
    const refreshToken = await RefreshTokenMiddleware();

    try {
        if (refreshToken) {
            const response = await fetch(
                `${process.env.REACT_APP_URM_BASE_URL}${process.env.REACT_APP_ROLES_URL}/user/${userId}/application`,
                basicGETRequestOptions(getLoggedUserJwt() ?? '')
            );

            const responseArray = await handleResponseNotOK(response, t("unable_to_get_user_permissions")
            );

            const responseData = responseArray.data

            return responseData

        } else {
            console.log('Error refreshing token');
            throw new Error('Error refreshing token');
        }
    } catch (e: any) {
        if (e.message && e.message === 'handled_error') {
            throw new Error('Handled error');
        } else {
            new ErrorModalService(
                undefined,
                i18n.language,
                null,
              i18n.t("unable_to_fetch_data")
            ).showErrorModal();
            console.log(e);
            throw new Error('Error getting user permissions: ' + e);
        }
    }
}