import React, { useEffect, useRef, useState } from "react";
import Moveable, { MoveableManagerInterface, Renderer } from "react-moveable";
import { FaTrash } from "react-icons/fa";

function SignatureMoveable(props: any) {
  const [moveableTarget, setMoveableTarget] = useState<HTMLElement | undefined>(
    undefined
  );

  const moveableRef = useRef<Moveable>(null);

  let signaturePageElement = document.getElementById("signature-page");
  let bounds = signaturePageElement?.getBoundingClientRect();

  useEffect(() => {
    let effectTimer = setTimeout(() => {
      if (document.getElementById("signature-field")) {
        document.body.style.cursor = "default";
        setMoveableTarget(document.getElementById("signature-field")!);
      } else {
        setTimeout(() => {
          document.body.style.cursor = "default";
          setMoveableTarget(document.getElementById("signature-field")!);
        }, 500);
      }
    }, 200);

    return () => {
      clearTimeout(effectTimer);
    };
  }, [props.addSignature, props.signatureFrame]);

  const DeleteMoveable = {
    name: "deleteViewable",
    props: {
      clickable: true,
    },
    events: {},
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();

      return (
        <div
          key={"delete-viewer"}
          className={"moveable-delete"}
          onClick={props.removeSignatureHandler}
          onTouchStartCapture={
            props.width < 1200 ? props.removeSignatureHandler : undefined
          }
          style={{
            position: "absolute",
            left: `${rect.width / 1 + 10}px`,
            top: `${rect.height - rect.height}px`,
            borderRadius: "2px",
            width: "34px",
            height: "34px",
            color: "red",
            fontSize: "16px",
            padding: "0px 8px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: `translate(-${rect.width + 50}px, 0px)`,
          }}
        >
          <FaTrash />
        </div>
      );
    },
  } as const;

  return (
    <Moveable
      ables={[DeleteMoveable]}
      props={{
        deleteViewable: true,
        signViewable: true,
      }}
      target={moveableTarget}
      ref={moveableRef}
      resizable={true}
      snappable={true}
      keepRatio={true}
      throttleScale={0}
      draggable={true}
      throttleDrag={0}
      startDragRotate={0}
      bounds={{
        left: 0,
        top: 0,
        right: bounds?.width,
        bottom: bounds?.height,
      }}
      throttleDragRotate={0}
      throttleResize={0}
      renderDirections={["nw", "ne", "sw", "se"]}
      edge={false}
      zoom={1}
      origin={false}
      padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
      onResizeStart={(e) => {
        e.setOrigin(["%", "%"]);
        e.dragStart && e.dragStart.set(props.signatureFrame.translate);
      }}
      onResize={(e) => {
        const beforeTranslate = e.drag.beforeTranslate;
        props.setSignatureFrame({
          type: "ON_RESIZE",
          translate: [beforeTranslate[0], beforeTranslate[1]],
          size: [e.width, e.height],
          scale: props.renderProps.scale,
        });
        e.target.style.width = `${e.width}px`;
        e.target.style.height = `${e.height}px`;
        e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
      }}
      onDragStart={(e) => {
        e.set(props.signatureFrame.translate);
      }}
      onDrag={(e) => {
        e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
        props.setSignatureFrame({
          type: "ON_DRAG",
          translate: [e.beforeTranslate[0], e.beforeTranslate[1]],
        });
      }}
      onDragEnd={(e) => {
        let movePageDown = bounds!.height - (e.currentTarget.state.height + 1);
        if (
          e.lastEvent?.translate[1] <= 1 &&
          props.signatureFrame.signaturePage - 1 > 0
        ) {
          //introducing height, width and size variables for cases where PDF pages dimensions are not the same, and checking for this scenario below
          let translateWidthPosition = 0;
          let translateHeightPosition = 0;
          let signatureSize = props.signatureFrame.size;

          //WORKAROUND FOR BROWSER THAT ARE NOT CHROME - THEY DON'T HAVE PATH PROPERTY
          if (e.lastEvent.inputEvent.path === undefined) {
            let getTargetElement = e.lastEvent.inputEvent.target.offsetParent.offsetParent;
            if (
              getTargetElement &&
              getTargetElement.className === "rpv-core__page-layer"
            ) {
              if (getTargetElement.attributes["data-testid"].textContent) {
                if (
                  props.signatureFrame.signaturePage -
                    Math.abs(
                      parseInt(
                        getTargetElement.attributes[
                          "data-testid"
                        ].textContent.slice(-1)
                      ) + 1
                    ) >
                  1
                ) {
                  props.setSignatureFrame({
                    type: "ON_PAGE_CHANGE_UP",
                    size: [
                      150 * props.signatureFrame.scale,
                      75 * props.signatureFrame.scale,
                    ],
                    signaturePage: props.signatureFrame.signaturePage - 1,
                    translate: [50, 50],
                    opacity: "0%",
                  });
                  setTimeout(() => {
                    props.setSignatureFrame({
                      type: "ON_CHANGE_OPACITY",
                      opacity: "100%",
                    });
                  }, 200);
                } else {
                  let pageSizeDiff = Math.abs(
                    e.lastEvent.currentTarget.props.bounds.right -
                      getTargetElement.clientWidth
                  );
                  if (pageSizeDiff > 20) {
                    translateWidthPosition =
                      getTargetElement.clientWidth / 2 -
                      (150 * props.signatureFrame.scale) / 2;
                    signatureSize = [
                      150 * props.signatureFrame.scale,
                      75 * props.signatureFrame.scale,
                    ];
                  } else {
                    translateWidthPosition = e.lastEvent.translate[0];
                  }
                  translateHeightPosition =
                    getTargetElement.clientHeight - signatureSize[1];
                  props.setSignatureFrame({
                    type: "ON_PAGE_CHANGE_UP",
                    size: signatureSize,
                    signaturePage: props.signatureFrame.signaturePage - 1,
                    translate: [
                      translateWidthPosition,
                      translateHeightPosition - 30,
                    ],
                    opacity: "0%",
                  });
                  setTimeout(() => {
                    props.setSignatureFrame({
                      type: "ON_CHANGE_OPACITY",
                      opacity: "100%",
                    });
                  }, 200);
                }
              } else {
                props.setSignatureFrame({
                  type: "ON_PAGE_CHANGE_UP",
                  size: [
                    150 * props.signatureFrame.scale,
                    75 * props.signatureFrame.scale,
                  ],
                  signaturePage: props.signatureFrame.signaturePage - 1,
                  translate: [50, 50],
                  opacity: "0%",
                });
                setTimeout(() => {
                  props.setSignatureFrame({
                    type: "ON_CHANGE_OPACITY",
                    opacity: "100%",
                  });
                }, 200);
              }
            } else {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_UP",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage - 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
          }
          //TODO: CHROME PART - it seems as of 25.08. this doesn't work anymore, look for an alternative of remove this
          else {
            let dropPdfViewerElement = e.lastEvent.inputEvent.path[0];
            //if dropPdfViewerElement is not defined or drop element is not part of pdf viewer, then default sig image to predefined location
            if (
              dropPdfViewerElement?.children[0]?.clientWidth === undefined ||
              dropPdfViewerElement.className !== "rpv-core__page-layer"
            ) {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_UP",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage - 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
            //if drop element is more than 1 page difference from current signature image page, then default sig image to predefined location
            else if (
              props.signatureFrame.signaturePage -
                Math.abs(e.lastEvent.inputEvent.path[1].ariaLabel.slice(-1)) >
              1
            ) {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_UP",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage - 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            } else {
              //calculate difference between page sizes to check if position should be defaulted to avoid placing signature image outside of page boundaries
              let pageSizeDiff = Math.abs(
                e.lastEvent.currentTarget.props.bounds.right -
                  e.lastEvent.inputEvent.path[0].children[0].clientWidth
              );
              if (pageSizeDiff > 20) {
                translateWidthPosition =
                  e.lastEvent.inputEvent.path[0].children[0].clientWidth / 2 -
                  (150 * props.signatureFrame.scale) / 2;
                signatureSize = [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ];
              } else {
                translateWidthPosition = e.lastEvent.translate[0];
              }
              translateHeightPosition =
                e.lastEvent.inputEvent.path[0].children[0].clientHeight -
                signatureSize[1];
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_UP",
                size: signatureSize,
                signaturePage: props.signatureFrame.signaturePage - 1,
                translate: [
                  translateWidthPosition,
                  translateHeightPosition - 30,
                ],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
          }
        } else if (
          e.lastEvent?.translate[1] >= movePageDown &&
          props.renderProps.doc.numPages > props.signatureFrame.signaturePage
        ) {
          //introducing height, width and size variables for cases where PDF pages dimensions are not the same, and checking for this scenario below
          let translateWidthPosition = 0;
          let signatureSize = props.signatureFrame.size;
          //getting target element for drop
          //WORKAROUND FOR BROWSER THAT ARE NOT CHROME - THEY DON'T HAVE PATH PROPERTY
          if (!e.lastEvent.inputEvent.path) {
            let getTargetElement = e.lastEvent.inputEvent.target.offsetParent.offsetParent;
            if (
              getTargetElement &&
              getTargetElement.className === "rpv-core__page-layer"
            ) {
              if (getTargetElement.attributes["data-testid"].textContent) {
                if (
                  Math.abs(
                    parseInt(
                      getTargetElement.attributes[
                        "data-testid"
                      ].textContent.slice(-1)
                    ) + 1
                  ) -
                    props.signatureFrame.signaturePage >
                  1
                ) {
                  props.setSignatureFrame({
                    type: "ON_PAGE_CHANGE_DOWN",
                    size: [
                      150 * props.signatureFrame.scale,
                      75 * props.signatureFrame.scale,
                    ],
                    signaturePage: props.signatureFrame.signaturePage + 1,
                    translate: [50, 50],
                    opacity: "0%",
                  });
                  setTimeout(() => {
                    props.setSignatureFrame({
                      type: "ON_CHANGE_OPACITY",
                      opacity: "100%",
                    });
                  }, 200);
                } else {
                  let pageSizeDiff = Math.abs(
                    e.lastEvent.currentTarget.props.bounds.right -
                      getTargetElement.clientWidth
                  );
                  if (pageSizeDiff > 20) {
                    translateWidthPosition =
                      getTargetElement.clientWidth / 2 -
                      (150 * props.signatureFrame.scale) / 2;
                    signatureSize = [
                      150 * props.signatureFrame.scale,
                      75 * props.signatureFrame.scale,
                    ];
                  } else {
                    translateWidthPosition = e.lastEvent.translate[0];
                  }
                  props.setSignatureFrame({
                    type: "ON_PAGE_CHANGE_DOWN",
                    size: signatureSize,
                    signaturePage: props.signatureFrame.signaturePage + 1,
                    translate: [translateWidthPosition, 30],
                    opacity: "0%",
                  });
                  setTimeout(() => {
                    props.setSignatureFrame({
                      type: "ON_CHANGE_OPACITY",
                      opacity: "100%",
                    });
                  }, 200);
                }
              } else {
                props.setSignatureFrame({
                  type: "ON_PAGE_CHANGE_DOWN",
                  size: [
                    150 * props.signatureFrame.scale,
                    75 * props.signatureFrame.scale,
                  ],
                  signaturePage: props.signatureFrame.signaturePage + 1,
                  translate: [50, 50],
                  opacity: "0%",
                });
                setTimeout(() => {
                  props.setSignatureFrame({
                    type: "ON_CHANGE_OPACITY",
                    opacity: "100%",
                  });
                }, 200);
              }
            } else {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_DOWN",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage + 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
          }
          //TODO: CHROME PART - it seems as of 25.08. this doesn't work anymore, look for an alternative of remove this
          else {
            let dropPdfViewerElement = e.lastEvent.inputEvent.path[0];
            //if dropPdfViewerElement is not defined or drop element is not part of pdf viewer, then default sig image to predefined location
            if (
              dropPdfViewerElement?.children[0]?.clientWidth === undefined ||
              dropPdfViewerElement.className !== "rpv-core__page-layer"
            ) {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_DOWN",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage + 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
            //if drop element is more than 1 page difference from current signature image location, then default sig image to predefined location
            else if (
              Math.abs(e.lastEvent.inputEvent.path[1].ariaLabel.slice(-1)) -
                props.signatureFrame.signaturePage >
              1
            ) {
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_DOWN",
                size: [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ],
                signaturePage: props.signatureFrame.signaturePage + 1,
                translate: [50, 50],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            } else {
              //calculate difference between page sizes to check if position should be defaulted to avoid placing signature image outside of page boundaries
              let pageSizeDiff = Math.abs(
                e.lastEvent.currentTarget.props.bounds.right -
                  e.lastEvent.inputEvent.path[0].children[0].clientWidth
              );
              if (pageSizeDiff > 20) {
                translateWidthPosition =
                  e.lastEvent.inputEvent.path[0].children[0].clientWidth / 2 -
                  (150 * props.signatureFrame.scale) / 2;
                signatureSize = [
                  150 * props.signatureFrame.scale,
                  75 * props.signatureFrame.scale,
                ];
              } else {
                translateWidthPosition = e.lastEvent.translate[0];
              }
              props.setSignatureFrame({
                type: "ON_PAGE_CHANGE_DOWN",
                size: signatureSize,
                signaturePage: props.signatureFrame.signaturePage + 1,
                translate: [translateWidthPosition, 30],
                opacity: "0%",
              });
              setTimeout(() => {
                props.setSignatureFrame({
                  type: "ON_CHANGE_OPACITY",
                  opacity: "100%",
                });
              }, 200);
            }
          }
        } else {
          return;
        }
      }}
    />
  );
}

export default SignatureMoveable;
