import AtrustIcon from "../images/a-trust_logo.png"
import SwissComIcon from "../images/swisscomlogo.png"
import Sign8Icon from "../images/sign8.png"
import CertiliaIcon from "../images/certilia.png"
import DTrustIcon from "../images/dtrust.png"
import InfoCertIcon from "../images/infocert.png"
export interface IProvider {
  providerName: string,
  providerKey: string,
  providerEnvVariableName: string
  iframe: boolean,
  needsUserName: boolean
  providerIcon: any
}
export const ProvidersSettings: Array<IProvider> = [
  {
    providerName: "A-Trust",
    providerKey: "ATrustCscV1",
    iframe: true,
    providerEnvVariableName: "atrust",
    needsUserName: false,
    providerIcon: AtrustIcon
  },
  {
    providerName: "SwisscomEtsi",
    providerKey: "SwisscomEtsiV1",
    iframe: false,
    providerEnvVariableName: "swisscometsi",
    needsUserName: false,
    providerIcon: SwissComIcon
  },
  {
    providerName: "Sign8",
    providerKey: "Sign8CscV2",
    iframe: false,
    providerEnvVariableName: "sign8",
    needsUserName: false,
    providerIcon: Sign8Icon
  },
  {
    providerName: "Certilia",
    providerKey: "CertiliaCommercialV1",
    iframe: false,
    providerEnvVariableName: "certilia",
    needsUserName: false,
    providerIcon: CertiliaIcon
  },
  {
    providerName: "D-Trust",
    providerKey: "DTrustV1",
    iframe: false,
    providerEnvVariableName: "dtrust",
    needsUserName: true,
    providerIcon: DTrustIcon
  },
  {
    providerName: "InfoCert",
    providerKey: "InfocertCscV1",
    iframe: true,
    providerEnvVariableName: "infocert",
    needsUserName: true,
    providerIcon: InfoCertIcon
  }
]