import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import { v4 as uuid } from "uuid";
import axios from "axios";
import {
  IeSignLiteSignatureArray,
  IeSignLiteSignatureOnlyArray,
  storage,
} from "./indexDb";
import i18next from "i18next";
import {keycloak} from "./Keycloak";
import {userBuilder} from "../classes/User";
import {GetSignatureCard} from "./GetSignatureCard";

export const toUint8Array = async (file: File) => {
  let tempFile = file as Blob;
  let convertedFile = await tempFile.arrayBuffer();
  return new Uint8Array(convertedFile);
};

export function splitBase64(signatureImage: string) {
  let splitData = (signatureImage as string)?.split(";base64,");
  if (splitData?.length === 2) {
    return splitData[1];
  } else {
    return splitData[0];
  }
}

export const base64_arraybuffer = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url.split(",", 2)[1];
};

export const base64_arraybuffer_no_split = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url;
};

export function base64toBlob(base64Data: string, contentType: string) {
  contentType = contentType || "";
  let sliceSize = 1024;
  let byteCharacters = atob(base64Data);
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);

    let bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const blobPdfFromBase64String = (base64String: string) => {
  const byteArray = Uint8Array.from(
    atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  return new Blob([byteArray], { type: "application/pdf" });
};

export const createSignatureProfileWithImageInLocalStorage = (
  signatureImage: string,
  signatureProvider:
    | "ATrustCscV1"
    | "SwisscomEtsiV1"
    | "Sign8CscV2"
    | "CertiliaCommercialV1"
    | "DTrustV1"
    | "InfocertCscV1",
  name: string
) => {
  storage
    .getItem<{
      eSignLiteSignature: IeSignLiteSignatureArray;
      eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
    }>("eSignLite")
    .then((result) => {
      let profileOne = {
        txtSigImageProfileName: i18next.t("default_image_profile"),
        UseHandwrittenStyle: true,
        txtName: name,
        txtSignatureImagePicture: signatureImage,
        txtSignatureProvider: signatureProvider,
        txtType: "IMAGE",
        default: false,
        profileId: uuid(),
      };

      let profileTwo = {
        txtSigImageProfileName: i18next.t("default_no_image_profile"),
        txtName: name,
        txtSignatureProvider: signatureProvider,
        txtType: "NO_IMAGE",
        default: false,
        profileId: uuid(),
      };

      storage
        .setItem("eSignLite", {
          eSignLiteSignatureOnly: {
            items: [profileTwo],
          },
          eSignLiteSignature: {
            items: [profileOne],
          },
        })
        .then((res) => {})
        .catch((e) => console.log(e));
    })
    .catch((e) => {
      new ErrorModalService(29).showErrorModal();
      console.log(e);
    });
};

export const createSignatureImage = async (
  profile: any,
  token: string
): Promise<any> => {
  let responseImage = undefined;
  let remoteSignatureImage = profile.txtSignatureImagePicture ?? undefined;
  let remoteSignatureLogo = profile.txtLogo ?? undefined;

  if(profile.remoteProfile && profile.profileId){
    try {
      const getSignatureImage = await GetSignatureCard(profile.profileId);

      if(getSignatureImage){
        remoteSignatureImage = getSignatureImage.signatureImage;
        remoteSignatureLogo = getSignatureImage.logo;
      }
    } catch (e) {
      console.log("Unable to fetch signature profile data", e);
    }
  }

  await axios
    .post(
      process.env.REACT_APP_BASE_URL &&
        process.env.REACT_APP_SIGNATURE_IMAGE_PREVIEW_URL
        ? process.env.REACT_APP_BASE_URL +
            process.env.REACT_APP_SIGNATURE_IMAGE_PREVIEW_URL
        : "",
      {
        Page: 0,
        WidgetOffsetX: 231,
        WidgetOffsetY: 359,
        WidgetHeight: 75,
        WidgetWidth: 150,
        Appearance: profile.Appearance,
        UseHandwrittenStyle: false,
        ImageData: profile.UseHandwrittenStyle
          ? null
          : remoteSignatureImage ?? profile.txtSignatureImagePicture,
        LineOne: profile.txtName,
        LineTwo: profile.txtPosition,
        LineThree: profile.txtDepartment,
        LineFour: profile.txtCompanyName,
        LogoImageData: remoteSignatureLogo ?? profile.txtLogo,
      },
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(async (response) => {
      return await base64_arraybuffer_no_split(response.data);
    })
    .then((response) => {
      responseImage = response;
    })
    .catch((e) => {
      throw new Error("ERROR");
    });

  return {responseImage, remoteSignatureImage , remoteSignatureLogo};
};

export const signedDocDownload = (token: string) => {
  return axios
    .get(
      process.env.REACT_APP_BASE_URL + `/v/1/lwms/external/document/download`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const isNameEmpty = (value: string) => !value.replace(/\s/g, "").length;

export async function initOffice() {
  await Office.onReady();
}

export async function loadOfficeJsAndTryToRunFunctionHandler(
  officeOnReadyFn: Function
) {
  try {
    const script = document.createElement("script");
    script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
    document.head.appendChild(script);

    setTimeout(async () => {
      await officeOnReadyFn();
    }, 500);
  } catch (e) {
    return;
  }
}

export async function officeFunctionHandler(officeOnReadyFn: Function) {
  try {
    await officeOnReadyFn();
  } catch (e) {
    console.log(
      "RUNNING OFFICE SCRIPT ERROR, TRYING TO LOAD OFFICE SCRIPT AND TRY AGAIN ",
      e
    );
    await loadOfficeJsAndTryToRunFunctionHandler(officeOnReadyFn);
    return;
  }
}

export const getEnabledProviders = () => {
  if (process.env.REACT_APP_ENABLED_PROVIDERS)
    return process.env.REACT_APP_ENABLED_PROVIDERS.split(",");
};

export const transformSignatureProfiles = async (sigProfile: any) => {
  //const getSignatureImage = await GetSignatureCard(sigProfile.id);

  let getUserInitials = "";

  if(keycloak.token){
    let userObject = userBuilder(keycloak.token)
    getUserInitials = `${userObject?.FirstName[0]} ${userObject?.LastName[0]}`;
  }

  if (sigProfile) {
    let updatedSigProfile = {
      UseHandwrittenStyle: false,
      txtName: sigProfile.profile && sigProfile.profile === 1  ? getUserInitials : sigProfile.firstName + " " + sigProfile.lastName,
      txtSignatureImagePicture: sigProfile.signatureImage,
      txtPosition: sigProfile.role ?? "",
      txtDepartment: sigProfile.department ?? "",
      txtCompanyName: sigProfile.organizationName ?? "",
      txtSignatureProvider: "",
      Appearance: sigProfile.profile && sigProfile.profile === 1 ? 0 : 1,
      profileName: sigProfile.profileName,
      profileId: sigProfile.id,
      remoteProfile: true,
      txtType: "IMAGE",
      txtSigImageProfileName: sigProfile.profileName
    };

    if (sigProfile.imageStyle === 2) {
      updatedSigProfile.UseHandwrittenStyle = true;
    }

    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_ATRUST_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "ATrustCscV1";
    }
    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_CERTILIA_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "CertiliaCommercialV1";
    }
    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_SWISSCOM_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "SwisscomEtsiV1";
    }

    return updatedSigProfile;
  } else {
    return null;
  }
};

export const transformSignatureProfilesNoImage = async (sigProfile: any) => {
  if (sigProfile) {
    let updatedSigProfile = {
      profileId: sigProfile.id,
      txtSignatureProvider: "",
    };

    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_ATRUST_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "ATrustCscV1";
    }
    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_CERTILIA_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "CertiliaCommercialV1";
    }
    if (
      sigProfile?.providerId &&
      sigProfile?.providerId === process.env.REACT_APP_SWISSCOM_PROVIDER_ID
    ) {
      updatedSigProfile.txtSignatureProvider = "SwisscomEtsiV1";
    }

    return updatedSigProfile;
  } else {
    return null;
  }
};
