import i18next from "i18next";
import { Checkbox, Input, Modal } from "antd";
import { Form } from "react-final-form";
import React, { useContext, useEffect, useState } from "react";
import styles from "./FirstTimeVisitorModal.module.css";
import {
  createSignatureProfileWithImageInLocalStorage,
  isNameEmpty,
  splitBase64,
} from "../../services/utilityServices";
import { ErrorModalService } from "../ErrorHandling/ErrorModalService";
import { Loader } from "../Loader";
import { IProvider } from "../../store/ProvidersSettings";
import { ProviderButton } from "../ProviderButton/ProviderButton";
import AuthContext from "../../store/AuthContext";

interface IFirstTimeVisitorModal {
  showModal: boolean;
  closeModal: Function;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  phoneNumber: string | undefined | null;
  jurisdiction: string | undefined | null;
}

export const FirstTimeVisitorModal = ({
  jurisdiction,
  firstName,
  lastName,
  phoneNumber,
  showModal,
  closeModal,
}: IFirstTimeVisitorModal) => {
  const authCtx = useContext(AuthContext);

  const [name, setName] = useState("");
  const [acceptTC, setAcceptTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jurisdictionState, setJurisdictionState] = useState(jurisdiction);
  const [selectedProvider, setSelectedProvider] = useState<
    | "ATrustCscV1"
    | "SwisscomEtsiV1"
    | "Sign8CscV2"
    | "CertiliaCommercialV1"
    | "DTrustV1"
    | "InfocertCscV1"
    | undefined
  >(authCtx.enabledProviders.length === 1 ? authCtx.enabledProviders[0].providerKey : undefined);

  useEffect(() => {
    if (firstName && lastName) {
      setName(firstName + " " + lastName);
    }
  }, []);

  const submitCreateDefaultProfile = (values: any) => {
    if (name === "" || name === undefined || acceptTC === false) {
      console.log("Missing required fields");
      new ErrorModalService(31).showErrorModal();
      return;
    } else {
      setLoading(true);
      document.fonts.load("10vmin Caveat").then(() => {
        let canvas = document.createElement("canvas");
        canvas.width = 600;
        canvas.height = 300;
        let ctx = canvas.getContext("2d");
        ctx!.textAlign = "center";
        ctx!.font = "10vmin Caveat";
        ctx!.fillText(name, 300, 150);
        let signatureImage = document.createElement("img");
        signatureImage.src = canvas.toDataURL();
        let signatureImageBase64 = splitBase64(signatureImage.src);

        try {
          localStorage.setItem("eSignLiteTCAccepted", "true");

          if (!selectedProvider) {
            throw new Error("No signature provider selected");
          }

          createSignatureProfileWithImageInLocalStorage(
            signatureImageBase64,
            selectedProvider,
            name
          );

          setTimeout(() => {
            closeModal();
            setLoading(false);
          }, 500);
        } catch (e) {
          new ErrorModalService(29).showErrorModal();
          setLoading(false);
          return;
        }
      });
    }
  };

  return (
    <Modal
      visible={showModal}
      className={"FirstTimeVisitorModal"}
      title={
        <div>
          {i18next.t("create_default_profile_title")}
          <hr />
        </div>
      }
      footer={null}
      onCancel={() => closeModal()}
      maskClosable={false}
    >
      {loading ? (
        <Loader />
      ) : (
        <Form
          onSubmit={submitCreateDefaultProfile}
          initialValues={{
            profilePhoneNumber: phoneNumber
              ? phoneNumber.startsWith("+")
                ? phoneNumber
                : "+" + phoneNumber.trim()
              : undefined,
            signatureStandard: jurisdictionState,
          }}
        >
          {({ handleSubmit, values }) => {
            return (
              <form id="default-profiles-form" onSubmit={handleSubmit}>
                <div className={styles.SignatorProfilesForm}>
                  <div className={styles.SignatorProfilesForm_Text}>
                    <div className={styles.SignatorProfilesForm_Text_Title}>
                      {i18next.t("welcome_modal_title")}
                    </div>
                    <div className={styles.SignatorProfilesForm_Text_Subtitle}>
                      {i18next.t("welcome_modal_message")} <br />
                      {i18next.t("create_signature_modal_message")}
                    </div>
                  </div>
                  <div className={styles.SignatureProvidersContainer}>
                    {authCtx.enabledProviders.map((item: IProvider, index) => {
                      return (
                        <ProviderButton
                          radioFieldName={item.providerName}
                          providerValue={item.providerKey}
                          providerIcon={item.providerIcon}
                          selectedProvider={selectedProvider}
                          setSelectedProvider={setSelectedProvider}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "15px",
                      marginTop: "10px",
                    }}
                  >
                    <Input
                      style={{
                        marginBottom: "15px",
                        height: "40px",
                        borderRadius: "0.25rem",
                      }}
                      placeholder={i18next.t("first_and_last_name")}
                      name={"profileName"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                    <Checkbox
                      name={"txtAcceptTC"}
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        color: "#8c8b8b",
                      }}
                      onChange={(e) => setAcceptTC(e.target.checked)}
                    >
                      {i18next.t("accept")}{" "}
                      <a
                        href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {i18next.t("terms_and_conditions")}
                      </a>
                      {" & "}
                      <a
                        href={process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {i18next.t("privacy_policy")}
                      </a>
                    </Checkbox>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <button
                      disabled={
                        acceptTC === false ||
                        name === "" ||
                        name === undefined ||
                        isNameEmpty(name) ||
                        !selectedProvider
                      }
                      type={"submit"}
                      className={styles.SignStepButton}
                    >
                      {i18next.t("create_label")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};