import { INDEXEDDB, createInstance } from 'localforage'
import {Jurisdiction, SignatureLevel, SignatureProvider} from "../components/SigningComponent/SigningComponent";

export interface IeSignLiteSignatureArray {
    items: Array<IeSignLiteSignature>
}

export interface IeSignLiteSignatureOnlyArray {
    items: Array<IeSignLiteSignatureOnly>
}

export interface IeSignLiteSignatureOnly {
    default: boolean,
    profileId: string,
    txtMobitel?: string | undefined,
    txtSignatureLevel?: SignatureLevel | undefined
    txtSignatureProvider?: SignatureProvider
    txtSignatureStandard?: Jurisdiction
    txtType: string
    txtCompanyName?: string | undefined
    txtDepartment?: string
    txtLogo?: string
    txtPosition?: string
}

export interface IeSignLiteSignature {
    default: boolean,
    profileId: string,
    txtMobitel?: string | undefined,
    txtSigImageProfileName: string,
    txtSignatureLevel?: SignatureLevel | undefined
    txtSignatureProvider?: SignatureProvider
    txtSignatureStandard?: Jurisdiction
    txtType: string
    txtCompanyName?: string | undefined
    txtDepartment?: string
    txtLogo?: string
    txtPosition?: string
    txtSignatureImagePicture: string
}

export const storage = createInstance({
    driver: INDEXEDDB,
    version: 1.0,
    name: 'eSignLite',
})
