import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CtaButtonComponent } from "@signator/signator_react_components";
import {
  EButtonMode,
  ELoaderMode,
} from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";
import { RejectSignature } from "../SigningComponent/RejectSignature";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import { useNavigate } from "react-router-dom";
import {
  ErrorModalService
} from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import i18next from "i18next";

interface IRejectWfDialog {
  isRejectDialogVisible: boolean;
  setIsRejectDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RejectWfDialog = ({
  isRejectDialogVisible,
  setIsRejectDialogVisible,
}: IRejectWfDialog) => {
  const [rejectMessage, setRejectMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const { t } = useTranslation();

  function isCancelMessageValid(message: string) {
    return message.trim().length < 3;
  }

  async function handleCancelProcess() {
    try {
      setIsLoading(true);
      const response = await RejectSignature(
        signatureParamsCtx.signatureToken ?? "",
        rejectMessage
      );
      if (response) {
        setIsRejectDialogVisible(false);
        setRejectMessage("");
        setIsLoading(false);
        navigate("/", { state: { wfRejected: true } });
      } else {
        throw new Error("Unable to reject workflow");
      }
    } catch (error: any) {
      if(error.response && error.response?.data?.errorCode === 183 ){
        new ErrorModalService(undefined, i18next.language, null, i18next.t("signator_document_already_signed")).showErrorModal();
      } else if(error.response && error.response?.data?.errorCode === 184){
        new ErrorModalService(undefined, i18next.language, null, i18next.t("signator_document_expired")).showErrorModal();
      } else {
        new ErrorModalService(2, i18next.language, null, 'ServiceNotAvailable').showErrorModal();
      }
      console.error("Error rejecting workflow: ", error);
      setIsRejectDialogVisible(false);
      setIsLoading(false);
    }
  }

  function closeAndResetDialog() {
    setIsRejectDialogVisible(false);
    setRejectMessage("");
  }

  return (
    <Dialog
      minWidth={300}
      width={500}
      title={t("reject_signing_header") ?? "Reject signing"}
      className={"wizardDialog"}
      onClose={() => closeAndResetDialog()}
    >
      <div className={"reminderDialogContentContainer"}>
        <label>{t("reject_desc")}:</label>
        <textarea
          className={"reminderDialogTextarea"}
          value={rejectMessage}
          onChange={(e) => setRejectMessage(e.target.value)}
          placeholder={t("start_typing") ?? "Start typing..."}
        />

        <div className={"reminderDialogBtnContainer"}>
          <CtaButtonComponent
            mode={EButtonMode.Outline}
            type={"button"}
            btnText={t("close_label")}
            onClickFunction={() => closeAndResetDialog()}
            loaderMode={ELoaderMode.Light}
          />
          <CtaButtonComponent
            mode={EButtonMode.Filled}
            type={"button"}
            btnText={t("reject_button")}
            isCtaLoading={isLoading}
            isBtnDisabled={isCancelMessageValid(rejectMessage) || isLoading}
            onClickFunction={handleCancelProcess}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default RejectWfDialog;
