import { ErrorModalService } from '@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService';
import { RefreshTokenMiddleware } from './RefreshTokenMiddleware';
import {basicPOSTRequestOptions} from '../appsettings';
import { getLoggedUserJwt } from '../classes/User';
import {handleResponseNotOK} from "./handleResponseNotOk";
import i18n, {t} from "i18next";

async function AcceptInvite(acceptInvitationRequest: { id: string, organizationId: string}) {

    const refreshToken = await RefreshTokenMiddleware();

    if (refreshToken) {
        try {
            const body = {
                id: acceptInvitationRequest.id,
                organizationId: acceptInvitationRequest.organizationId
            };


            const response = await fetch(`${process.env.REACT_APP_URM_BASE_URL}${process.env.REACT_APP_ROLES_URL}/user/invite/accept`,
                basicPOSTRequestOptions(getLoggedUserJwt() ?? '',  { ...body })
            );

            return await handleResponseNotOK(response, t("error_accepting_invite"), true);

        } catch (e: any) {
            if (e.message && e.message === 'handled_error') {
                throw new Error('Handled error');
            } else {
                new ErrorModalService(
                  undefined,
                  i18n.language,
                  null,
                  i18n.t("error_accepting_invite")
                ).showErrorModal();
                console.log(e.message);
                throw new Error('Error accepting invitation');
            }
        }

    } else {
        console.log('Error refreshing token');
        throw new Error('Error refreshing token');
    }
}

export default AcceptInvite;
