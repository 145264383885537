import React, { Dispatch, SetStateAction } from "react";

interface IAuthContext {
    loggedIn: boolean
    setLoggedIn: Function
    loading: boolean
    setLoading: Function
    updateBalance: Date
    setUpdateBalance: Function
    isSigningActive: boolean,
    setIsSigningActive: Dispatch<SetStateAction<boolean>>,
    enabledProviders: any[]
}

const AuthContext = React.createContext<IAuthContext>({
    loggedIn: false,
    setLoggedIn: () => {},
    loading: false,
    setLoading: () => {},
    updateBalance: new Date(),
    setUpdateBalance: () => {},
    isSigningActive: false,
    setIsSigningActive: () => {},
    enabledProviders: []
})

export default AuthContext;