import i18next from 'i18next'
import React from 'react'

import styles from './Loader.module.css'

export const Loader: React.FC = () => (
  <div className={styles.loader}>
    {i18next.t("loading_text")}
  </div>
)
