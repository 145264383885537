import {useEffect, useState} from "react";
import {ProvidersSettings} from "./ProvidersSettings";

export const useGetEnabledProviders = () => {
  const [enabledProviders, setEnabledProviders] = useState<Array<any>>([]);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLED_PROVIDERS) {
      const allProviders = ProvidersSettings;
      const getEnabledProviders = allProviders.filter((provider) =>
        process.env.REACT_APP_ENABLED_PROVIDERS?.split(",").includes(
          provider.providerEnvVariableName
        )
      );
      setEnabledProviders(getEnabledProviders);
    }
  }, []);

  return { enabledProviders };
}