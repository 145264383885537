import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./LandingPage.module.css";
import Vizibit from "../images/vizibit.png";
import PoweredByLogo from "../images/signOneLogo.svg";
import i18next from "i18next";
import Toolbar from "../components/SiteHeader/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CtaButtonComponent,
  LoaderComponent,
} from "@signator/signator_react_components";
import {
  EButtonMode,
  ELoaderSize,
} from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";
import AuthContext from "../store/AuthContext";
import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import BigActionButton from "@signator/signator_react_components/dist/components/BigActionButton";
import { CreateWorkflow } from "../services/CreateWorkflow";
import LandingCards from "./LandingCards/LandingCards";
import { keycloak } from "../services/Keycloak";
import DropZone from "../components/DropZone/DropZone";
import SignatureParametersContext from "../store/SignatureParametersContext";

const LandingPage = () => {
  const [loadingFile, setLoadingFile] = useState(true);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [workflowInDraft, setWorkflowInDraft] = useState(false);

  const authCtx = useContext(AuthContext);
  const signingCtx = useContext(SignatureParametersContext);
  const hiddenFileInput: any = React.useRef(null);

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const originParam = urlParams.get("origin")?.split("#")[0];

  const { state } = useLocation();
  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event: any) => {
    setLoadingDraft(true);

    if (event.target.files[0].type === "application/pdf") {
      try {
        const wf = await CreateWorkflow(
          event.target.files[0].name,
          event.target.files[0]
        );

        if (wf && wf.actionUrl) {
          signingCtx.pdfFileName = event.target.files[0].name;
          window.location.replace(wf.actionUrl);
        } else {
          setLoadingFile(false);
          new ErrorModalService(37).showErrorModal();
          setLoadingDraft(false);
          setWorkflowInDraft(false);
        }
      } catch (e) {
        setLoadingFile(false);

        console.log(e);
      }
    } else {
      new ErrorModalService(4).showErrorModal();
      setWorkflowInDraft(false);
      setLoadingFile(false);

      setLoadingDraft(false);
    }
  };

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoadingFile(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  const closeButtonHandler = useCallback(async () => {
    if (signingCtx.loadedInIframe) {
      window.parent.postMessage(
        {
          type: "SIGNATOR_SIGNING_SUCCESS",
        },
        "*"
      );

      if (Office) {
        await Office.onReady(() => {
          if (window.parent) {
            Office.context?.ui?.messageParent("START_WF_SUCCESS", {
              targetOrigin: process.env.REACT_APP_TARGET_ORIGIN ?? "",
            });
            Office.context?.ui?.messageParent("START_WF_SUCCESS", {
              targetOrigin: process.env.REACT_APP_MICROSOFT_TARGET_ORIGIN ?? "",
            });
          }
        });
      }
    } else {
      window.open(window.location.origin, "_self");
    }
  }, []);

  if (loadingFile) {
    return (
      <>
        <div className={styles.LandingPageBodyLoading}>
          {(originParam && (originParam === "flows" || originParam === "office" || originParam === "teams")) ? <></> : <Toolbar />}
          <img
            src={PoweredByLogo}
            style={{ width: "20em" }}
            alt={"Vizibit Logo"}
          />
          <br />
          <LoaderComponent loaderSize={ELoaderSize.Large} />
        </div>

        <div
          style={{
            minHeight: "10rem",
            width: "100%",
            backgroundColor: "#623D91",
            marginBottom: "-7rem",
          }}
        ></div>
        <div style={{ width: "100%" }}>
          <LandingCards />
        </div>
        <div className={styles.bottomBanner}>
          <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
        </div>
      </>
    );
  }

  if ((state as any)?.wfRejected) {
    return (
      <>
        <div className={styles.LandingPageBodyLoggedIn}>
          {(originParam && (originParam === "flows" || originParam === "office" || originParam === "teams")) ? <></> : <Toolbar />}
          <img
            src={PoweredByLogo}
            style={{ width: "15em" }}
            alt={"Vizibit Logo"}
          />
          <br />
          <div
            style={{
              width: "70%",
              marginTop: "25px",
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "17px",
                marginTop: "40px",
              }}
            >
              {i18next.t("wf_rejected")}
            </p>
            {signingCtx.loadedInIframe ? (
              <CtaButtonComponent
                mode={EButtonMode.Filled}
                type={"button"}
                btnText={i18next.t("close_label")}
                onClickFunction={closeButtonHandler}
              />
            ) : (
              <>
                <div className={styles.socialDividerContainer}>
                  <span className={styles.socialDivider}>
                    {i18next.t("or_label")}
                  </span>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  <BigActionButton
                    btnText={i18next.t("create_new_wf")}
                    onClickFunction={handleClick}
                  />
                  <input
                    id="contained-button-file"
                    type="file"
                    accept="application/pdf"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </div>
              </>
            )}
          </div>
          <br />
        </div>

        <div
          style={{
            minHeight: "10rem",
            width: "100%",
            backgroundColor: "#623D91",
            marginBottom: "-7rem",
          }}
        ></div>
        <div style={{ width: "100%" }}>
          <LandingCards />
        </div>
        <div className={styles.bottomBanner}>
          <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
        </div>
      </>
    );
  }

  if (authCtx.loggedIn) {
    return !workflowInDraft ? (
      <>
        <div className={styles.LandingPageBodyLoggedIn}>
          {(originParam && (originParam === "flows" || originParam === "office" || originParam === "teams")) ? <></> : <Toolbar />}
          <img
            src={PoweredByLogo}
            style={{ width: "15em" }}
            alt={"Vizibit Logo"}
          />
          <div
            style={{
              width: "70%",
              marginTop: "25px",
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loadingDraft ? (
              <LoaderComponent loaderSize={ELoaderSize.Medium} />
            ) : (
              <>
                <div className={styles.LandingPageMockupDoc}>
                  <DropZone setLoadingFile={setLoadingFile} />
                </div>

                <div className={styles.socialDividerContainer}>
                  <span className={styles.socialDivider}>
                    {i18next.t("or_label")}
                  </span>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  <BigActionButton
                    btnText={i18next.t("create_new_wf")}
                    onClickFunction={handleClick}
                  />
                </div>
              </>
            )}
            <input
              id="contained-button-file"
              type="file"
              accept="application/pdf"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          <br />
        </div>

        <div
          style={{
            minHeight: "10rem",
            width: "100%",
            backgroundColor: "#623D91",
            marginBottom: "-7rem",
          }}
        ></div>
        <div style={{ width: "100%" }}>
          <LandingCards />
        </div>
        <div className={styles.bottomBanner}>
          <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
        </div>
      </>
    ) : (
      <>
        <div className={styles.LandingPageBody}>
          {(originParam && (originParam === "flows" || originParam === "office" || originParam === "teams")) ? <></> : <Toolbar />}
          <img
            src={PoweredByLogo}
            style={{ width: "15em" }}
            alt={"Vizibit Logo"}
          />
          <br />
          <div
            style={{
              width: "70%",
              marginTop: "25px",
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          ></div>
          <br />
        </div>

        <div
          style={{
            minHeight: "10rem",
            width: "100%",
            backgroundColor: "#623D91",
            marginBottom: "-7rem",
          }}
        ></div>
        <div style={{ width: "100%" }}>
          <LandingCards />
        </div>
        <div className={styles.bottomBanner}>
          <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.LandingPageBody}>
        {(originParam && (originParam === "flows" || originParam === "office" || originParam === "teams")) ? <></> : <Toolbar />}
        <img
          src={PoweredByLogo}
          style={{ width: "15em" }}
          alt={"Vizibit Logo"}
        />
        <div style={{ width: "70%", fontSize: "15px" }}>
          <p
            style={{
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            {i18next.t("description_no_parameter")}
          </p>
        </div>
        <div style={{ paddingBottom: "40px" }}>
          <BigActionButton
            btnText={i18next.t("sign_in")}
            onClickFunction={() => keycloak.login()}
          />
        </div>
      </div>

      <div
        style={{
          minHeight: "10rem",
          width: "100%",
          backgroundColor: "#623D91",
          marginBottom: "-7rem",
        }}
      ></div>
      <div style={{ width: "100%" }}>
        <LandingCards />
      </div>
      <div className={styles.bottomBanner}>
        <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
      </div>
    </>
  );
};

export default LandingPage;

