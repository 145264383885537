import styles from "./App.module.css";
import { useNavigate } from "react-router-dom";
import Router from "./pages/Router";
import i18next from "i18next";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import SignatureParametersContext from "./store/SignatureParametersContext";
import axios from "axios";
import { Spin } from "antd";
import AuthContext from "./store/AuthContext";
import { keycloak } from "./services/Keycloak";
import { setUser, userBuilder } from "./classes/User";
import { ErrorModalService } from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import { initOffice } from "./services/utilityServices";
import { useGetEnabledProviders } from "./store/useGetEnabledProviders";

function App() {
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [updateBalance, setUpdateBalance] = React.useState(new Date());
  const [isSigningActive, setIsSigningActive] = React.useState<boolean>(false);

  const { enabledProviders } = useGetEnabledProviders();

  const { t } = useTranslation();

  const navigation = useNavigate();

  let search = window.location.search;
  let params = new URLSearchParams(search.split("?")[1]);

  //signature parameters context
  const signatureParamsCtx = useContext(SignatureParametersContext);

  const closeIframeDialogIfPossible = () => {
    if (window.parent) {
      window.parent.postMessage(
        {
          type: "SIGNATOR_SIGNING_SUCCESS",
        },
        "*"
      );
    }
  }

  useEffect(() => {
    //TODO: ADDED TIMER HERE BECAUSE OF KEYCLOAK RACE CONDITION - KEYCLOAK.AUTHENTICATED WOULD RETURN FALSE, EVEN THOUGH INSIDE KEYCLOAK OBJECT IS TRUE
    const authTimer = setTimeout(() => {
      if (keycloak.authenticated && keycloak.token) {
        const newUser = userBuilder(keycloak.token);
        if (newUser) setUser(newUser, keycloak.token);
        setLoggedIn(true);
        setLoading(false);
      }
    }, 1000);

    return () => clearTimeout(authTimer);
  }, [keycloak.token, keycloak.authenticated]);

  useEffect(() => {
    const langTimer = setTimeout(() => {
      if (keycloak.tokenParsed?.locale != undefined) {
        i18n.changeLanguage(keycloak.tokenParsed?.locale).then((_) => {});
      }
    }, 1000);

    setLoading(false);
    return () => clearTimeout(langTimer);
  }, []);

  useEffect(() => {
    if (params.get("origin") && params.get("origin") === "office") {
      initOffice().then((_) => {});
    }
  }, [params.get("origin")]);

  useEffect(() => {
    let signatureToken = params.get("token");
    signatureParamsCtx.signatureToken = signatureToken;

    if (signatureToken) {
      setLoadingFile(true);
      axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `/v/1/lwms/external/document/download`,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + signatureToken,
            },
          }
        )
        .then(
          async (response) => {
            let convertPdf = await toUint8Array(response.data);
            const disposition = response.headers["content-disposition"];
            const fileName = disposition?.split("filename=")[1]?.split(";")[0];
            signatureParamsCtx.pdfFileName =
              fileName ?? i18next.t("untitled_document");
            signatureParamsCtx.pdfFile = convertPdf;
            signatureParamsCtx.predefinedSignatureOptions = false;
            signatureParamsCtx.comingFromThirdParty = true;
            setLoadingFile(false);
            navigation("/sign");
          },
          async (reason: any) => {
            const errorText = await reason.response?.data.text();
            try {
              let parsedErrorText = JSON.parse(errorText);
              if (parsedErrorText && parsedErrorText.errorCode === 24101) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("storage_id_error")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
              if (parsedErrorText && parsedErrorText.errorCode === 184) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("signator_document_expired")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
              if (parsedErrorText && parsedErrorText.errorCode === 183) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("signator_document_already_signed")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
              if (parsedErrorText && parsedErrorText.errorCode === 185) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("signator_no_owner_permission")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
              if (parsedErrorText && parsedErrorText.errorCode === 177) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("signator_invalid_state")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
              if (parsedErrorText && parsedErrorText.errorCode === 179) {
                new ErrorModalService(
                  undefined,
                  i18next.language,
                  closeIframeDialogIfPossible,
                  i18next.t("signator_invalid_state")
                ).showErrorModal();
                setLoadingFile(false);
                return;
              }
            } catch (e) {
              setLoadingFile(false);
              new ErrorModalService(
                undefined,
                i18next.language,
                closeIframeDialogIfPossible,
                i18next.t("service_not_available")
              ).showErrorModal();
              return;
            }
            setLoadingFile(false);
            new ErrorModalService(
              undefined,
              i18next.language,
              closeIframeDialogIfPossible,
              i18next.t("service_not_available")
            ).showErrorModal();
          }
        )
        .catch((e) => console.log(e));
    }
  }, [params.get("token")]);

  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  if (loadingFile) {
    return (
      <>
        <div className={styles.AppLoadingElement}>
          <Spin size="large" />
        </div>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <div className={styles.AppLoadingElement}>
          <Spin size="large" />
        </div>
      </>
    );
  }

  return (
    <div className={styles.Overlay}>
      <div className={styles.App} id="signator_light_app_element">
        <AuthContext.Provider
          value={{
            loggedIn,
            setLoggedIn,
            loading,
            setLoading,
            updateBalance,
            setUpdateBalance,
            isSigningActive,
            setIsSigningActive,
            enabledProviders,
          }}
        >
          <Router />
        </AuthContext.Provider>
      </div>
      {/*<CookieConsent
                    buttonStyle={{
                        marginRight: "85px",
                        width: "80px",
                        backgroundColor: "#1cbeda",
                        color: "white",
                    }}
                    declineButtonStyle={{
                        marginRight: "10px",
                        width: "80px",
                        backgroundColor: "red",
                        color: "white",
                    }}
                    style={{
                        alignItems: "center",
                        backgroundColor: "rgba(142,137,147,0.5)",
                        color: "#fff",
                        fontWeight: "bold",
                        textShadow: "0 0 3px black, 0 0 5px black",
                    }}
                    buttonText="Accept"
                    declineButtonText="Decline"
                    enableDeclineButton={true}
                    onDecline={() => new ErrorModalService(32).showErrorModal()}
                >
                    {i18next.t("cookie_consent_msg_1")}
                    <a
                        href="https://vizibit.eu/privacy-policy/"
                        target={"_blank"}
                        style={{color: "#623d91", textShadow: "none"}}
                        rel={"noreferrer"}
                    >
                        {i18next.t("cookie_consent_msg_2")}
                    </a>
                </CookieConsent>*/}
    </div>
  );
}

export default App;
