import axios from "axios";
import { SignatureImage } from "./SigningComponent";

export const AtrustSigningComponent = async (
  token: string,
  sigProvider: string | undefined,
  shouldRedirectUrlsBeNull: boolean,
  signatureImage: SignatureImage | null,
  wfId: string | null | undefined
) => {
  if(!sigProvider){
    throw new Error("No sig. provider defined");
    return;
  }

  let successfulUrl = shouldRedirectUrlsBeNull ? null : `${window.location.origin}?token=${token}&signature_successful=true`
  let unsuccessfulUrl = shouldRedirectUrlsBeNull ? null : `${window.location.origin}?token=${token}&signature_unsuccessful=true`

  if(wfId){
    successfulUrl = `${process.env.REACT_APP_BASE_URL}/#/workflow-viewer/${wfId}?success=true&sig_token=${token}`;
    unsuccessfulUrl = `${process.env.REACT_APP_BASE_URL}/#/workflow-viewer/${wfId}?success=false&sig_token=${token}`
  }

  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/v/1/lwms/external/signature/action/init",
    {
      signatureImage: {...signatureImage, UseHandwrittenStyle: false },
      Provider: sigProvider,
      SuccessfulSignatureRedirect:
        sigProvider === "ATrustCscV1" || sigProvider === "InfocertCscV1"
          ? null
          : successfulUrl,
      UnsuccessfulSignatureRedirect:
        sigProvider === "ATrustCscV1" || sigProvider === "InfocertCscV1"
          ? null
          : unsuccessfulUrl,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response;
};