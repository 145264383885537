import React, { useContext, useEffect, useState } from "react";
import styles from "../SigningWizard.module.css";
import { Radio } from "../../../components/Radio";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import { Spin } from "antd";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import SignatureParametersContext from "../../../store/SignatureParametersContext";
import i18next from "i18next";
import { SignatureProfileSettings } from "../../../components/SignatureProfileComponent/SignatureProfileSettings";
import {
  IeSignLiteSignatureArray,
  IeSignLiteSignatureOnlyArray,
  storage,
} from "../../../services/indexDb";
import { ErrorModalService } from "../../../components/ErrorHandling/ErrorModalService";
import { v4 as uuid } from "uuid";
import { IProvider, ProvidersSettings } from "../../../store/ProvidersSettings";
import AuthContext from "../../../store/AuthContext";

enum StepTwoState {
  PROFILES = "PROFILES",
  BASIC = "BASIC",
}

function StepTwo(props: any) {
  const [updateProfiles, setUpdateProfiles] = useState(new Date());

  const formState = useFormState();

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const authCtx = useContext(AuthContext);

  let acceptedTCStorage = localStorage.getItem("eSignLiteTCAccepted");

  const [stepTwoState, setStepTwoState] = useState({
    loadingProfiles: false,
    state: StepTwoState.BASIC as StepTwoState,
    showProfiles: false,
    profiles: [] as any[],
    acceptTC: false,
    acceptedTC: false,
  });
  const [subStep, setSubStep] = useState(0);
  const [profileName, setProfileName] = useState("Profile Name");

  const unique_id = uuid();

  //effect for checking and showing profiles from local storage
  useEffect(() => {
    setStepTwoState((prevState) => ({
      ...prevState,
      loadingProfiles: true,
    }));

    let timeout = setTimeout(() => {
      setStepTwoState((prevState) => ({
        ...prevState,
        loadingProfiles: false,
      }));
    }, 500);

    //check for accepted T&C key in local storage, and set state to it's value
    if (acceptedTCStorage && acceptedTCStorage === "true") {
      setStepTwoState((prevState) => ({
        ...prevState,
        acceptedTC: true,
      }));
    }

    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        if (
          !props.addProfileState &&
          result?.eSignLiteSignatureOnly &&
          result.eSignLiteSignatureOnly.items &&
          result.eSignLiteSignatureOnly.items.length > 0 &&
          !signatureParamsCtx.predefinedSignatureOptions
        ) {
          setStepTwoState((prevState) => ({
            ...prevState,
            state: StepTwoState.PROFILES,
            profiles: result.eSignLiteSignatureOnly.items,
            showProfiles: true,
          }));
        }

        if (
          !props.addProfileState &&
          result?.eSignLiteSignatureOnly &&
          result.eSignLiteSignatureOnly.items &&
          result.eSignLiteSignatureOnly.items.length > 0 &&
          signatureParamsCtx.predefinedSignatureOptions
        ) {
          let filteredProfiles = result.eSignLiteSignatureOnly.items.filter(
            (item) =>
              item.txtType === "NO_IMAGE" &&
              item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
              item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
              item.txtSignatureProvider === signatureParamsCtx.signatureProvider
          );

          if (filteredProfiles.length > 0 && !signatureParamsCtx.phoneNumber) {
            setStepTwoState((prevState) => ({
              ...prevState,
              state: StepTwoState.PROFILES,
              profiles: filteredProfiles,
              showProfiles: true,
            }));
          } else if (
            filteredProfiles.length > 0 &&
            signatureParamsCtx.phoneNumber
          ) {
            let getProfilesWithMatchingPhoneNumber =
              result.eSignLiteSignatureOnly.items.filter(
                (item) =>
                  item.txtType === "NO_IMAGE" &&
                  item.txtSignatureLevel ===
                    signatureParamsCtx.signatureLevel &&
                  item.txtSignatureStandard ===
                    signatureParamsCtx.jurisdiction &&
                  item.txtSignatureProvider ===
                    signatureParamsCtx.signatureProvider &&
                  (item.txtMobitel ===
                  signatureParamsCtx.phoneNumber?.startsWith("+")
                    ? signatureParamsCtx.phoneNumber?.substring(1)
                    : signatureParamsCtx.phoneNumber)
              );
            setStepTwoState((prevState) => ({
              ...prevState,
              state: StepTwoState.PROFILES,
              profiles: getProfilesWithMatchingPhoneNumber,
              showProfiles: true,
            }));
          } else {
            setStepTwoState((prevState) => ({
              ...prevState,
              state: StepTwoState.BASIC,
              profiles: [] as any[],
              showProfiles: false,
            }));
          }
        }
        formState.values["txtSigImageProfileName"] = profileName;
      })
      .catch((e) => console.log(e));
    return () => clearTimeout(timeout);
  }, [updateProfiles]);

  /*  const removeAllSavedProfiles = () => {
      localStorage.removeItem("eSignLiteSignaturesOnly");
  };*/

  const removeSavedSignatureProfileHandler = (profileId: any) => {
    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        try {
          let profileToDelete = result?.eSignLiteSignatureOnly.items.findIndex(
            (item) => item.profileId === profileId
          );

          if (result && profileToDelete !== undefined) {
            let profiles = result.eSignLiteSignatureOnly.items;
            profiles.splice(profileToDelete, 1);

            if (profiles.length === 0) {
              storage
                .setItem("eSignLite", {
                  eSignLiteSignature: {
                    ...result?.eSignLiteSignature,
                  },
                  eSignLiteSignatureOnly: {
                    items: profiles,
                  },
                })
                .then((res) => {
                  setStepTwoState((prevState) => ({
                    ...prevState,
                    state: StepTwoState.BASIC,
                    profiles: [],
                  }));
                  props.addProfileStateHandler(true);
                })
                .catch((e) => console.log(e));
            } else {
              storage
                .setItem("eSignLite", {
                  eSignLiteSignature: {
                    ...result?.eSignLiteSignature,
                  },
                  eSignLiteSignatureOnly: {
                    items: profiles,
                  },
                })
                .then((res) => {
                  setStepTwoState((prevState) => ({
                    ...prevState,
                    profiles: profiles,
                  }));
                })
                .catch((e) => console.log(e));
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  const signWithProfileHandler = (profile: any) => {
    formState.values["txtMobitel"] = profile.txtMobitel ?? "";
    formState.values["txtSignatureLevel"] = profile.txtSignatureLevel ?? "";
    formState.values["txtSignatureProvider"] =
      profile.txtSignatureProvider ?? "";
    formState.values["txtSignatureStandard"] =
      profile.txtSignatureStandard ?? "";

    props.signWithSavedProfile();
  };

  const addNewSignatureWithoutImageHandler = () => {
    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        let newProfileToAdd = {
          txtType: "NO_IMAGE",
          default: false,
          profileId: unique_id,
        };
        if (
          result?.eSignLiteSignatureOnly &&
          result?.eSignLiteSignatureOnly.items &&
          result.eSignLiteSignatureOnly?.items.length > 0
        ) {
          let updatedArray = result.eSignLiteSignatureOnly.items;
          updatedArray.push(newProfileToAdd);
          storage.setItem("eSignLite", {
            eSignLiteSignature: {
              ...result?.eSignLiteSignature,
            },
            eSignLiteSignatureOnly: { items: [...updatedArray] },
          });
          setUpdateProfiles(new Date());
        } else {
          newProfileToAdd = { ...newProfileToAdd };
          storage.setItem("eSignLite", {
            eSignLiteSignature: {
              ...result?.eSignLiteSignature,
            },
            eSignLiteSignatureOnly: {
              items: [newProfileToAdd],
            },
          });
          setUpdateProfiles(new Date());
        }
      })
      .catch((e) => {
        setUpdateProfiles(new Date());

        console.log(e);
        new ErrorModalService(29).showErrorModal();
      });
  };

  if (
    stepTwoState.state === StepTwoState.PROFILES &&
    stepTwoState.showProfiles
  ) {
    return (
      <Fade>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              marginTop: "25px",
            }}
          >
            <div className={styles.SignatorStepContainer}>
                <div className={styles.SignatorStepTitle}>
                  {i18next.t("select_signature_label")}
                </div>
            </div>
            {stepTwoState.loadingProfiles ? (
              <Spin
                size="large"
                style={{ marginTop: "150px", width: "100%" }}
              />
            ) : (
              <>
                <div className={styles.SignatorStepSubtitle}>
                  <div
                    onClick={() => {
                      setStepTwoState((prevState) => ({
                        ...prevState,
                        state: StepTwoState.BASIC,
                      }));
                      props.addProfileStateHandler(true);}
                    }
                    className={styles.SignatorStepSubtitleButton}
                    style={{ textDecoration: "none" }}
                  >
                    <AddPhotoAlternateIcon /> {i18next.t("add_new_signature")}
                  </div>
                  {/*<div
                    style={{ color: "red", fontSize: "10px" }}
                    className={styles.SignatorStepSubtitleButton}
                    onClick={() => {
                      setStepTwoState((prevState) => ({
                        ...prevState,
                        state: StepTwoState.BASIC,
                      }));
                      removeAllSavedProfiles();
                    }}
                  >
                    {i18next.t("delete_all_profiles")}
                  </div>*/}
                </div>
                <div className={styles.SignatorStepContainer}>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {stepTwoState.profiles
                      .filter((item) => item.txtType === "NO_IMAGE")
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "65%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                            className={styles.Signator_SignatureProfileNoImage}
                          >
                            <div
                              style={{
                                marginBottom: "5px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "3px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                {item.txtSigImageProfileName}
                              </span>
                              <span
                                onClick={() =>
                                  removeSavedSignatureProfileHandler(
                                    item.profileId
                                  )
                                }
                              >
                                <DeleteIcon
                                  style={{ color: "red", cursor: "pointer" }}
                                />
                              </span>
                            </div>
                            <div
                              className={`SignatorSignatureProfileCard${
                                item.txtSignatureLevel ?? "Local"
                              }`}
                              onClick={() => signWithProfileHandler(item)}
                            >
                              <div
                                className={
                                  styles.SignatorSignatureProfileDetailsNoImage
                                }
                                style={{ borderTop: "none" }}
                              >
                                <div
                                  className={
                                    styles.SignatorSignatureProfileBasicDetails
                                  }
                                  style={{
                                    height: "35px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {i18next.t("profile_without_image")}
                                  </span>
                                </div>
                              </div>
                              <SignatureProfileSettings profile={item} />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    );
  }

  /*PROFILE CREATION WIZARD*/
  return (
    <Fade>
      <div
        className={styles.SignatorStepContainer}
        style={{ justifyContent: "center", marginTop: "50px" }}
      >
        <div className={styles.SignatorStepTitle}>
          {i18next.t("select_provider")}
        </div>
      </div>
      <div
        className={styles.Signator_StepTwoContainer}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "300px",
          marginTop: "20px",
        }}
      >
        {props.waitingForSignatureImage ? (
          <Spin size="large" style={{ width: "100%" }} />
        ) : (
          <div
            className={styles.Signator_StepTwoBodyContainer}
            style={{
              alignSelf: "center",
              width: "100%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.SignatorStepContainer}>
              <div>
                <Fade>
                  <div className={styles.SignatureStandardRadiosBody}>
                    {authCtx.enabledProviders.map((item: IProvider, index) => {
                      return (
                        <Radio
                          key={index}
                          label={item.providerName}
                          name="txtSignatureProvider"
                          value={item.providerKey}
                          radioIcon={item.providerIcon}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                    className={styles.Signator_ConfirmButtonContainer}
                  >
                    <button
                      type={"submit"}
                      className={formState.values["txtSignatureProvider"]
                          ? styles.SignStepButton
                          : styles.SignStepButtonDisabled
                      }
                      disabled={
                        !formState.values["txtSignatureProvider"]
                      }
                    >
                      {signatureParamsCtx.signatureType === "image"
                        ? i18next.t("confirm_button_label")
                        : i18next.t("confirm_button_label")}
                    </button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
}

export default StepTwo;
