import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import i18next from "i18next";

const SignImageButton = (props: any) => {
  //state variables

  const [openSignButtonDropdown, setOpenSignButtonDropdown] = useState({
    open: false,
    selectedIndex: 0,
  });

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);

  //handlers
  const handleClick = () => {
    if (!props.isSigAdded) {
      props.signWithImageHandler();
    } else if (props.isSigAdded) {
      props.deleteSigImage();
    } else {
      console.log("Signature Image Error");
    }
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Button
          onClick={handleClick}
          startIcon={
            !props.isSigAdded ? (
              <ControlPointIcon style={{ fontSize: "22px" }} />
            ) : (
              <DeleteForeverIcon style={{ fontSize: "22px" }} />
            )
          }
          style={{
            backgroundColor: "white",
            color: props.isSigAdded ? "#db0000" : "#623d91",
            fontSize: "13px",
            marginLeft: props.isMobile ? "5px" : "15px",
            height: "40px",
            minWidth: "140px",
            fontWeight: "bold",
            fontFamily: "OpenSans",
            textTransform: "none",
            paddingLeft: props.isMobile ? "5px" : "16px",
            paddingRight: props.isMobile ? "5px" : "16px",
          }}
        >
          {i18next.t("no_sig_image_info_three")}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default SignImageButton;
