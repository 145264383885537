import React, {useContext} from "react";
import { Fade } from "react-awesome-reveal";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import { TbNumber1, TbNumber2, TbNumber3 } from "react-icons/tb";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { Stepper } from "@mui/material";
import styles from "./SigningWizard.module.css";
import AuthContext from "../../store/AuthContext";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    width: "30%",
    left: "calc(-15% + 0px) !important",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1cbeda !important",
      border: "2px solid",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1cbeda !important",
      border: "2px solid",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: "2px dashed",
    borderColor: "#ccc",
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 42,
  height: 42,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#623d91",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#1cbeda",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <TbNumber1 style={{ fontSize: "18px" }} />,
    2: <TbNumber2 style={{ fontSize: "18px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Define signature", "Authenticate"];

const StepperComponent = ({ currentPage }: any) => {
  const authCtx = useContext(AuthContext);

  if(authCtx.isSigningActive){
    return null;
  }

  return (
    <div>
      <Fade>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={currentPage}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  className={styles.Signator_Stepper_Label}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Fade>
    </div>
  );
};

export default StepperComponent;
