import React, {useContext, useEffect, useState} from "react";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import {useTranslation} from "react-i18next";
import {Dialog} from "@progress/kendo-react-dialogs";
import {CtaButtonComponent} from "@signator/signator_react_components";
import {EButtonMode, ELoaderMode} from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";
import {GetAllSignatureProviders} from "../../services/GetAllSignatureProviders";
import {
  DropDownList
} from "@progress/kendo-react-dropdowns";
import {UpdateSignatureProfile} from "../../services/UpdateSignatureProfile";
import AuthContext from "../../store/AuthContext";
interface ISelectProviderDialog {
  signatureProfile: any
  isSelectProviderDialogVisible: boolean
  setIsSelectProviderDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SelectProviderDialog = (props: ISelectProviderDialog) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [providers, setProviders] = useState<any>([]);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    const fetchSignatureProvider = async () => {
      try{
        const response = await GetAllSignatureProviders();
        if(response){
          const updatedProviders = response.map((provider: any) => {
            return ({id: provider.id, label: provider.name})
          })
          setProviders(updatedProviders);
          setIsLoading(false);
        }
      } catch (e) {
        console.log("Unable to fetch signature providers", e);
      }
    }

    fetchSignatureProvider().then(_ => {})
  }, [])

  function closeAndResetDialog() {
    props.setIsSelectProviderDialogVisible(false);
    authCtx.setUpdateBalance(new Date());
  }

  async function handleUpdateProfile () {
    setIsLoading(true)
    if(selectedProvider){
      let updatedProfile = {...props.signatureProfile, providerId: selectedProvider.id}

      try{
        const response = await UpdateSignatureProfile(updatedProfile)

        if(response){
          props.setIsSelectProviderDialogVisible(false);
        } else {
          console.log("Error while updating profile");
        }
      } catch(e){
        console.log(e);
      } finally {
        authCtx.setUpdateBalance(new Date());
        setIsLoading(false)
      }
    }
  }

  return (
    <Dialog
      minWidth={300}
      width={500}
      title={t("select_sig_provider") ?? "Choose sig. provider"}
      className={"wizardDialog"}
      onClose={() => closeAndResetDialog()}
    >
      <div className={"reminderDialogContentContainer"}>
        <DropDownList
          label={t("select_sig_provider")}
          data={providers}
          textField={"label"}
          dataItemKey={"id"}
          onChange={(e: any) =>
            setSelectedProvider(e.target.value)
          }
        />
        <div className={"reminderDialogBtnContainer"}>
          <CtaButtonComponent
            mode={EButtonMode.Outline}
            type={"button"}
            btnText={t("close_label")}
            onClickFunction={() => closeAndResetDialog()}
            loaderMode={ELoaderMode.Light}
          />
          <CtaButtonComponent
            mode={EButtonMode.Filled}
            type={"button"}
            btnText={t("confirm")}
            isCtaLoading={isLoading}
            isBtnDisabled={isLoading || !selectedProvider}
            onClickFunction={handleUpdateProfile}
          />
        </div>
      </div>
    </Dialog>
  );
}