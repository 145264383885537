import React, { ChangeEvent } from "react";
import styles from "./ProviderButton.module.css";

interface IProviderButton {
  radioFieldName: string;
  providerValue: string;
  providerIcon: any;
  selectedProvider: any;
  setSelectedProvider: React.Dispatch<React.SetStateAction<"ATrustCscV1" | "SwisscomEtsiV1" | "Sign8CscV2" | "CertiliaCommercialV1" | "DTrustV1" | "InfocertCscV1" | undefined>>;
}

export const ProviderButton = (props: IProviderButton) => {
  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setSelectedProvider(props.providerValue as any);
  };

  const handleOptionClick = () => {
    props.setSelectedProvider(props.providerValue as any);
  };

  return (
    <>
      <div className={styles.profileNoImageContainer}>
        <div
          className={
            props.providerValue === props.selectedProvider
              ? styles.profileNoImageInformationChecked
              : styles.profileNoImageInformation
          }
          onClick={handleOptionClick}
        >
          <input
            type="radio"
            id={props.providerValue}
            value={props.providerValue}
            onChange={onRadioChange}
            checked={props.selectedProvider === props.providerValue}
            className={styles.customRadioButtonInput}
          />
          <div
            style={{
              display: "flex",
              width: "70%",
              justifyContent: "center",
              fontSize: "9px",
              height: "100%",
              alignItems: "center",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <img
              style={{
                paddingTop: "2px",
                maxWidth:
                  props.providerValue === "SwisscomEtsiV1" ? "80px" : "60px",
                maxHeight:
                  props.providerValue === "SwisscomEtsiV1" ? "50px" : "30px",
              }}
              src={props.providerIcon}
              height={props.providerValue === "SwisscomEtsiV1" ? 35 : 25}
            />
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className={styles.customRadioButtonCheckmark}></span>
          </div>
        </div>
      </div>
    </>
  );
};