import React, { useCallback } from "react";
import styles from "./TopToolbar.module.css";
import CTAButton from "@signator/signator_react_components/dist/components/CtaButton";
import { EButtonMode } from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";

function PdfViewerTopToolbar(props: any) {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const originParam = urlParams.get("origin")?.split("#")[0];

  const closeButtonHandler = useCallback(async () => {
    window.parent.postMessage(
      {
        type: "SIGNATOR_SIGNING_SUCCESS",
      },
      "*"
    );
  }, []);

  return (
    <div className={styles.Toolbar}>
      {/*
          <div className={styles.LogoContainer}>
            <a href="/">
              <img
                className={styles.ToolbarLogo}
                src={ESignLiteLogo}
                alt="Vizibit logo"
              />
            </a>
          </div>
        */}
      <div className={styles.Signator_ToolbarSlotsDiv}>
        <div
          className={styles.Signator_ToolbarSlotContainer}
          style={{ display: "flex", alignItems: "center", paddingTop: "5px" }}
        >
          <div style={{ padding: "0px 2px" }}>
            <props.slots.ZoomOut />
          </div>
          {!props.isMobile && (
            <div style={{ padding: "0px 2px" }}>
              <props.slots.Zoom />
            </div>
          )}
          <div style={{ padding: "0px 2px" }}>
            <props.slots.ZoomIn />
          </div>
          {!props.isMobile && (
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <props.slots.GoToPreviousPage />
            </div>
          )}
          {!props.isMobile && (
            <div style={{ padding: "0px 2px" }}>
              <props.slots.GoToNextPage />
            </div>
          )}
          <div style={{ padding: "0px 2px" }}>
            <props.slots.Download />
          </div>
          {!props.isMobile && (
            <div style={{ padding: "0px 2px" }}>
              <props.slots.Print />
            </div>
          )}
          {originParam && originParam === "flows" && (
            <CTAButton
              mode={EButtonMode.Link}
              btnText={"Close"}
              onClickFunction={closeButtonHandler}
            />
          )}
        </div>
      </div>
      {/*
          <div
            style={{
              display: "flex",
              width: "20%",
              justifyContent: "flex-end",
            }}
          >
            <div className={styles.ToolbarRightMenu}>
              <div className={styles.ToolbarMenuLanguage}>
                <LanguageButton backgroundColor={"#f5f5f5"} />
              </div>
            </div>
            <div className={styles.ToolbarRightMenu}>
              <div className={styles.ToolbarMenuLanguage}>
                <SettingsButtonMenu backgroundColor={"#f5f5f5"} />
              </div>
            </div>
          </div>
        */}
    </div>
  );
}

export default PdfViewerTopToolbar;
