import { RefreshTokenMiddleware } from "./RefreshTokenMiddleware";
import { basicGETRequestOptions } from "../appsettings";
import { getLoggedUserJwt } from "../classes/User";
import i18n, { t } from "i18next";
import {
    ErrorModalService
} from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import {handleResponseNotOK} from "./handleResponseNotOk";

export async function GetUserInvites() {

    const refreshToken = await RefreshTokenMiddleware();

    try {
        if (refreshToken) {
            const response = await fetch(`${process.env.REACT_APP_URM_BASE_URL}${process.env.REACT_APP_ROLES_URL}/user/invite`, basicGETRequestOptions(getLoggedUserJwt() ?? ''));

            const data = await handleResponseNotOK(response, t("error_getting_invite")
            );

            return data;

        } else {
            console.log('Error refreshing token');
            throw new Error('Error refreshing token');
        }
    } catch (e: any) {
        if (e.message && e.message === 'handled_error') {
            throw new Error('Handled error');
        } else {
            new ErrorModalService(
              undefined,
              i18n.language,
              null,
              i18n.t("error_getting_invite")).showErrorModal();
            console.log(e);
            throw new Error('Error getting organization: ' + e);
        }
    }
}
