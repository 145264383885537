import {
    ErrorModalService
} from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import i18n, {t} from "i18next";

export async function handleResponseNotOK(
  response: Response,
  customErrorMessage: string,
  responseWithoutBody?: boolean
): Promise<any> {
    if (!response.ok) {
        return response
          .text()
          .then((text: any) => JSON.parse(text))
          .then((parsedText) => {
              if (parsedText.ErrorCode || parsedText.errorCode) {
                  if ((parsedText.ErrorCode || parsedText.errorCode) === 204) {
                      // in case user is blocked redirect to blocked page
                      window.location.href = process.env.REACT_APP_URM_BASE_URL ?? "";
                      throw new Error("handled_error");
                  } else if (response.status === 403) {
                      new ErrorModalService(
                        undefined,
                        i18n.language,
                        undefined,
                        t("you_are_not_authorized_to_view_this_page") ||
                        "You are not authorized to view this page.",
                        undefined
                      ).showErrorModal();
                      throw new Error("handled_error");
                  } else if(parsedText.ErrorCode || parsedText.errorCode) {
                      new ErrorModalService(
                        parsedText.ErrorCode ? parsedText.ErrorCode : parsedText.errorCode,
                        i18n.language.toLowerCase(),
                        undefined,
                        undefined,
                        undefined
                      ).showErrorModal();
                      throw new Error("handled_error");
                  }
              } else {
                  if (response.status === 403) {
                      new ErrorModalService(
                        undefined,
                        i18n.language,
                        undefined,
                        t("you_are_not_authorized_to_view_this_page") ||
                        "You are not authorized to view this page.",
                        undefined
                      ).showErrorModal();
                      throw new Error("handled_error");
                  } else {
                      new ErrorModalService(
                        undefined,
                        i18n.language,
                        undefined,
                        customErrorMessage,
                        undefined
                      ).showErrorModal();
                      throw new Error("handled_error");
                  }
              }
          })
          .catch((e) => {
              if (e.message && e.message === "handled_error") {
                  throw new Error("handled_error");
              } else {
                  new ErrorModalService(
                    undefined,
                    i18n.language,
                    undefined,
                    customErrorMessage,
                    undefined
                  ).showErrorModal();
                  throw new Error("handled_error");
              }
          });
    }

    if (responseWithoutBody) {
        return response;
    } else {
        return await response.json();
    }
}
