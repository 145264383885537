import {getLoggedUser, getLoggedUserEmail, getLoggedUserJwt} from "../classes/User";
import {RefreshTokenMiddleware} from "./RefreshTokenMiddleware";
import {
    ErrorModalService
} from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";
import i18next from "i18next";

export const CreateWorkflow = async  (fileName: string, file: File) => {
    const formData = new FormData();


    formData.append("name", fileName);
    formData.append("document", file);
    formData.append("participantActions[0].email", getLoggedUserEmail())
    formData.append("language", i18next.language)

    const refreshToken = await RefreshTokenMiddleware();

    if (refreshToken) {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + "/v/1/lwms", {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + getLoggedUserJwt()
                },
            });

            if (!response.ok) {
                return response
                    .text()
                    .then((text: any) => {
                        return JSON.parse(text);
                    })
                    .then((parsedText) => {
                        if (parsedText.errorName) {
                            new ErrorModalService(
                                parsedText.errorName,
                              i18next.language,
                            ).showErrorModal();
                            throw new Error("handled_error");
                        } else if (parsedText.status && parsedText.status === 403) {
                            new ErrorModalService(
                                undefined,
                                i18next.language,
                                null,
                                i18next.t("signator_no_permission")
                            ).showErrorModal();
                            throw new Error("handled_error");
                        }  else {
                            new ErrorModalService(
                                undefined,
                              i18next.language,
                                null,
                              i18next.t("unable_to_initialize_wf"),
                            ).showErrorModal();
                            throw new Error("handled_error");
                        }
                    })
                    .catch((e) => {
                        if (e.message && e.message === "handled_error") {
                            throw new Error("handled_error");
                        } else {
                            new ErrorModalService(
                                undefined,
                              i18next.language,
                                null,
                              i18next.t("unable_to_initialize_wf")
                            ).showErrorModal();
                            throw new Error("handled_error");
                        }
                    });
            }

            const wfRes = await response.json()

            const initWf = await fetch(process.env.REACT_APP_BASE_URL + `/v/1/lwms/${wfRes.id}/action/init`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + getLoggedUserJwt()
                },
            });

            if (!initWf.ok) {
                return initWf
                    .text()
                    .then((text: any) => {
                        return JSON.parse(text);
                    })
                    .then((parsedText) => {
                        if (parsedText.errorName) {
                            new ErrorModalService(
                              2,
                              i18next.language,
                              null,
                              i18next.t("unable_to_fetch_data"),
                            ).showErrorModal();
                            throw new Error("handled_error");
                        } else {
                            new ErrorModalService(
                                undefined,
                              i18next.language,
                                null,
                              i18next.t("unable_to_initialize_wf"),
                            ).showErrorModal();
                            throw new Error("handled_error");
                        }
                    })
                    .catch((e) => {
                        if (e.message && e.message === "handled_error") {
                            throw new Error("handled_error");
                        } else {
                            new ErrorModalService(
                                2,
                              i18next.language,
                                null,
                                undefined
                            ).showErrorModal();
                            throw new Error("handled_error");
                        }
                    });
            }

            const initWfRes = await initWf.json()

            return initWfRes;
        } catch (e: any) {
            if (e.message && e.message === "handled_error") {
                throw new Error("Handled error");
            } else {
                new ErrorModalService(
                    undefined,
                  i18next.language,
                    null,
                  i18next.t("unable_to_initialize_wf"),
                ).showErrorModal();
                console.log(e);
            }
        }
    } else {
        console.log("Error refreshing token");
        throw new Error("Error refreshing token");
    }
}