import React, { useContext, useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import { FormModel } from "../../../services/signingModel";
import { SignatureImage } from "../../../components/SigningComponent/SigningComponent";
import {
  officeFunctionHandler,
  signedDocDownload,
  splitBase64,
} from "../../../services/utilityServices";
import i18next from "i18next";
import SignatureParametersContext from "../../../store/SignatureParametersContext";
import AuthContext from "../../../store/AuthContext";
import { AtrustSigningComponent } from "../../../components/SigningComponent/AtrustSigningComponent";
import styles from "../SigningWizard.module.css";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseButton from "../../../images/close_bold_icon_grey.svg";
import DownloadButton from "../../../images/download_icon_purple.svg";
import WalletIcon from "../../../images/money_icon.svg";
import RefreshIcon from "../../../images/refresh_icon_purple.svg";
import { saveAs } from "file-saver";
import NoFunds from "../../../images/noBudget.svg";
import CTAButton from "@signator/signator_react_components/dist/components/CtaButton";
import { EButtonMode } from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";
import * as microsoftTeams from "@microsoft/teams-js";
import { GetWorkflowActionState } from "../../../services/GetWorkflowActionState";

const StepThreeSuite = (props: any) => {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);

  const formState = useFormState();
  const authCtx = useContext(AuthContext);

  let search = window.location.search;
  let params = new URLSearchParams(search.split("?")[1]);

  const [documentDownloading, setDocumentDownloading] =
    useState<boolean>(false);
  const [stepThreeState, setStepThreeState] = useState({
    loading: true,
    showSpinner: true,
    errorState: false,
    errorMessage: "",
    consentUrl: false,
    consentUrlValue: "",
    signingSuccessful: false,
    signedFile: undefined,
    secondaryDrawerOpen: false,
    secondaryDrawerVideoIdent: false,
    secondaryDrawerBilling: false,
    showRetryButton: false,
    remainingAmountOfCredits: undefined,
    remainingAmountSpinner: false,
    remainingCreditsChecked: false,
  });
  const [widthForDrawer, setWidthForDrawer] = useState<number>(
    window.innerWidth
  );
  const [showWalletIframe, setShowWalletIframe] = useState<boolean>(false);
  const [signatureTypeQueryParam, setSignatureTypeQueryParam] = useState<
    string | undefined
  >(undefined);

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const originParam = urlParams.get("origin")?.split("#")[0];
  const wfIdParam = urlParams.get("wfId")?.split("#")[0];

  const checkStateIntervalRef = useRef<NodeJS.Timeout | null>(null);

  async function officeOnReady() {
    await Office.onReady(() => {
      if (stepThreeState.signingSuccessful) {
        if (window.parent) {
          Office.context?.ui?.messageParent("START_WF_SUCCESS", {
            targetOrigin: process.env.REACT_APP_TARGET_ORIGIN ?? "",
          });
          Office.context?.ui?.messageParent("START_WF_SUCCESS", {
            targetOrigin: process.env.REACT_APP_MICROSOFT_TARGET_ORIGIN ?? "",
          });
        }
      } else {
        if (window.parent && stepThreeState.errorState) {
          Office.context?.ui?.messageParent(
            "START_WF_ERROR:" + stepThreeState.errorMessage ?? "",
            { targetOrigin: process.env.REACT_APP_TARGET_ORIGIN ?? "" }
          );
          Office.context?.ui?.messageParent(
            "START_WF_ERROR:" + stepThreeState.errorMessage ?? "",
            {
              targetOrigin: process.env.REACT_APP_MICROSOFT_TARGET_ORIGIN ?? "",
            }
          );
        }
      }
    });
  }

  async function officeSignOnReady(consentUrl: string) {
    await Office.onReady(() => {
      if (window.parent) {
        Office.context?.ui?.messageParent(consentUrl, {
          targetOrigin: process.env.REACT_APP_TARGET_ORIGIN ?? "",
        });
        Office.context?.ui?.messageParent(consentUrl, {
          targetOrigin: process.env.REACT_APP_MICROSOFT_TARGET_ORIGIN ?? "",
        });
      }
    });
  }

  async function teamsSignOnReady(consentUrl: string) {
    const message = {
      type: "SIGNATOR_SIGNING_IN_NEW_TAB",
      content: consentUrl,
    };
    console.log(message);
    microsoftTeams.dialog.url.submit(message);
  }

  function flowsSignOnReady(consentUrl: string) {
    if (window.parent) {
      window.parent.postMessage(
        {
          type: "SIGNATOR_SIGNING_IN_NEW_TAB",
          url: consentUrl,
        },
        "*"
      );
    } else {
      console.log("Missing parent window");
    }
  }

  async function handleCloseIframe() {
    if (window.location === window.parent.location) {
      console.log("Application is not loaded through iframe.");
      window.location.href = window.location.origin;
    }

    if (window.parent) {
      window.parent.postMessage(
        {
          type: "SIGNATOR_SIGNING_SUCCESS",
        },
        "*"
      );
    }

    if (params.get("origin") && params.get("origin") === "teams") {
      const message = {
        type: "CLOSE_SIGNING_WINDOW",
        content: "User has clicked close button",
      };
      console.log(message);
      microsoftTeams.dialog.url.submit(message);
    }

    await officeFunctionHandler(officeOnReady);
  }

  //getting info of window width
  function handleWindowSizeChange() {
    setWidthForDrawer(window.innerWidth);
  }

  async function CheckForWorkflowActionStateResults(): Promise<"finished" | "error" | "expired" | "pending" | "rejected"> {
    try {
      const state = await GetWorkflowActionState(
        signatureParamsCtx.signatureToken ?? ""
      );
      if (state) {
        if (state.state === "Pending") {
          return "pending";
        } else if (state.state === "Signed") {
          return "finished";
        } else if (state.state === "Rejected") {
          return "rejected";
        } else if (state.state === "Unknown") {
          return "error";
        } else {
          return "error";
        }
      } else {
        return "error";
      }
    } catch (e) {
      console.log(e);
      return "error";
    }
  }

  async function listenForSigningResult(event: any) {
    if (event.data.type === "SIGNATOR_SIGNING_SUCCESS") {
      setStepThreeState((prevState) => ({
        ...prevState,
        consentUrl: false,
        consentUrlValue: "",
        loading: true,
      }));

      if (checkStateIntervalRef.current === null) {
        checkStateIntervalRef.current = setInterval(async () => {
          const result = await CheckForWorkflowActionStateResults();
          if (result === "finished") {
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              consentUrl: false,
              consentUrlValue: "",
              signingSuccessful: true,
              showSpinner: false,
              errorState: false,
            }));

            if(checkStateIntervalRef.current){
              clearInterval(checkStateIntervalRef.current);
            }
          } else if(result === "error"){
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              consentUrl: false,
              consentUrlValue: "",
              signingSuccessful: false,
              showSpinner: false,
              errorState: true,
              errorMessage: i18next.t("signator_generic_signature_error"),
            }));

            if(checkStateIntervalRef.current){
              clearInterval(checkStateIntervalRef.current);
            }
          }
        }, 2500);
      }

      //AFTER ONE MINUTE, CLEAR INTERVAL REGARDLESS OF RESULTS
      setTimeout(() => {
        setStepThreeState((prevState) => ({
          ...prevState,
          loading: false,
          consentUrl: false,
          consentUrlValue: "",
          signingSuccessful: true,
          showSpinner: false,
          errorState: false,
        }));

        if (checkStateIntervalRef.current)
          clearInterval(checkStateIntervalRef.current);
      }, 60000);
    }
    if (event.data.type === "SIGNATOR_SIGNING_ERROR") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        consentUrl: false,
        consentUrlValue: "",
        signingSuccessful: false,
        showSpinner: false,
        errorState: true,
        errorMessage: i18next.t("signator_generic_signature_error"),
      }));
    }
  }

  function listenForAddFunds(event: any) {
    if (event.data.type === "SIGNATOR_ADDED_FUNDS") {
      setShowWalletIframe(false);
    }
  }

  const saveDocumentHandler = (signedFile: any) => {
    setDocumentDownloading(true);
    setTimeout(async () => {
      try {
        let downloadFile = await signedDocDownload(
          signatureParamsCtx.signatureToken ?? ""
        );
        if (downloadFile && downloadFile.data) {
          saveAs(
            downloadFile.data,
            signatureParamsCtx.pdfFileName + i18next.t("signed_pdf")
          );
        }
        setDocumentDownloading(false);
      } catch (e) {
        console.log(e);
        setDocumentDownloading(false);
        throw new Error(i18next.t("doc_not_available"));
      }
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("message", listenForSigningResult);
    window.addEventListener("message", listenForAddFunds);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("message", listenForSigningResult);
      window.removeEventListener("message", listenForAddFunds);
    };
  }, []);

  useEffect(() => {
    if (params.get("origin") && params.get("origin") === "teams") {
      try {
        microsoftTeams.app.initialize().then((_) => {
          console.log("TEAMS INITIALIZED");
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [params.get("origin")]);

  useEffect(() => {
    if (signatureParamsCtx.successfulSignature) {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        consentUrl: false,
        consentUrlValue: "",
        signingSuccessful: true,
        showSpinner: false,
        errorState: false,
      }));
    } else if (signatureParamsCtx.unsuccessfulSignature) {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        consentUrl: false,
        consentUrlValue: "",
        signingSuccessful: false,
        showSpinner: false,
        errorState: true,
        errorMessage: i18next.t("unable"),
      }));
    } else {
      if (props.signWithImageContinue) {
        props.addProfileStateHandler(false);

        formState.values["txtName"] = props.signingImageProfile.txtName ?? "";
        formState.values["txtCompanyName"] =
          props.signingImageProfile.txtCompanyName ?? "";
        formState.values["txtDepartment"] =
          props.signingImageProfile.txtDepartment ?? "";
        formState.values["txtPosition"] =
          props.signingImageProfile.txtPosition ?? "";
        formState.values["txtLogo"] = props.signingImageProfile.txtLogo ?? "";
        formState.values["txtSignatureImagePicture"] =
          props.signingImageProfile.txtSignatureImagePicture ?? "";
        formState.values["UseHandwrittenStyle"] =
          props.signingImageProfile.UseHandwrittenStyle ?? false;

        submitForm();
      } else if (props.directSignWithoutImage) {
        formState.values["txtName"] = props.signingImageProfile.txtName ?? "";
        formState.values["txtCompanyName"] =
          props.signingImageProfile.txtCompanyName ?? "";
        formState.values["txtDepartment"] =
          props.signingImageProfile.txtDepartment ?? "";
        formState.values["txtPosition"] =
          props.signingImageProfile.txtPosition ?? "";
        formState.values["txtLogo"] = props.signingImageProfile.txtLogo ?? "";
        formState.values["txtSignatureImagePicture"] = "";
        formState.values["UseHandwrittenStyle"] = null;

        submitForm();
      } else {
        props.addProfileStateHandler(false);

        submitForm();
      }
    }

    return () => {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: true,
        showSpinner: true,
        errorState: false,
        consentUrl: false,
        consentUrlValue: "",
        errorMessage: "",
        signingSuccessful: false,
        signedFile: undefined,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: false,
        remainingAmountOfCredits: undefined,
      }));
    };
  }, []);

  const determineSignatureTypeQueryParam = () => {
    if (
      props.signingImageProfile.txtSignatureProvider === "ATrustCscV1" ||
      formState.values?.["txtSignatureProvider"] === "ATrustCscV1"
    ) {
      setSignatureTypeQueryParam("atrust_qes");
    }
    if (
      props.signingImageProfile.txtSignatureProvider ===
        "CertiliaCommercialV1" ||
      formState.values?.["txtSignatureProvider"] === "CertiliaCommercialV1"
    ) {
      setSignatureTypeQueryParam("certilia");
    }
    if (
      props.signingImageProfile.txtSignatureProvider === "SwisscomEtsiV1" ||
      formState.values?.["txtSignatureProvider"] === "SwisscomEtsiV1"
    ) {
      setSignatureTypeQueryParam("swisscom_qes");
    }
    if (
      props.signingImageProfile.txtSignatureProvider === "InfocertCscV1" ||
      formState.values?.["txtSignatureProvider"] === "InfocertCscV1"
    ) {
      setSignatureTypeQueryParam("infocert_qes");
    }
  };

  const submitForm = async () => {
    if (stepThreeState.loading === false) {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }

    setTimeout(() => {
      setStepThreeState((prevState) => ({
        ...prevState,
        showSpinner: false,
      }));
    }, 1000);

    try {
      const { ...rest } = formState.values as FormModel;

      const sigImage = props.signatureImage
        ? new SignatureImage(
            props.signatureImage.page,
            props.signatureImage.offsetX,
            props.signatureImage.offsetY,
            props.signatureImage.height,
            props.signatureImage.width,
            rest["UseHandwrittenStyle"] === true
              ? null
              : splitBase64(rest["txtSignatureImagePicture"]!),
          props.signingImageProfile.Appearance ?? undefined,
            rest["txtName"] ?? "",
            rest["txtPosition"] ?? "",
            rest["txtDepartment"] ?? "",
            rest["txtCompanyName"] ?? "",
            rest["txtLogo"] ?? null,
            rest["UseHandwrittenStyle"] ?? false
          )
        : null;

      let selectedProviderIframeStatus;
      if (props.signingImageProfile?.txtSignatureProvider) {
        selectedProviderIframeStatus = authCtx.enabledProviders.filter(
          (provider) =>
            provider.providerKey ===
            props.signingImageProfile.txtSignatureProvider
        )?.[0];
      } else {
        selectedProviderIframeStatus = authCtx.enabledProviders.filter(
          (provider) =>
            provider.providerKey === formState.values?.["txtSignatureProvider"]
        )?.[0];
      }

      if (!selectedProviderIframeStatus) {
        setStepThreeState((prevState) => ({
          ...prevState,
          loading: false,
          consentUrl: false,
          consentUrlValue: "",
          signingSuccessful: false,
          showSpinner: false,
          errorState: true,
        }));
      } else {
        const signingProcess = await AtrustSigningComponent(
          signatureParamsCtx.signatureToken ?? "",
          selectedProviderIframeStatus.providerKey,
          (selectedProviderIframeStatus.providerKey !== "ATrustCscV1" || selectedProviderIframeStatus.providerKey !== "InfocertCscV1") &&
            (originParam === "office" || originParam === "teams"),
          sigImage,
          wfIdParam
        );

        if (
          selectedProviderIframeStatus.providerKey !== "ATrustCscV1" && selectedProviderIframeStatus.providerKey !== "InfocertCscV1" &&
          originParam === "office" &&
          signingProcess?.data?.consentUrl
        ) {
          officeSignOnReady(signingProcess?.data?.consentUrl).then((_) => {});
          return;
        } else if (
          selectedProviderIframeStatus.providerKey !== "ATrustCscV1" && selectedProviderIframeStatus.providerKey !== "InfocertCscV1" &&
          originParam === "teams" &&
          signingProcess?.data?.consentUrl
        ) {
          teamsSignOnReady(signingProcess?.data?.consentUrl).then((_) => {});
          return;
        } else if (
          selectedProviderIframeStatus.providerKey !== "ATrustCscV1" && selectedProviderIframeStatus.providerKey !== "InfocertCscV1" &&
          originParam === "flows" &&
          signingProcess?.data?.consentUrl
        ) {
          flowsSignOnReady(signingProcess?.data?.consentUrl);
          return;
        } else if (signingProcess?.data?.consentUrl) {
          if (selectedProviderIframeStatus.iframe) {
            setStepThreeState((prevState) => ({
              ...prevState,
              errorState: false,
              loading: false,
              consentUrl: true,
              consentUrlValue: signingProcess.data.consentUrl,
            }));
          } else {
            window.location.href = signingProcess?.data?.consentUrl;
          }
        } else {
          setStepThreeState((prevState) => ({
            ...prevState,
            loading: false,
            consentUrl: false,
            consentUrlValue: "",
            signingSuccessful: false,
            showSpinner: false,
            errorState: true,
          }));
        }
      }
    } catch (e) {
      let errorMessage = i18next.t("unable_to_start_signing_document");

      if ((e as any)?.response?.data?.errorCode) {
        if ((e as any)?.response?.data?.errorCode === 168) {
          errorMessage = i18next.t("error_status_finished");
        } else if ((e as any)?.response?.data?.errorCode === 70) {
          determineSignatureTypeQueryParam();
          errorMessage = i18next.t("signator_no_credits_message_part_one");
        } else if ((e as any)?.response?.data?.errorCode === 183) {
          errorMessage = i18next.t("signator_document_already_signed");
        } else if ((e as any)?.response?.data?.errorCode === 184) {
          errorMessage = i18next.t("signator_document_expired");
        } else if ((e as any)?.response?.data?.errorCode === 185) {
          errorMessage = i18next.t("signator_no_owner_permission");
        } else if ((e as any)?.response?.data?.errorCode === 180) {
          errorMessage = i18next.t("signator_email_mismatch");
        } else if ((e as any)?.response?.data?.errorCode === 177) {
          errorMessage = i18next.t("signator_invalid_state");
        } else if ((e as any)?.response?.data?.errorCode === 179) {
          errorMessage = i18next.t("signator_invalid_state");
        }
      }

      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        consentUrl: false,
        consentUrlValue: "",
        signingSuccessful: false,
        showSpinner: false,
        errorState: true,
        errorMessage: errorMessage,
      }));
      //new ErrorModalService(5).showErrorModal();

      console.log(e);
    }
  };

  const handleLoadWallet = () => {
    setShowWalletIframe(true);
  };

  if (stepThreeState.errorState) {
    return stepThreeState.loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin size="large" style={{ marginTop: "150px" }} />
      </div>
    ) : (
      <Fade>
        <div className={styles.SignatorStepContainer}>
          {!showWalletIframe && (
            <div className={styles.SignatorErrorTitle}>
              <span style={{ color: "#ff8d00" }}>
                {i18next.t("signator_error_title")}
              </span>
              <div className={styles.SignatorSuccessIcon}>
                {stepThreeState.errorMessage ===
                i18next.t("signator_no_credits_message_part_one") ? (
                  <img src={NoFunds} style={{ width: "40%", height: "40%" }} />
                ) : (
                  <WarningIcon
                    style={{ width: "40%", height: "40%", color: "#ff8d00" }}
                  />
                )}
              </div>
              <div
                className={styles.SignatorErrorMessage}
                style={{
                  color: "#5e5e5e",
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    paddingTop: "20px",
                  }}
                >
                  {stepThreeState.errorMessage}
                </div>
                {stepThreeState.errorMessage ===
                  i18next.t("signator_no_credits_message_part_one") &&
                  !showWalletIframe && (
                    <div className={styles.SignatorNoFundsButtons}>
                      {authCtx.loggedIn && (
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "180px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CTAButton
                            mode={EButtonMode.Filled}
                            btnText={i18next.t("go_to_wallet")}
                            onClickFunction={handleLoadWallet}
                            btnIcon={WalletIcon}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "180px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CTAButton
                          mode={EButtonMode.Filled}
                          btnText={i18next.t("retry")}
                          onClickFunction={submitForm}
                          btnIcon={RefreshIcon}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {stepThreeState.errorMessage ===
            i18next.t("signator_no_credits_message_part_one") &&
            showWalletIframe && (
              <div className={"walletIframeContainer"}>
                <iframe
                  src={
                    signatureTypeQueryParam
                      ? process.env.REACT_APP_QUICK_ADD_FUNDS_URL +
                        `?signature_type=${signatureTypeQueryParam}`
                      : process.env.REACT_APP_QUICK_ADD_FUNDS_URL
                  }
                ></iframe>
              </div>
            )}
        </div>
      </Fade>
    );
  }

  if (stepThreeState.signingSuccessful) {
    return (
      <Fade>
        <div className={styles.SignatorStepContainer}>
          <div className={styles.SignatorSuccessTitle}>
            {i18next.t("success")}
          </div>
          <div className={styles.SignatorSuccessIcon}>
            <CheckCircleIcon
              style={{ width: "40%", height: "40%", color: "#13c2c2" }}
            />
          </div>
          <div
            className={styles.SignatorErrorMessage}
            style={{ color: "#5e5e5e", marginBottom: "20px", width: "80%" }}
          >
            {i18next.t("success_text")}
          </div>

          {/*
              <div
                className={styles.SignatorSuccessButton}
                onClick={backToDocumentHandler}
              >
                <KeyboardReturnIcon style={{ marginRight: "5px" }} />
                {i18next.t("back_to_doc_label")}
              </div>
            */}
          <div className={styles.SignatorSuccessButtonsContainer}>
            <div
              style={{
                width: "100%",
                maxWidth: "180px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CTAButton
                mode={EButtonMode.Filled}
                btnText={i18next.t("close_label")}
                onClickFunction={handleCloseIframe}
                btnIcon={CloseButton}
              />
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "180px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CTAButton
                mode={EButtonMode.Filled}
                btnText={i18next.t("download_label")}
                onClickFunction={saveDocumentHandler}
                btnIcon={DownloadButton}
                isCtaLoading={documentDownloading}
                isBtnDisabled={documentDownloading}
              />
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  return (
    <Fade>
      {stepThreeState.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin size="large" style={{ marginTop: "150px" }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
            minHeight: "500px",
          }}
        >
          {stepThreeState.consentUrl && stepThreeState.consentUrlValue && (
            <iframe
              src={stepThreeState.consentUrlValue}
              style={{
                border: "none",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "450px",
              }}
            ></iframe>
          )}
        </div>
      )}
    </Fade>
  );
};

export default StepThreeSuite;
