import { RefreshTokenMiddleware } from "./RefreshTokenMiddleware";
import { basicPUTRequestOptions } from "../appsettings";
import { getLoggedUserJwt } from "../classes/User";
import { handleResponseNotOK } from "./handleResponseNotOk";
import i18n, { t } from "i18next";
import { ErrorModalService } from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";

export const UpdateSignatureProfile = async (profileCard: any) => {
  const refreshToken = await RefreshTokenMiddleware();

  if (refreshToken) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URM_BASE_URL}/v/1/permission-management/signature-card`,
        basicPUTRequestOptions(getLoggedUserJwt() ?? "", profileCard)
      );

      const data = await handleResponseNotOK(
        response,
        t("error_updating_profile"),
        true
      );

      return data;
    } catch (e: any) {
      if (e.message && e.message === "handled_error") {
        throw new Error("Handled error");
      } else {
        new ErrorModalService(
          undefined,
          i18n.language,
          undefined,
          t("error_updating_profile")
        ).showErrorModal();
        console.log(e);
        throw new Error("Error updating profile: ");
      }
    }
  } else {
    console.log("Error refreshing token");
    window.location.reload();
    throw new Error("Error refreshing token");
  }
};