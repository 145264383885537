import {
  Jurisdiction,
  SignatureLevel,
  SignatureProvider,
} from "./components/SigningComponent/SigningComponent";

interface IApplicationSettings {
  PRODUCTION_BUILD: boolean
  UPLOADING_DISABLED: boolean
  ENABLED_LANGUAGES: Array<string>
  LANGUAGES: Array<{}>
  ZERTES_ENABLED: boolean
  EIDAS_ENABLED: boolean
  LOCAL_COMPONENT_AND_REMOTE_SIGNATURE_OPTION: boolean
  REMOTE_SIGNATURE_OPTION: SignatureLevel
  SWISSCOM_ENABLED: boolean
  CERTILIA_ENABLED: boolean
  CERTILIA_RDD_ENABLED: boolean
  SES_ENABLED: boolean
  AES_ENABLED: boolean
  QES_ENABLED: boolean
  TOOLTIP_COMPONENT_ENABLED: boolean
  IDENT_AND_PAYMENT_SERVICE_ENABLED: boolean
  SIGNING_OPTIONS_PREDEFINED: boolean,
  DEFAULT_PREDEFINED_STANDARD: Jurisdiction
  DEFAULT_PREDEFINED_PROVIDER: SignatureProvider
  DEFAULT_PREDEFINED_LEVEL: SignatureLevel
}

enum AppEnv {
  ON_PREM = "ON_PREM",
  PRODUCTION = "PRODUCTION",
  TEST = "TEST",
  DEV = "DEV",
}

export const applicationSettings: IApplicationSettings = {
  PRODUCTION_BUILD: false,
  ENABLED_LANGUAGES: ["en", "fr", "it", "de"],
  LANGUAGES: [
    {
      code: "en",
      languageName: "EN",
      country_code: "gb",
    },
    {
      code: "fr",
      languageName: "FR",
      country_code: "fr",
    },
    {
      code: "it",
      languageName: "IT",
      country_code: "it",
    },
    {
      code: "de",
      languageName: "DE",
      country_code: "de",
    },
  ],
  UPLOADING_DISABLED: false,
  ZERTES_ENABLED: true,
  EIDAS_ENABLED: true,
  SWISSCOM_ENABLED: true,
  CERTILIA_ENABLED: false,
  CERTILIA_RDD_ENABLED: false,
  LOCAL_COMPONENT_AND_REMOTE_SIGNATURE_OPTION: false,
  REMOTE_SIGNATURE_OPTION: SignatureLevel.QES,
  SES_ENABLED: true,
  AES_ENABLED: true,
  QES_ENABLED: true,
  TOOLTIP_COMPONENT_ENABLED: false,
  IDENT_AND_PAYMENT_SERVICE_ENABLED: false,
  SIGNING_OPTIONS_PREDEFINED: false,
  DEFAULT_PREDEFINED_STANDARD: Jurisdiction.ZERTES,
  DEFAULT_PREDEFINED_PROVIDER: SignatureProvider.SwisscomAis,
  DEFAULT_PREDEFINED_LEVEL: SignatureLevel.SES,
}

export const URMId = "16ea4584-88ae-40c7-b986-8de72623aa34"
export const walletId = "db128af0-6067-4114-a729-d46209c34b8f"
export const eScanId = "e87b6c37-0f5c-4b99-821e-c3275ee57f87"
export const signatorLiteId = "e9ab12f8-c7cf-40d2-b949-185d87ede51e"
export const eFormReportId = "c70f09ad-5a66-44b2-a2ba-7b78bb22bf27"
export const eFormPublisherId = "cff841f4-643f-465b-850a-665178837117"
export const eFormBuilderId = "24c25c3f-5c60-4704-9f3e-3e64aacefd5c"
export const myFormsId = "84e2c9e8-ac7f-49b6-be35-acaf3c695c73"
export const flowsId = "34fee6ab-2104-4ced-94a3-1f393311a336"
export const identHubId = "094b0697-f25d-4c02-a90a-5e04c74d16ef"


export const languages = [{ label: "English", value: "en" }, { label: "Deutsch", value: "de" }];

export const basicGETRequestOptions = (jwt: string) => {
  return ({
    method: 'GET',
    accept: 'application/json',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + jwt
    },
  })
};

export const basicPUTRequestOptions = (jwt: string, body: any) => {
  return ({
    method: 'PUT',
    body: JSON.stringify(body),
    accept: 'application/json',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + jwt
    },
  })
};

export const basicPOSTRequestOptions = (jwt: string, body: any) => {
  return ({
    method: 'POST',
    body: JSON.stringify(body),
    accept: 'application/json',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + jwt
    },
  })
};