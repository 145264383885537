import jwt_decode from 'jwt-decode'
import { keycloak } from "../services/Keycloak";
export class User {
    Id: number | string;
    FirstName: string;
    LastName: string;
    Email: string;
    Company?: string

    constructor(Id: number, FirstName: string, LastName: string, Email: string, PhoneNumber?: string, Company?: string) {
        this.Id = Id;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Email = Email;
        this.Company = Company;
    }
}

export const getItemFromLocalStorage = (key: string): string | null => {
    const storageItem = localStorage.getItem(key)
    if (!storageItem) return null;

    return storageItem;
};

export const userBuilder = (signatorUser: string): User | null => {
    const decodedUser = jwt_decode(signatorUser) as any;
    try {
        const parsedUser = new User(decodedUser.sid, decodedUser.given_name, decodedUser.family_name, decodedUser.email);
        if (parsedUser) {
            return parsedUser;
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

export const setUser = (user: User, token: string) => {
    localStorage.setItem('signator.jwt', token)
    localStorage.setItem('signator.user', JSON.stringify(user))
}

export const updateUser = (object: User) => {
    localStorage.setItem('signator.user', JSON.stringify(object))
}

export const getLoggedUser = (): User | null => {
    const signatorJwt = getItemFromLocalStorage('signator.jwt');
    const signatorUser = getItemFromLocalStorage('signator.user');

    if (!signatorUser || !signatorJwt) return null;

    return userBuilder(signatorJwt);
}

export const getLoggedUserJwt = (): string | null | undefined => {
    return keycloak.token;
}

export const getUserDetails = (keycloakUser: any) => {
    if(keycloakUser?.email) {
        return {
            firstName: keycloakUser.given_name ?? "",
            lastName: keycloakUser.family_name ?? "",
            email: keycloakUser.email ?? "",
            organizationName: keycloakUser.organizationName ?? undefined
        }
    } else {
        return {
            firstName: "",
            lastName: "",
            email: "",
            organizationName: undefined
        }
    }
}

export const getLoggedUserEmail = () => {
    return keycloak.tokenParsed?.email ?? ""
}

export const removeUserFromLocalStorage = () => {
    localStorage.removeItem('signator.jwt')
    localStorage.removeItem('signator.user')
}
