import WalletAppIcon from "../../src/assets/applicationIcons/shop_icon_filled.svg";
import URMAppIcon from "../../src/assets/applicationIcons/urm_icon_filled.svg";
import SignatorLiteAppIcon from "../../src/assets/applicationIcons/E-sign-lite.svg";
import VizibitIcon from "../../src/assets/applicationIcons/vizibit_logo_icon.svg";
import eFormReporter from "../../src/assets/applicationIcons/E-forms-reporter.svg"
import eFormPublisher from "../../src/assets/applicationIcons/E-forms-publisher.svg"
import eFormBuilder from "../../src/assets/applicationIcons/E-forms-builder.svg"
import myForms from "../../src/assets/applicationIcons/MyE-forms.svg"
import eScan from "../../src/assets/applicationIcons/E-scan.svg"
import flowsIcon from "../../src/assets/applicationIcons/flows_icon.svg"
import identHubIcon from "../../src/assets/applicationIcons/identHubIcon.svg"

import {
    eFormBuilderId,
    eFormPublisherId,
    eFormReportId,
    eScanId,
    myFormsId,
    signatorLiteId,
    URMId,
    walletId,
    flowsId,
    identHubId
} from "../appsettings";

export function whatApplicationIconToDisplay(appId: string) {
    if (appId === URMId) return URMAppIcon;
    if (appId === walletId) return WalletAppIcon;
    if (appId === eScanId) return eScan;
    if (appId === signatorLiteId) return SignatorLiteAppIcon;
    if(appId === eFormReportId) return eFormReporter
    if(appId === eFormPublisherId) return eFormPublisher
    if(appId === eFormBuilderId) return eFormBuilder
    if(appId === myFormsId) return myForms
    if(appId === flowsId) return flowsIcon
    if (appId === identHubId) return identHubIcon
    return VizibitIcon;
}
