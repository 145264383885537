import React, { useContext } from "react";
import styles from "./PdfViewerBottomToolbar.module.css";
import SignButtonMenu from "../SignButtonMenu/SignButtonMenu";
import SigImageButton from "../SigImageButton/SigImageButton";
import RejectButton from "../RejectButton/RejectButton";
import IntroTooltip from "../IntroTooltip/IntroTooltip";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import BottomToolbarSettings from "../SettingsButtonMenu/BottomToolbarSettings";

interface IPdfViewerTopToolbar {
  isMobile: boolean;
  signingDrawerWithoutImageStateHandler: Function;
  signWithoutImageModalHandler: Function;
  signWithImageHandler: Function;
  signWithImage: Function;
  addSigImageInitial: Function;
  isSigAdded: boolean;
  deleteSigImage: Function;
  setShowRejectDialog: React.Dispatch<React.SetStateAction<boolean>>;
  errorStatePdf: boolean;
  loadingState: boolean;
  directSignHandler: Function;
  profileButtonChecked: any;
  selectedProfile: any;
}

function PdfViewerBottomToolbar(props: IPdfViewerTopToolbar) {
  const signatureParamsCtx = useContext(SignatureParametersContext);

  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const originParam = urlParams.get("origin")?.split("#")[0];

  if (signatureParamsCtx.backToDocAfterThirdPartySign) {
    return null;
  }

  if (props.isMobile && !props.errorStatePdf && !props.loadingState) {
    return (
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow: "black 0px 6px 12px 5px, black 0px 3px 7px -3px",
            bottom: "0px",
            display: "flex",
            left: "50%",
            height: "65px",
            width: "100%",
            padding: "4px",
            position: "absolute",
            transform: "translate(-50%, 0)",
            zIndex: 10000,
            marginBottom: (originParam && (originParam?.split("#")[0] === "flows" || originParam?.split("#")[0] === "office" || originParam?.split("#")[0] === "teams")) ? "0px" : "65px",
          }}
          className={styles.SignatorPdfToolbar}
        >
          <div className={styles.SignatorPdfToolbarLeft}>
            <div
              style={{
                marginRight: "5px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SignButtonMenu
                signingDrawerWithoutImageStateHandler={
                  props.signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={
                  props.signWithoutImageModalHandler
                }
                signWithImageHandler={props.signWithImageHandler}
                signWithImage={props.signWithImage}
                addSigImageInitial={props.addSigImageInitial}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
              />
                <SigImageButton
                  addSigImageInitial={props.addSigImageInitial}
                  signWithImageHandler={props.signWithImageHandler}
                  deleteSigImage={props.deleteSigImage}
                  isSigAdded={props.isSigAdded}
                  isMobile={props.isMobile}
                />
            </div>
          </div>
          <div className={styles.SignatorPdfToolbarRight}>
              <RejectButton
                showRejectHandler={props.setShowRejectDialog}
                isMobile={props.isMobile}
              />
            {/*!signatureParamsCtx.rejectUrl && (
              <SigImageButton
                addSigImageInitial={props.addSigImageInitial}
                signWithImageHandler={props.signWithImageHandler}
                deleteSigImage={props.deleteSigImage}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
              />
            )*/}
          </div>
        </div>
    );
  }

  if (!props.isMobile && !props.errorStatePdf && !props.loadingState) {
    return (
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "35px",
            bottom: "5px",
            display: "flex",
            left: "50%",
            height: "65px",
            minWidth: "200px",
            padding: "4px",
            position: "absolute",
            transform: "translate(-50%, 0)",
            zIndex: 10000,
            marginBottom: (originParam && (originParam?.split("#")[0] === "flows" || originParam?.split("#")[0] === "office" || originParam?.split("#")[0] === "teams")) ? "10px" : "80px",
          }}
          className={styles.SignatorPdfToolbar}
        >
          <div className={styles.SignatorPdfToolbarLeftDesktop}>
            <div
              style={{
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SignButtonMenu
                signingDrawerWithoutImageStateHandler={
                  props.signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={
                  props.signWithoutImageModalHandler
                }
                signWithImageHandler={props.signWithImageHandler}
                signWithImage={props.signWithImage}
                addSigImageInitial={props.addSigImageInitial}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
                directSignHandler={props.directSignHandler}
                profileButtonChecked={props.profileButtonChecked}
              />
            </div>
          </div>
          <div className={styles.SignatorPdfToolbarRight}>
            <BottomToolbarSettings isMobile={props.isMobile} showRejectHandler={props.setShowRejectDialog}/>
          </div>
        </div>
    );
  }

  return null;
}

export default PdfViewerBottomToolbar;
