import styles from "./ProfilesSidebar.module.css";
import React, { useContext, useState } from "react";
import { SignatureProfileSettings } from "../SignatureProfileComponent/SignatureProfileSettings";
import i18next from "i18next";
import AuthContext from "../../store/AuthContext";
import withoutImage from "../../images/bez-slike01.svg";
import { keycloak } from "../../services/Keycloak";
import { SelectProviderDialog } from "../SelectProviderDialog/SelectProviderDialog";

interface IProfileCard {
  profile: any;
  onDragStart: any;
  addSignature: boolean;
  createSignatureImageOnClick: (profile: any) => void;
  serverProfile: any | null;
}

export const ProfileCard = (props: IProfileCard) => {
  const authCtx = useContext(AuthContext);

  const [dragStarted, setDragStarted] = useState(false);
  const [showSelectProviderDialog, setShowSelectProviderDialog] =
    useState(false);

  const handleAddProviderToProfile = () => {
    if (props.serverProfile) {
      setShowSelectProviderDialog(true);
    } else {
      console.log("LOCAL PROFILE");
    }
  };

  if (
    authCtx.enabledProviders.filter(
      (provider: any) =>
        provider.providerKey === props.profile.txtSignatureProvider
    )?.length > 0
  ) {
    return (
      <div
        style={{ width: "100%", opacity: props.addSignature ? "40%" : "100%" }}
        className={
          !dragStarted
            ? "signatureProfileCardWrapper"
            : "signatureProfileCardWrapperDragging"
        }
      >
        <div className={"signatureProfileCardInner"}>
          <div className={"signatureProfileFrontCard"}>
            <div
              className={`signatureProfileCard${
                props.profile.txtSignatureLevel ?? "Local"
              }`}
              style={{
                backgroundColor: "white",
                marginBottom: "0px",
              }}
              draggable={true}
              //onClick={() => props.createSignatureImageOnClick(props.profile)}
              onDragStart={(e) => props.onDragStart(e, props.profile)}
            >
              <div className={styles.signatureProfileCardTop}>
                {props.profile.txtSignatureImagePicture ? (
                  <img
                    src={
                      "data:image/png;base64, " +
                      props.profile.txtSignatureImagePicture
                    }
                    width={75}
                    height={37}
                    alt={"Signature image"}
                    className={styles.signatureImageInProfile}
                  />
                ) : (
                  <img
                    src={withoutImage}
                    width={75}
                    height={37}
                    alt={"Signature image"}
                    className={styles.signatureImageInProfile}
                  />
                )}
                <div className={styles.signatureProfileBasicDetails}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                  >
                    {props.profile.profileName ? props.profile.profileName : props.profile.txtName ?? "N/A"}
                  </span>
                </div>
              </div>
              <SignatureProfileSettings
                profile={props.profile}
                smallFont={true}
              />
            </div>
          </div>
          <div
            className={"signatureProfileBackCard"}
            draggable={true}
            onDragStart={(e) => {
              setDragStarted(true);
              props.onDragStart(e, props.profile);
            }}
          >
            {i18next.t("drag_and_drop_image")}
          </div>
        </div>
      </div>
    );
  }

  if (
    keycloak.authenticated &&
    (props.profile.txtSignatureProvider === null ||
      props.profile.txtSignatureProvider === "" ||
      props.profile.txtSignatureProvider === undefined)
  ) {
    return (
      <>
        <div
          style={{
            width: "100%",
            opacity: props.addSignature ? "40%" : "100%",
          }}
          className={
            !dragStarted
              ? "signatureProfileCardWrapper"
              : "signatureProfileCardWrapperDragging"
          }
          onClick={handleAddProviderToProfile}
        >
          <div className={"signatureProfileCardInner"}>
            <div className={"signatureProfileFrontCard"}>
              <div
                className={`signatureProfileCard${
                  props.profile.txtSignatureLevel ?? "Local"
                }`}
                style={{
                  backgroundColor: "white",
                  marginBottom: "0px",
                  minHeight: "83px",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {i18next.t("click_to_select_sig_provider")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showSelectProviderDialog && (
          <SelectProviderDialog
            isSelectProviderDialogVisible={showSelectProviderDialog}
            setIsSelectProviderDialogVisible={setShowSelectProviderDialog}
            signatureProfile={props.serverProfile}
          />
        )}
      </>
    );
  }

  return (
    <div
      style={{ width: "100%", opacity: props.addSignature ? "40%" : "100%" }}
      className={
        !dragStarted
          ? "signatureProfileCardWrapper"
          : "signatureProfileCardWrapperDragging"
      }
    >
      <div className={"signatureProfileCardInner"}>
        <div className={"signatureProfileFrontCard"}>
          <div
            className={`signatureProfileCard${
              props.profile.txtSignatureLevel ?? "Local"
            }`}
            style={{
              backgroundColor: "white",
              marginBottom: "0px",
              minHeight: "83px",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "12px" }}>
              {i18next.t("invalid_sig_profile")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
