import { keycloak } from "../services/Keycloak";
import { setUser, userBuilder } from "../classes/User";
export const RefreshTokenMiddleware = async (): Promise<boolean> => {
    if(keycloak.authenticated){
        if(keycloak.isTokenExpired()){
            try {
                const refreshed = await keycloak.updateToken(5);
                if(refreshed && keycloak.token){
                    const newUser = userBuilder(keycloak.token);
                    if (newUser) setUser(newUser, keycloak.token);
                    return true;
                } else {
                    return false;
                }
            } catch (e){
                console.log(e)
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}
