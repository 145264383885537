import React from "react";
import Error from "../images/error.jpg";
import styles from "./LandingPage.module.css";

import { useNavigate } from "react-router-dom";
import i18next from "i18next";

export default function ErrorPage() {
    const navigate = useNavigate();

    return (
        <div>
            <img src={Error} className={styles.errorImg} />
            <div>
                <h2 className={styles.errorTitle}>{i18next.t("something_went_wrong")}</h2>
            </div>
        </div>
    );
}