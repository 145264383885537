import axios from "axios";
export const RejectSignature = async (
    token: string,
    rejectReason: string
) => {
    const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/v/1/lwms/external/action/reject",
        {
            "message": rejectReason,
        },
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );

    return response;
};