import { basicGETRequestOptions } from "../appsettings";
import { getLoggedUserJwt } from "../classes/User";
import { RefreshTokenMiddleware } from "./RefreshTokenMiddleware";
import { handleResponseNotOK } from "./handleResponseNotOk";
import i18n, { t } from "i18next";
import { ErrorModalService } from "@signator/signator_react_components/dist/components/ErrorModalService/ErrorModalService";

export async function GetAllSignatureCards() {
  const refreshToken = await RefreshTokenMiddleware();

  if (refreshToken) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URM_BASE_URL}/v/1/permission-management/signature-card`,
        basicGETRequestOptions(getLoggedUserJwt() ?? "")
      );

      const data = await handleResponseNotOK(
        response,
        t("error_getting_signature_card")
      );

      return data;
    } catch (e: any) {
      if (e.message && e.message === "handled_error") {
        throw new Error("Handled error");
      } else {
        new ErrorModalService(
          undefined,
          i18n.language,
          undefined,
          t("error_getting_signature_card")
        ).showErrorModal();
        console.log(e);
        throw new Error("Error getting signature cards: ");
      }
    }
  } else {
    console.log("Error refreshing token");
    window.location.reload();
    throw new Error("Error refreshing token");
  }
}