import { authenticatedState, keycloak } from "../../services/Keycloak";
import AccountIcon from "../../images/person_icon_purple.svg";
import MoneyIcon from "../../images/money_hands_icon_purple.svg";
import {HeaderComponent, PendingInviteDialog} from "@signator/signator_react_components";
import { getUserDetails, setUser, userBuilder } from "../../classes/User";
import { useNavigate } from "react-router-dom";
import styles from "./Toolbar.module.css";
import i18next from "i18next";
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  languages,
} from "../../appsettings";
import signatorLiteIcon from "../../assets/applicationIcons/E-sign-lite.svg";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import useFetchUserInvites from "../../store/useFetchUserInvites";
import {GetAllAppWithinCategory} from "../../services/GetAllAppWithinCategory";
import {whatApplicationIconToDisplay} from "../../services/whatApplicationIconToDisplay";
import warningICon from "../../images/warning_icon_yellow.svg"
import {getApplicationRoute} from "../../services/getApplicationRoute";
import AcceptInvite from "../../services/AcceptInvite";
import RejectInvite from "../../services/RejectInvite";
import {
    IInviteNotificationsComponentProps
} from "@signator/signator_react_components/dist/components/domain/ComponentSpecs";
import AuthContext from "../../store/AuthContext";

export const Toolbar = (props: any) => {
  const navigate = useNavigate();
  const signatureParamtersCtx = useContext(SignatureParametersContext);
  const [balanceAmount, setBalanceAmount] = React.useState("");
  const [showInvitePendingDialog, setShowInvitePendingDialog] = useState<boolean>(false)
  const [isRejectBtnLoading, setIsRejectBtnLoading] = useState<boolean>(false);
  const [isAcceptBtnLoading, setIsAcceptBtnLoading] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState(keycloak.tokenParsed?.locale ?? i18next.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [moduleItems, setModuleItems] = useState([]);

  const { invites, updateInvites} = useFetchUserInvites();

  const authCtx = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (authCtx.loggedIn) {
      setShowInvitePendingDialog(true);
    }
  }, [authCtx.loggedIn]);

  const changeLanguageHandler = (event: string) => {
    const iframe = document.createElement('iframe');
    iframe.src = process.env.REACT_APP_IDP_URL + "realms/signator/account/sessions?kc_locale=" + event;
    iframe.style.display = 'none';

    // Append to body for a brief period
    document.body.appendChild(iframe);

    // Remove after 10 seconds
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 10000);

    setLanguage(event)
  }

    useEffect(() => {
        const fetchAllApplications = async () => {
            if (keycloak.token) {
                const newUser = userBuilder(keycloak.token);
                if (newUser && authenticatedState && moduleItems.length === 0) {
                    setUser(newUser, keycloak.token)
                    try {
                        const response = await GetAllAppWithinCategory(newUser.Id.toString());
                        const assignedApplications = response.filter((item:any) => item.assigned);

                        // Sorting apps by name: flows, lite, wallet and users and all other applications in the future.
                        const customSort = (a: any, b:any) => {
                            const order =  { "One": 1, "Flows": 2, "Users": 3, "Wallet": 4 };
                            return (order as any)[a.application.name] - (order as any)[b.application.name];
                        };

                        // Sort the assigned applications
                        assignedApplications.sort(customSort);


                        const newModuleItems = assignedApplications.map((item: any) => ({
                            columnTitle: "",
                            items: [
                                {
                                    name: item.application.name,
                                    icon: whatApplicationIconToDisplay(item.application.id),
                                    route: item.application.url,
                                    isSelected: false
                                }
                            ]
                        }));
                        setModuleItems(newModuleItems);
                    } catch (error) {
                        console.log(error);
                    }
                }
            };
        }
        fetchAllApplications().then();
    }, [keycloak.token]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  function setLanguage(selectedLanguage: any) {
    i18next.changeLanguage(selectedLanguage).then(() => setCurrentLanguage(selectedLanguage));
  }

    const handleAcceptInvite = async (id: string, organizationId: string) => {
      setIsAcceptBtnLoading(true);
      try {
            const acceptInvitationRequest = {
                id: id,
                organizationId: organizationId
            };
            const response = await AcceptInvite(acceptInvitationRequest);
            if (response) {
                updateInvites()
                window.location.reload();
            }
        } catch (error) {
            console.error("Error accepting invitation:", error);
        } finally {
        setIsAcceptBtnLoading(false);
      }
    };

    const handleRejectInvite = async (id: string, organizationId: string) => {
      setIsRejectBtnLoading(true);
      try {
            const rejectInvitationRequest = {
                id: id,
                organizationId: organizationId
            };
            const response = await RejectInvite(rejectInvitationRequest);
            if (response) {
                updateInvites()
                window.location.reload();
            }
        } catch (error) {
            console.error("Error rejecting invitation:", error);
        } finally {
        setIsRejectBtnLoading(false);
      }
    }

    const inviteProps: IInviteNotificationsComponentProps = {
        userId: keycloak.tokenParsed?.sub ?? "",
        inviteState: {
            invites: invites.invites
        },
        acceptInvites: (userId, orgId) => {
            handleAcceptInvite(userId, orgId)
        },
        rejectInvites: (userId, orgId) => {
            handleRejectInvite(userId, orgId)
        },
        rejectText: t("reject"),
        acceptText: t("accept"),
        inviteTitle: invites.invites.length > 1 ? t("pending_invites") : t("pending_invite") ,
        inviteDescription: t("invite_pending_description"),
        noInviteText: t("no_pending_invites"),
        inviteCancelConfirmText: t("reject"),
        iconConfirmInvite: warningICon,
        inviteAcceptConfirmText: t("confirm"),
        popUpDescription: t("confirm_invite_description_1") ,
        popUpDescription1: t("confirm_invite_description_2"),
        popUpDescription2: t("confirm_invite_description_3"),
        popUpDescription3: t("confirm_invite_description_4"),
        popUpTitle: t("confirm_invite_title"),
        acceptBtnIsLoading: isAcceptBtnLoading,
        rejectBtnIsLoading: isRejectBtnLoading
    };

  if(props.loadingFile){
    return <></>
  }

  return (
    <nav
      className={styles.Toolbar}
      style={
        props.width
          ? { width: "calc(100% - 100px)" }
          : { width: "100% !important" }
      }
    >
      <HeaderComponent
        showBurgerButton={false}
        signOutFn={() => keycloak.logout()}
        headerBrandingLogo={"Vizibit"}
        headerTitle={"One"}
        searchBar={null}
        onLanguageChange={changeLanguageHandler}
        userDataObject={getUserDetails(keycloak.tokenParsed)}
        onClickBurgerHandler={() => {}}
        onClickLogoHandler={() => {
          if (signatureParamtersCtx.loadedInIframe) {
            return;
          } else {
            window.open(window.location.origin, "_self");
          }
        }}
        thirdPartyLogo={signatorLiteIcon}
        languages={languages as any}
        moduleItems={moduleItems}
        showGridIcon={authCtx.loggedIn}
        isDropdownOpen={isDropdownOpen}
        onDropdownToggle={toggleDropdown}
        onBlur={closeDropdown}
        showInvitesIcons={authCtx.loggedIn}
        inviteProps={inviteProps}
        headerAccountOptions={[
          {
            label: i18next.t("manage_account"),
            icon: AccountIcon,
            onClick: () => {
              window.open(process.env.REACT_APP_IDP_URL + "/realms/signator/account/", "_blank");
            },
          },
        ]}
        headerAccountBalance={{
          label: i18next.t("wallet_balance"),
          icon: MoneyIcon,
          value: balanceAmount + " €",
          walletLink: process.env.REACT_APP_WALLET_URL ?? "",
        }}
        defaultLanguage={languages.find((element) => element.value === currentLanguage) ?? {
          label: "English",
          value: "en"
        }}
        signInUrl={keycloak.login}
        signOutTranslation={t("sign_out")}
        footerLinks={{
          privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY_LINK ?? "",
          termsAndConditions: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK ?? "",
          support: process.env.REACT_APP_SUPPORT_LINK ?? ""
        }}
        footerLinksLabels={{
          privacyPolicy: i18next.t("privacy_policy"),
          termsAndConditions: i18next.t("terms_and_conditions"),
          support: i18next.t("support")
        }}
        legacyRouter={true}
        showHelpIcon={true}
        helpUrl={process.env.REACT_APP_SUPPORT_LINK}
      />
      {showInvitePendingDialog &&
          <PendingInviteDialog
              userId={keycloak.tokenParsed?.sub ?? ""}
              inviteTitle={invites.invites.length > 1 ? t("pending_invites") : t("pending_invite")}
              inviteState={invites as any}
              inviteDescription={t("invite_pending_description")}
              btnTextAccept={t("accept")}
              btnTextReject={t("reject")}
              showDialog={true}
              setShowDialog={setShowInvitePendingDialog}
              rejectInvites={handleRejectInvite}
              acceptInvites={handleAcceptInvite}
              multipleInvitesDescription={t("multiple_invites_description")}
              popUpDescriptionTitle={t("confirm_invite_description_1")}
              popUpDescription1={t("confirm_invite_description_2")}
              popUpDescription2={t("confirm_invite_description_3")}
              popUpDescription3={t("confirm_invite_description_4")}
              popUpTitle={t("confirm_invite_title")}
              inviteAcceptConfirmText={t("confirm")}
              inviteCancelConfirmText={t("reject")}
              acceptBtnIsLoading={isAcceptBtnLoading}
              rejectBtnIsLoading={isRejectBtnLoading}
          />}
    </nav>
  );
};

export default Toolbar;
