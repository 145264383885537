import React, { useContext, useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import i18next from "i18next";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const BottomToolbarSettings = (props: any) => {
  const signatureParamsCtx = useContext(SignatureParametersContext);

  const options = ["reject_button"];

  //state variables
  const [openSignButtonDropdown, setOpenSignButtonDropdown] = useState({
    open: false,
    selectedIndex: 0,
  });

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);
  //handlers
  const handleSignMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: false,
      selectedIndex: index,
    }));
    if (index === 0) {
      props.showRejectHandler(true);
    }
  };

  const handleSignMenuToggle = () => {
    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: !openSignButtonDropdown.open,
    }));
  };

  const handleSignMenuClose = (event: Event) => {
    if (
      signButtonDropdownAnchorRef.current &&
      signButtonDropdownAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenSignButtonDropdown((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Button
          size="small"
          aria-controls={
            openSignButtonDropdown.open ? "split-button-menu" : undefined
          }
          aria-expanded={openSignButtonDropdown.open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleSignMenuToggle}
          style={{
            backgroundColor: props.backgroundColor
              ? props.backgroundColor
              : "white",
            color: "#623d91",
            fontSize: "12px",
            height: props.isMobile ? "30px" : "40px",
            minWidth: "28px",
            padding: "4px",
            marginLeft: props.isMobile ? "4px" : "10px",
          }}
        >
          <MoreOutlined className={"moreIconButton"} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openSignButtonDropdown.open}
        anchorEl={signButtonDropdownAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleSignMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      style={{
                        fontSize: "13px",
                        color: "#4188bb",
                        borderBottom:
                          index < options.length - 1
                            ? "1px solid #4188bb"
                            : "none",
                        fontWeight: "bold",
                        fontFamily: "OpenSans",
                      }}
                      key={option}
                      selected={index === openSignButtonDropdown.selectedIndex}
                      onClick={(event) => handleSignMenuItemClick(event, index)}
                    >
                      {index === 0 && i18next.t(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default BottomToolbarSettings;
