import styles from "./ProfilesSidebar.module.css";
import i18next from "i18next";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import Kartica from "../../images/potpis_karticom.svg";
import Udaljeni from "../../images/udaljeni_potpis.svg";
import ATrust from "../../images/a-trust_logo.png";
import {ProvidersSettings} from "../../store/ProvidersSettings";
import AuthContext from "../../store/AuthContext";
import {keycloak} from "../../services/Keycloak";
import {SelectProviderDialog} from "../SelectProviderDialog/SelectProviderDialog";

interface IProfileCardNoImage {
  profile: any;
  selectedProfile: any;
  setSelectedProfile: any;
  checkedRadioButton: any;
  setCheckedRadioButton: any;
  serverProfile: any | null;

}

export const ProfileCardNoImage = (props: IProfileCardNoImage) => {
  const authCtx = useContext(AuthContext);
  const [showSelectProviderDialog, setShowSelectProviderDialog] =
    useState(false);
  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setCheckedRadioButton(e.target.value);
    props.setSelectedProfile(props.profile);
  };

  const handleOptionClick = () => {
    props.setCheckedRadioButton(props.profile.profileId);
    props.setSelectedProfile(props.profile);
  };

  const handleAddProviderToProfile = () => {
    if (props.serverProfile) {
      setShowSelectProviderDialog(true);
    } else {
      console.log("LOCAL PROFILE");
    }
  };

  if(authCtx.enabledProviders.filter(
    (provider: any) =>
      provider.providerKey === props.profile.txtSignatureProvider
  )?.length > 0){
    return (
      <>
        <div className={styles.profileNoImageContainer}>
          {props.profile.txtSignatureProvider === "ExternalLocalComponent" ? (
            <div
              className={
                props.profile.profileId === props.checkedRadioButton
                  ? styles.profileNoImageInformationChecked
                  : styles.profileNoImageInformation
              }
              onClick={handleOptionClick}
            >
              <input
                type="radio"
                id={props.profile.profileId}
                value={props.profile.profileId}
                onChange={onRadioChange}
                checked={props.checkedRadioButton === props.profile.profileId}
                className={styles.customRadioButtonInput}
              />
              <div
                style={{
                  width: "90%",
                  textAlign: "center",
                  paddingLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img src={Kartica} height={30} />
                {i18next.t("smart_card")}
              </div>
              <span className={styles.customRadioButtonCheckmark}></span>
            </div>
          ) : (
            <div
              className={
                props.profile.profileId === props.checkedRadioButton
                  ? styles.profileNoImageInformationChecked
                  : styles.profileNoImageInformation
              }
              onClick={handleOptionClick}
            >
              <input
                type="radio"
                id={props.profile.profileId}
                value={props.profile.profileId}
                onChange={onRadioChange}
                checked={props.checkedRadioButton === props.profile.profileId}
                className={styles.customRadioButtonInput}
              />
              <div
                style={{
                  //borderRight: "1px solid #afafaf",
                  display: "flex",
                  width: "33%",
                  justifyContent: "center",
                  fontSize: "9px",
                  height: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={Udaljeni} height={30} />
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "50px",
                  }}
                >
                {i18next.t("remote_sig")}
              </span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "33%",
                  justifyContent: "center",
                  fontSize: "9px",
                  height: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {authCtx.enabledProviders.filter(
                  (provider: any) =>
                    provider.providerKey === props.profile.txtSignatureProvider
                )?.length > 0 ?
                  <img
                    src={
                      authCtx.enabledProviders.filter(
                        (provider: any) =>
                          provider.providerKey ===
                          props.profile.txtSignatureProvider
                      )?.[0]?.providerIcon ?? undefined
                    }
                    style={{ paddingTop: "2px" }}
                    height={25}
                  /> :
                  <span>Invalid sig. profile</span>
                }
              </div>
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className={styles.customRadioButtonCheckmark}></span>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  if (
    keycloak.authenticated &&
    (props.profile.txtSignatureProvider === null ||
      props.profile.txtSignatureProvider === "" ||
      props.profile.txtSignatureProvider === undefined)
  ) {
    return (
      <>
        <div className={styles.profileNoImageContainer}>
          <div
            className={
              props.profile.profileId === props.checkedRadioButton
                ? styles.profileNoImageInformationChecked
                : styles.profileNoImageInformation
            }
            style={{justifyContent: "center"}}
            onClick={handleAddProviderToProfile}
          >
            <div>{i18next.t("click_to_select_sig_provider")}</div>
          </div>
        </div>
        {showSelectProviderDialog && (
          <SelectProviderDialog
            isSelectProviderDialogVisible={showSelectProviderDialog}
            setIsSelectProviderDialogVisible={setShowSelectProviderDialog}
            signatureProfile={props.serverProfile}
          />
        )}
      </>
    )
  }
  return (
    <>
      <div className={styles.profileNoImageContainer}>
          <div
            className={
              props.profile.profileId === props.checkedRadioButton
                ? styles.profileNoImageInformationChecked
                : styles.profileNoImageInformation
            }
            style={{justifyContent: "center"}}
          >
            <div>{i18next.t("invalid_sig_profile")}</div>
          </div>
      </div>
    </>
  );
};
