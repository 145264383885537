import React from "react";
import { SignatureProvider } from "../components/SigningComponent/SigningComponent";
import i18next from "i18next";

interface ISignatureParametersContext {
  predefinedSignatureOptions: boolean;
  signatureToken: string | undefined | null;
  pdfFile: Uint8Array | undefined;
  pdfFileBase64: string | undefined;
  phoneNumber: string | undefined | null;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  jurisdiction: string | undefined | null;
  signatureLevel: string | undefined | null;
  prepaid: string | undefined | null;
  targetIdentUrl: string | undefined | null;
  storageId: string | undefined | null;
  signatureProvider: SignatureProvider;
  comingFromThirdParty: boolean | undefined;
  loadedInIframe: boolean | undefined;
  backToDocAfterThirdPartySign: boolean | undefined;
  rejectUrl: string | undefined | null;
  isDocumentSigned: boolean;
  signatureType: "image" | "noimage" | "signatureInitials";
  pdfFileName: string;
  successfulSignature: boolean;
  unsuccessfulSignature: boolean;
}

const SignatureParametersContext =
  React.createContext<ISignatureParametersContext>({
    predefinedSignatureOptions: false,
    signatureToken: undefined,
    pdfFile: undefined,
    pdfFileBase64: undefined,
    phoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    jurisdiction: undefined,
    signatureLevel: undefined,
    prepaid: undefined,
    targetIdentUrl: undefined,
    storageId: undefined,
    signatureProvider: SignatureProvider.ATrust,
    comingFromThirdParty: false,
    backToDocAfterThirdPartySign: false,
    rejectUrl: undefined,
    isDocumentSigned: false,
    signatureType: "image",
    pdfFileName: i18next.t("untitled_document"),
    loadedInIframe: false,
    successfulSignature: false,
    unsuccessfulSignature: false
  });

export default SignatureParametersContext;
