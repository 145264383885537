import i18next from "i18next";
import { useCallback, useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { ErrorModalService } from "../ErrorHandling/ErrorModalService";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import {CreateWorkflow} from "../../services/CreateWorkflow";

const baseStyle = {
  display: "flex",
  alignItems: "center",
  width: "80%",
  height: "80%",
  borderWidth: 3,
  borderRadius: 5,
  borderColor: "rgb(163 148 148)",
  borderStyle: "dashed",
  backgroundColor: "rgba(249,245,255,0)",
  color: "rgb(163 148 148)",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DropzoneComponent(props: any) {
  const signingCtx = useContext(SignatureParametersContext);

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    props.setLoadingFile(true);
    try {
      const wf = await CreateWorkflow(
          acceptedFiles[0].name,
          acceptedFiles[0]
      );

      if (wf && wf.actionUrl) {
        signingCtx.pdfFileName = acceptedFiles[0].name;

        window.location.replace(wf.actionUrl);
        setTimeout(() => {
          props.setLoadingFile(false);
        }, 2000)
      } else {
        new ErrorModalService(37).showErrorModal();
        setTimeout(() => {
          props.setLoadingFile(false);
        }, 1000)      }
    } catch (e) {
      console.log(e);
      setTimeout(() => {
        props.setLoadingFile(false);
      }, 1000)    }
  }, []);

  const onDropRejected = useCallback(async (rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      new ErrorModalService(4).showErrorModal();
    } else {
      new ErrorModalService(4).showErrorModal();
    }
  }, []);

  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "application/pdf",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <div style={{ textAlign: "center", fontSize: "large", width: "100%" }}>
        {i18next.t("drag_drop_or_click")}
      </div>
    </div>
  );
}

export default DropzoneComponent;
