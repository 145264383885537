import { GetUserInvites } from "../services/GetUserInvites";
import { useEffect, useState } from "react";
import {keycloak} from "../services/Keycloak";


const useFetchUserInvites = () => {
    const [invites, setInvites] = useState({
        invites: []
    });

    const updateInvites = async () => {
        try {
            const response = await GetUserInvites();
            setInvites(prevState => ({
                ...prevState,
                invites: response
            }));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (keycloak.authenticated && keycloak.token) {
            updateInvites();
        }
    }, [keycloak.authenticated]);

    return { invites, updateInvites };
};

export default useFetchUserInvites
