import styles from "./ProfilesSidebar.module.css";
import BigActionButton from "@signator/signator_react_components/dist/components/BigActionButton";
import InfoIcon from "../../images/info_icon_grey.svg";
import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { v4 as uuid } from "uuid";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import { ProfileCard } from "./ProfileCard";
import { ProfileCardNoImage } from "./ProfileCardNoImage";
import DeleteContextMenu from "../SignContextMenu/DeleteContextMenu";
import AddIcon from "../../images/plus_icon_white.svg";
import {
  IeSignLiteSignatureArray,
  IeSignLiteSignatureOnlyArray,
  storage,
} from "../../services/indexDb";
import { keycloak } from "../../services/Keycloak";
import { GetAllSignatureCards } from "../../services/GetAllSignatureCards";
import {
  transformSignatureProfiles,
  transformSignatureProfilesNoImage,
} from "../../services/utilityServices";
import AuthContext from "../../store/AuthContext";

interface IProfilesSidebar {
  signingDrawerWithImageStateHandler: Function;
  signingDrawerWithoutImageStateHandler: Function;
  onDragStart: any;
  addSignature: boolean;
  selectedProfile: any;
  setSelectedProfile: any;
  checkedRadioButton: any;
  setCheckedRadioButton: any;
  createSignatureImageOnClick: (profile: any) => void;
  signingDrawerState: boolean;
  firstTimeVisitorModal: boolean;
}

export const ProfilesSidebar = (props: IProfilesSidebar) => {
  const [profiles, setProfiles] = useState([] as any[]);
  const [serverSignatureProfiles, setServerSignatureProfiles] = useState(
    [] as any
  );
  const [loading, setLoading] = useState(true);
  const [updateProfiles, setUpdateProfiles] = useState(new Date());

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const authCtx = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const originParam = urlParams.get("origin")?.split("#")[0];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const unique_id = uuid();

  useEffect(() => {
    const getProfiles = async () => {
      if (
        keycloak.authenticated &&
        signatureParamsCtx.signatureType === "image" && !props.signingDrawerState
      ) {
        setLoading(true);
        try {
          const response = await GetAllSignatureCards();

          if (response?.data && response?.data?.length > 0) {
            let transformedProfiles = [];
            let serverProfiles = [];
            let signatureImages = response.data.filter(
              (item: any) => item.profile === 0
            );
            for (let i = 0; i < signatureImages.length; i++) {
              const transformedProfile = await transformSignatureProfiles(
                signatureImages[i]
              );
              serverProfiles.push(signatureImages[i]);
              transformedProfiles.push(transformedProfile);
            }
            setServerSignatureProfiles(serverProfiles);
            setProfiles(transformedProfiles);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (e) {
          setServerSignatureProfiles([]);
          setProfiles([]);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          console.log(e);
        }
      } else if (
        keycloak.authenticated &&
        signatureParamsCtx.signatureType === "signatureInitials" && !props.signingDrawerState
      ) {
        try {
          setLoading(true);
          const response = await GetAllSignatureCards();

          if (response?.data && response?.data?.length > 0) {
            let transformedProfiles = [];
            let serverProfiles = [];
            let signatureInitials = response.data.filter(
              (item: any) => item.profile === 1
            );
            for (let i = 0; i < signatureInitials.length; i++) {
              const transformedProfile = await transformSignatureProfiles(
                signatureInitials[i]
              );
              serverProfiles.push(signatureInitials[i]);
              transformedProfiles.push(transformedProfile);
            }
            setServerSignatureProfiles(serverProfiles);
            setProfiles(transformedProfiles);
          }

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (e) {
          setServerSignatureProfiles([]);
          setProfiles([]);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          console.log(e);
        }
      } else if (
        keycloak.authenticated &&
        signatureParamsCtx.signatureType === "noimage" && !props.signingDrawerState
      ) {
        setLoading(true);
        try {
          const response = await GetAllSignatureCards();

          if (response?.data && response?.data?.length > 0) {
            let transformedProfiles = [];
            let serverProfiles = [];
            let signatureImages = response.data.filter(
              (item: any) => item.profile === 0
            );
            for (let i = 0; i < signatureImages.length; i++) {
              const transformedProfile =
                await transformSignatureProfilesNoImage(signatureImages[i]);
              serverProfiles.push(signatureImages[i]);
              transformedProfiles.push(transformedProfile);
            }
            setServerSignatureProfiles(serverProfiles);
            setProfiles(transformedProfiles);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (e) {
          setServerSignatureProfiles([]);
          setProfiles([]);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          console.log(e);
        }
      } else {
        if (!props.firstTimeVisitorModal && !props.signingDrawerState) {
          if (!loading) {
            setLoading(true);
          }

          let timeout = setTimeout(() => {
            setLoading(false);
          }, 400);
          if (signatureParamsCtx.signatureType === "image") {
            props.setSelectedProfile({});
            props.setCheckedRadioButton(undefined);

            storage
              .getItem<{
                eSignLiteSignature: IeSignLiteSignatureArray;
                eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
              }>("eSignLite")
              .then((result) => {
                if (
                  result &&
                  result.eSignLiteSignature &&
                  result.eSignLiteSignature.items &&
                  result.eSignLiteSignature.items.filter(
                    (item) => item.txtType === "IMAGE"
                  ).length > 0
                ) {
                  setProfiles(result.eSignLiteSignature.items);
                }

                if (
                  result?.eSignLiteSignature === undefined ||
                  result?.eSignLiteSignature?.items?.length === 0
                ) {
                  setProfiles([]);
                }
              });
          }

          if (signatureParamsCtx.signatureType === "noimage") {
            //ADDED TIMEOUT TO ENSURE I'M GETTING LATEST DATA FROM INDEXDB
            setTimeout(() => {
              storage
                .getItem<{
                  eSignLiteSignature: IeSignLiteSignatureArray;
                  eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
                }>("eSignLite")
                .then((result) => {
                  if (
                    result &&
                    result.eSignLiteSignatureOnly &&
                    result.eSignLiteSignatureOnly.items &&
                    result.eSignLiteSignatureOnly.items.filter(
                      (item) => item.txtType === "NO_IMAGE"
                    ).length > 0
                  ) {
                    setProfiles(result.eSignLiteSignatureOnly.items);
                  }

                  if (
                    result?.eSignLiteSignatureOnly === undefined ||
                    Object.keys(result?.eSignLiteSignatureOnly as Object)
                      .length === 0 ||
                    (result?.eSignLiteSignatureOnly.items &&
                      result?.eSignLiteSignatureOnly.items.length === 0)
                  ) {
                    setProfiles([]);
                  }
                });
            }, 400);
          }

          return () => clearTimeout(timeout);
        }
      }
    };

    getProfiles().then((_) => {});
  }, [
    signatureParamsCtx.signatureType,
    updateProfiles,
    authCtx.updateBalance,
    props.signingDrawerState,
    props.firstTimeVisitorModal,
  ]);

  const removeSavedSignatureProfileHandler = (profileId: any) => {
    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        try {
          let profileToDelete = result?.eSignLiteSignatureOnly.items.findIndex(
            (item) => item.profileId === profileId
          );

          if (result && profileToDelete !== undefined) {
            let profiles = result.eSignLiteSignatureOnly.items;
            profiles.splice(profileToDelete, 1);

            storage
              .setItem("eSignLite", {
                eSignLiteSignature: {
                  ...result?.eSignLiteSignature,
                },
                eSignLiteSignatureOnly: {
                  items: profiles,
                },
              })
              .then((res) => {
                setUpdateProfiles(new Date());
              })
              .catch((e) => console.log(e));
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  const removeSavedSignatureImageProfileHandler = (profileId: any) => {
    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        try {
          let profileToDelete = result?.eSignLiteSignature.items.findIndex(
            (item) => item.profileId === profileId
          );

          if (result && profileToDelete !== undefined) {
            let profiles = result.eSignLiteSignature.items;
            profiles.splice(profileToDelete, 1);

            storage
              .setItem("eSignLite", {
                eSignLiteSignatureOnly: {
                  ...result?.eSignLiteSignatureOnly,
                },
                eSignLiteSignature: {
                  items: profiles,
                },
              })
              .then((res) => {
                setUpdateProfiles(new Date());
              })
              .catch((e) => console.log(e));
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  const newButtonClickhandler = () => {
    if (keycloak.authenticated) {
      window.open(process.env.REACT_APP_SIGNATURE_PROFILES_URL, "_blank");
    } else {
      if (signatureParamsCtx.signatureType === "image") {
        props.signingDrawerWithImageStateHandler();
      } else {
        props.signingDrawerWithoutImageStateHandler();
        /* storage
           .getItem<{
             eSignLiteSignature: IeSignLiteSignatureArray;
             eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
           }>("eSignLite")
           .then((result) => {
             let newProfileToAdd = {
               txtType: "NO_IMAGE",
               default: false,
               profileId: unique_id,
             };
             if (
               result?.eSignLiteSignatureOnly &&
               result?.eSignLiteSignatureOnly.items &&
               result.eSignLiteSignatureOnly?.items.length > 0
             ) {
               let updatedArray = result.eSignLiteSignatureOnly.items;
               updatedArray.push(newProfileToAdd);
               storage.setItem("eSignLite", {
                 eSignLiteSignature: {
                   ...result?.eSignLiteSignature,
                 },
                 eSignLiteSignatureOnly: { items: [...updatedArray] },
               });
               setUpdateProfiles(new Date());
             } else {
               newProfileToAdd = { ...newProfileToAdd };
               storage.setItem("eSignLite", {
                 eSignLiteSignature: {
                   ...result?.eSignLiteSignature,
                 },
                 eSignLiteSignatureOnly: {
                   items: [newProfileToAdd],
                 },
               });
               setUpdateProfiles(new Date());
             }
           })
           .catch((e) => {
             setUpdateProfiles(new Date());
             console.log(e);
             new ErrorModalService(29).showErrorModal();
           });*/
      }
    }
  };

  if (signatureParamsCtx.signatureType === "noimage") {
    return (
      <div
        className={styles.signatorProfilesSidebar}
        style={{
          marginTop:
            originParam &&
            (originParam?.split("#")[0] === "flows" ||
              originParam?.split("#")[0] === "office" ||
              originParam?.split("#")[0] === "teams")
              ? "50px"
              : "initial",
        }}
      >
        <div className={styles.createNewSidebarContainer}>
          <BigActionButton
            btnText={i18next.t("create_new_no_image")}
            onClickFunction={newButtonClickhandler}
            btnIcon={AddIcon}
          />
        </div>
        <div
          style={{
            fontSize: "11px",
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          {i18next.t("choose_sig_no_image")}
        </div>
        <div
          className={styles.profilesContainer}
          style={{
            justifyContent:
              profiles && profiles.length > 0 ? "flex-start" : "center",
          }}
        >
          {loading && (
            <Spin
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "70px",
              }}
              indicator={antIcon}
            />
          )}
          {!loading && profiles.length === 0 && (
            <>
              <img
                src={InfoIcon}
                height={50}
                width={50}
                style={{ marginTop: "20px" }}
              />
              <span
                style={{ color: "gray", width: "70%", textAlign: "center" }}
              >
                {i18next.t("no_available_signature_images")}
              </span>
            </>
          )}
          {!loading &&
            profiles.length > 0 &&
            profiles.map((profile, index) => {
              if (keycloak.authenticated) {
                return (
                  <ProfileCardNoImage
                    profile={profile}
                    selectedProfile={props.selectedProfile}
                    setSelectedProfile={props.setSelectedProfile}
                    checkedRadioButton={props.checkedRadioButton}
                    setCheckedRadioButton={props.setCheckedRadioButton}
                    serverProfile={serverSignatureProfiles[index] ?? null}
                  />
                );
              } else {
                return (
                  <DeleteContextMenu
                    removeProfileHandler={() =>
                      removeSavedSignatureProfileHandler(profile.profileId)
                    }
                    key={index}
                  >
                    <ProfileCardNoImage
                      profile={profile}
                      selectedProfile={props.selectedProfile}
                      setSelectedProfile={props.setSelectedProfile}
                      checkedRadioButton={props.checkedRadioButton}
                      setCheckedRadioButton={props.setCheckedRadioButton}
                      serverProfile={serverSignatureProfiles[index] ?? null}
                    />
                  </DeleteContextMenu>
                );
              }
            })}
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.signatorProfilesSidebar}
      style={{
        marginTop:
          originParam &&
          (originParam?.split("#")[0] === "flows" ||
            originParam?.split("#")[0] === "office" ||
            originParam?.split("#")[0] === "teams")
            ? "50px"
            : "initial",
      }}
    >
      <div className={styles.createNewSidebarContainer}>
        <BigActionButton
          btnText={i18next.t("create_new_profile")}
          onClickFunction={newButtonClickhandler}
          btnIcon={AddIcon}
        />
      </div>
      <div
        style={{
          fontSize: "11px",
          textAlign: "center",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {i18next.t("click_on_signature_image")}
      </div>
      <div
        className={styles.profilesContainer}
        style={{
          justifyContent:
            profiles && profiles.length > 0 ? "flex-start" : "center",
        }}
      >
        {loading && (
          <Spin
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "70px",
            }}
            indicator={antIcon}
          />
        )}
        {!loading && profiles.length === 0 && (
          <>
            <img
              src={InfoIcon}
              height={50}
              width={50}
              style={{ marginTop: "20px" }}
            />
            <span style={{ color: "gray", width: "70%", textAlign: "center" }}>
              {i18next.t("no_available_signature_images")}
            </span>
          </>
        )}
        {!loading &&
          profiles.length > 0 &&
          profiles.map((profile, index) => {
            if (keycloak.authenticated) {
              return (
                <ProfileCard
                  profile={profile}
                  onDragStart={props.onDragStart}
                  addSignature={props.addSignature}
                  createSignatureImageOnClick={
                    props.createSignatureImageOnClick
                  }
                  serverProfile={serverSignatureProfiles[index] ?? null}
                />
              );
            } else {
              return (
                <DeleteContextMenu
                  removeProfileHandler={() =>
                    removeSavedSignatureImageProfileHandler(profile.profileId)
                  }
                  key={index}
                >
                  <ProfileCard
                    profile={profile}
                    onDragStart={props.onDragStart}
                    addSignature={props.addSignature}
                    createSignatureImageOnClick={
                      props.createSignatureImageOnClick
                    }
                    serverProfile={serverSignatureProfiles[index] ?? null}
                  />
                </DeleteContextMenu>
              );
            }
          })}
      </div>
    </div>
  );
};
