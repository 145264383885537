import Keycloak from "keycloak-js";

const keyCloakconfig = {
    url: process.env.REACT_APP_IDP_URL,
    realm: "signator",
    clientId: "signator-lite-client",
};

const keycloak = new Keycloak(keyCloakconfig);
let authenticatedState: boolean = false;

keycloak
    .init({
        onLoad: "check-sso",
        checkLoginIframe: false,
        responseMode: "query",
    })
    .then((authenticated) => {
        authenticatedState = authenticated
        return authenticated;
    })
    .catch((err) => {
        console.error("Error while authenticating user:", err);
        throw new Error(err);
        return err;
    });

export {keycloak, authenticatedState};