import React, {
  LegacyRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "../SigningWizard.module.css";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import { Input, Spin } from "antd";
import {
  base64_arraybuffer,
  base64_arraybuffer_no_split,
  transformSignatureProfiles,
} from "../../../services/utilityServices";
import { ErrorModalService } from "../../../components/ErrorHandling/ErrorModalService";
import { FaCloudUploadAlt } from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import SignaturePad from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingOutlined } from "@ant-design/icons";
import i18next from "i18next";
import i18n from "i18next";
import axios from "axios";
import SignatureParametersContext from "../../../store/SignatureParametersContext";
import { SignatureProfileSettings } from "../../../components/SignatureProfileComponent/SignatureProfileSettings";
import {
  IeSignLiteSignatureArray,
  IeSignLiteSignatureOnlyArray,
  storage,
} from "../../../services/indexDb";
import { keycloak } from "../../../services/Keycloak";
import { GetAllSignatureCards } from "../../../services/GetAllSignatureCards";
import withoutImage from "../../../images/bez-slike01.svg";
import {GetSignatureCard} from "../../../services/GetSignatureCard";

enum ChosenSignatureImageOption {
  TEXT = "TEXT",
  DRAW = "DRAW",
  IMAGE = "IMAGE",
}

enum StepOneState {
  PROFILES = "PROFILES",
  BASIC = "BASIC",
  DETAILS = "DETAILS",
}

const StepOne = (props: any) => {
  const formState = useFormState();

  const signatureParamsCtx = useContext(SignatureParametersContext);

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [chosenSigImageOption, setChosenSigImageOption] =
    useState<ChosenSignatureImageOption>(ChosenSignatureImageOption.TEXT);
  const [stepOneState, setStepOneState] = useState({
    loadingProfiles: true,
    state: StepOneState.PROFILES as StepOneState,
    imageLoading: false,
    showProfiles: true,
    profiles: [] as any[],
  });
  const [logoImageName, setLogoImageName] = useState<string>("");
  const [typedSignatureValue, setTypedSignatureValue] = useState<string>("");
  const [signatureImagePictureValue, setSignatureImagePictureValue] =
    useState<string>("");

  const sigCanvas = useRef({});
  const textSignatureImage = useRef<HTMLCanvasElement | undefined>(undefined);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  //getting info of window width
  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (chosenSigImageOption === ChosenSignatureImageOption.TEXT) {
      formState.values["UseHandwrittenStyle"] = true;
    } else {
      formState.values["UseHandwrittenStyle"] = false;
    }
  }, [chosenSigImageOption]);

  useEffect(() => {
    setStepOneState((prevState) => ({
      ...prevState,
      loadingProfiles: true,
    }));

    const fetchProfiles = async () => {
      //IF USER IS AUTHENTICATED, THEN WE WANT TO FETCH SIGNATURE PROFILES FROM OUR BACKEND, IF NOT, LOOK FOR LOCAL PROFILES
      if (keycloak.authenticated) {
        try {
          const response = await GetAllSignatureCards();

          if (response?.data && response?.data?.length > 0) {
            let transformedProfiles: any[] = [];
            let serverProfiles = [];
            let signatureImages = response.data;
            for (let i = 0; i < signatureImages.length; i++) {
              const transformedProfile = await transformSignatureProfiles(
                signatureImages[i]
              );
              serverProfiles.push(signatureImages[i]);
              transformedProfiles.push(transformedProfile);
            }

            setStepOneState((prevState) => ({
              ...prevState,
              state: StepOneState.PROFILES,
              profiles: transformedProfiles as any[],
              showProfiles: true,
            }));
          }
        } catch (e) {
          setStepOneState((prevState) => ({
            ...prevState,
            state: StepOneState.PROFILES,
            profiles: [],
            showProfiles: true,
          }));

          console.log(e);
        } finally {
          setTimeout(() => {
            setStepOneState((prevState) => ({
              ...prevState,
              loadingProfiles: false,
            }));
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStepOneState((prevState) => ({
            ...prevState,
            loadingProfiles: false,
          }));
        }, 1000);
        storage
          .getItem<{
            eSignLiteSignature: IeSignLiteSignatureArray;
            eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
          }>("eSignLite")
          .then((result) => {
            if (
              !props.addProfileState &&
              result?.eSignLiteSignature &&
              result.eSignLiteSignature.items &&
              result.eSignLiteSignature.items.length > 0 &&
              !signatureParamsCtx.predefinedSignatureOptions
            ) {
              setStepOneState((prevState) => ({
                ...prevState,
                state: StepOneState.PROFILES,
                profiles: result.eSignLiteSignature.items,
                showProfiles: true,
              }));
            }

            if (
              !props.addProfileState &&
              result?.eSignLiteSignature &&
              result.eSignLiteSignature.items &&
              result.eSignLiteSignature.items.length > 0 &&
              signatureParamsCtx.predefinedSignatureOptions
            ) {
              let filteredProfiles = result.eSignLiteSignature.items.filter(
                (item) =>
                  item.txtType === "IMAGE" &&
                  item.txtSignatureLevel ===
                    signatureParamsCtx.signatureLevel &&
                  item.txtSignatureStandard ===
                    signatureParamsCtx.jurisdiction &&
                  item.txtSignatureProvider ===
                    signatureParamsCtx.signatureProvider
              );

              if (
                filteredProfiles.length > 0 &&
                !signatureParamsCtx.phoneNumber
              ) {
                setStepOneState((prevState) => ({
                  ...prevState,
                  state: StepOneState.PROFILES,
                  profiles: filteredProfiles,
                  showProfiles: true,
                }));
              } else if (
                filteredProfiles.length > 0 &&
                signatureParamsCtx.phoneNumber
              ) {
                let getProfilesWithMatchingPhoneNumber =
                  result.eSignLiteSignature.items.filter(
                    (item) =>
                      item.txtType === "IMAGE" &&
                      item.txtSignatureLevel ===
                        signatureParamsCtx.signatureLevel &&
                      item.txtSignatureStandard ===
                        signatureParamsCtx.jurisdiction &&
                      item.txtSignatureProvider ===
                        signatureParamsCtx.signatureProvider &&
                      (item.txtMobitel ===
                      signatureParamsCtx.phoneNumber?.startsWith("+")
                        ? signatureParamsCtx.phoneNumber?.substring(1)
                        : signatureParamsCtx.phoneNumber)
                  );
                setStepOneState((prevState) => ({
                  ...prevState,
                  state: StepOneState.PROFILES,
                  profiles: getProfilesWithMatchingPhoneNumber,
                  showProfiles: true,
                }));
              } else {
                setStepOneState((prevState) => ({
                  ...prevState,
                  state: StepOneState.BASIC,
                  profiles: [] as any[],
                  showProfiles: false,
                }));
              }
            }
            if (props.addProfileState) {
              setStepOneState((prevState) => ({
                ...prevState,
                state: StepOneState.BASIC,
                profiles: [] as any[],
                showProfiles: false,
              }));
            }
          })
          .catch((e) => console.log(e));
      }
    };

    fetchProfiles().then((_) => {});
  }, [props.addProfileState]);

  useEffect(() => {
    let canvasElement = document.getElementById(
      "signator_text_signature_canvas"
    ) as HTMLCanvasElement;
    let signatorCanvasWrapElement = document.getElementById(
      "signator-image-wrap-element"
    ) as HTMLDivElement;

    if (canvasElement) {
      canvasElement.width = signatorCanvasWrapElement!.offsetWidth;
      canvasElement.height = signatorCanvasWrapElement!.offsetHeight;

      let textToShowAsSignature = typedSignatureValue;
      if (typedSignatureValue.length > 20) {
        textToShowAsSignature = typedSignatureValue.substring(0, 20) + "...";
      }

      let ctx = canvasElement.getContext("2d");
      ctx!.clearRect(0, 0, canvasElement.width, canvasElement.height);
      ctx!.textAlign = "center";
      ctx!.font = windowWidth < 540 ? "6vmin Caveat" : "4vmin Caveat";
      ctx!.fillText(
        textToShowAsSignature,
        canvasElement.width / 2,
        windowWidth < 540
          ? canvasElement.height / 1.8
          : canvasElement.height / 2
      );

      let canvasToGenerateSigImage = document.createElement("canvas");
      canvasToGenerateSigImage.width = windowWidth < 600 ? 320 : 600;
      canvasToGenerateSigImage.height = windowWidth < 600 ? 160 : 300;

      let ctxToGenerateSigImage = canvasToGenerateSigImage.getContext("2d");
      ctxToGenerateSigImage!.clearRect(
        0,
        0,
        canvasToGenerateSigImage.width,
        canvasToGenerateSigImage.height
      );
      ctxToGenerateSigImage!.textAlign = "center";
      ctxToGenerateSigImage!.font = "11vmin Caveat";
      ctxToGenerateSigImage!.fillText(
        textToShowAsSignature,
        windowWidth < 600 ? 150 : 300,
        windowWidth < 600 ? 75 : 150
      );

      textSignatureImage.current = canvasToGenerateSigImage;
    }

    if (chosenSigImageOption !== ChosenSignatureImageOption.IMAGE) {
      setSignatureImagePictureValue("");
    }
  }, [typedSignatureValue, chosenSigImageOption]);

  const saveSignatureImageHandler = () => {
    formState.values["txtSignatureImagePicture"] = (
      sigCanvas?.current as ReactSignatureCanvas
    )
      .toDataURL()
      .split(";base64,")[1];
  };

  const removeSavedSignatureProfileHandler = (profileId: any) => {
    storage
      .getItem<{
        eSignLiteSignature: IeSignLiteSignatureArray;
        eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
      }>("eSignLite")
      .then((result) => {
        try {
          let profileToDelete = result?.eSignLiteSignature.items.findIndex(
            (item) => item.profileId === profileId
          );

          if (result && profileToDelete !== undefined) {
            let profiles = result.eSignLiteSignature.items;
            profiles.splice(profileToDelete, 1);

            if (profiles.length === 0) {
              storage
                .setItem("eSignLite", {
                  eSignLiteSignatureOnly: {
                    ...result?.eSignLiteSignatureOnly,
                  },
                  eSignLiteSignature: {
                    items: profiles,
                  },
                })
                .then((res) => {
                  setStepOneState((prevState) => ({
                    ...prevState,
                    state: StepOneState.BASIC,
                    profiles: [],
                  }));
                  props.addProfileStateHandler(true);
                })
                .catch((e) => console.log(e));
            } else {
              storage
                .setItem("eSignLite", {
                  eSignLiteSignatureOnly: {
                    ...result?.eSignLiteSignatureOnly,
                  },
                  eSignLiteSignature: {
                    items: profiles,
                  },
                })
                .then((res) => {
                  setStepOneState((prevState) => ({
                    ...prevState,
                    profiles: profiles,
                  }));
                })
                .catch((e) => console.log(e));
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  const companyLogoToBase64Handler = async (logo: any) => {
    if (logo[0]) {
      let logoName = logo[0].name;

      if (logoName.length > 20) {
        logoName = logoName.replace(/(.{20})..+/, "$1...");
      }

      if (logo[0].type === "image/png" || logo[0].type === "image/jpeg") {
        if (logo[0].size < 2104000) {
          let base64Logo = await base64_arraybuffer(logo[0]);

          formState.values["txtLogo"] = base64Logo;
          setLogoImageName(logoName);
        } else {
          new ErrorModalService(8).showErrorModal();
        }
      } else {
        new ErrorModalService(7).showErrorModal();
      }
    }
  };

  const signaturePictureChangeHandler = async (image: any) => {
    setStepOneState((prevState) => ({
      ...prevState,
      imageLoading: true,
    }));

    if (image.target.files.length > 1) {
      //TO DO: ADD ERROR HANDLING
      return;
    }
    if (image.target.files[0]) {
      try {
        if (image.target.files[0].size > 2104000) {
          new ErrorModalService(8).showErrorModal();
          setStepOneState((prevState) => ({
            ...prevState,
            imageLoading: false,
          }));
          return;
        }
        await base64_arraybuffer_no_split(image.target.files[0]).then((res) => {
          setSignatureImagePictureValue(res);
          formState.values["txtSignatureImagePicture"] =
            res.split(";base64,")[1];
          setStepOneState((prevState) => ({
            ...prevState,
            imageLoading: false,
          }));
        });
      } catch (err) {
        console.log(err);
        setStepOneState((prevState) => ({
          ...prevState,
          imageLoading: false,
        }));
      }
    }
  };

  const typedSignatureImageHandler = () => {
    let canvasElement = document.getElementById(
      "signator_text_signature_canvas"
    ) as HTMLCanvasElement;

    if (
      canvasElement &&
      textSignatureImage.current &&
      chosenSigImageOption === ChosenSignatureImageOption.TEXT
    ) {
      formState.values["txtSignatureImagePicture"] = textSignatureImage.current
        .toDataURL()
        .split(";base64,")[1];
    }
  };

  const signWithProfileHandler = async (profile: any) => {
    setStepOneState((prevState) => ({
      ...prevState,
      loadingProfiles: true,
    }));

    formState.values["txtName"] = profile.txtName ?? "";
    formState.values["txtMobitel"] = profile.txtMobitel ?? "";
    formState.values["txtCompanyName"] = profile.txtCompanyName ?? "";
    formState.values["txtDepartment"] = profile.txtDepartment ?? "";
    formState.values["txtPosition"] = profile.txtPosition ?? "";
    formState.values["txtSignatureImagePicture"] =
      profile.txtSignatureImagePicture ?? "";
    formState.values["txtSignatureLevel"] = profile.txtSignatureLevel ?? "";
    formState.values["txtSignatureProvider"] =
      profile.txtSignatureProvider ?? "";
    formState.values["txtSignatureStandard"] =
      profile.txtSignatureStandard ?? "";
    formState.values["txtLogo"] = profile.txtLogo ?? "";
    formState.values["UseHandwrittenStyle"] =
      profile.UseHandwrittenStyle ?? !!ChosenSignatureImageOption.TEXT;
    formState.values["Appearance"] =
      profile.Appearance;

    //IF IT'S REMOTE PROFILE, WE NEED TO UPDATE SIG. IMAGE AND LOGO
    if(profile.remoteProfile && profile.profileId){
      try {
        const getSignatureImage = await GetSignatureCard(profile.profileId);

        if(getSignatureImage){
          profile.txtSignatureImagePicture = getSignatureImage.signatureImage;
          profile.txtLogo = getSignatureImage.logo;
        }
      } catch (e) {
        console.log("Unable to fetch signature profile data", e);
      }
    }

    try {
      const createSigImage = await axios.post(
        process.env.REACT_APP_BASE_URL &&
          process.env.REACT_APP_SIGNATURE_IMAGE_PREVIEW_URL
          ? process.env.REACT_APP_BASE_URL +
              process.env.REACT_APP_SIGNATURE_IMAGE_PREVIEW_URL
          : "",
        {
          Page: 0,
          WidgetOffsetX: 231,
          WidgetOffsetY: 359,
          WidgetHeight: 75,
          WidgetWidth: 150,
          UseHandwrittenStyle: false,
          ImageData:
            profile.UseHandwrittenStyle && profile.UseHandwrittenStyle === true
              ? null
              : profile.txtSignatureImagePicture,
          LineOne: profile.txtName,
          LineTwo: profile.txtPosition,
          LineThree: profile.txtDepartment,
          LineFour: profile.txtCompanyName,
          LogoImageData: profile.txtLogo,
          Appearance: profile.Appearance
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + signatureParamsCtx.signatureToken ?? "",
          },
        }
      );

      let createdSignatureImage = await base64_arraybuffer_no_split(
        createSigImage.data
      );

      setStepOneState((prevState) => ({
        ...prevState,
        loadingProfiles: false,
      }));

      props.signWithCreatedSignatureImageHandler(
        profile,
        createdSignatureImage
      );
    } catch (e) {
      new ErrorModalService(30).showErrorModal();
      setStepOneState((prevState) => ({
        ...prevState,
        loadingProfiles: false,
      }));
      console.log(e);
    }
  };

  //IF USER IS LOGGED IN AND DOESN'T HAVE ANY PROFILES, THEN SHOW THE MESSAGE
  if (
    keycloak.authenticated &&
    stepOneState.state === StepOneState.PROFILES &&
    stepOneState.showProfiles &&
    (!stepOneState.profiles || stepOneState?.profiles?.length < 1)
  ) {
    return (
      <Fade>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              marginTop: "25px",
            }}
          >
            <div className={styles.SignatorStepContainer}>
              <div className={styles.SignatorStepTitle}>
                {i18next.t("select_signature_label")}
              </div>
            </div>
            {stepOneState.loadingProfiles ? (
              <Spin
                size="large"
                style={{ marginTop: "150px", width: "100%" }}
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={styles.SignatorStepSubtitle}>
                    {i18n.t("no_profiles_detected")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    );
  }

  //IF USER IS LOGGED IN AND HAS PROFILES, THEN SHOW THE MESSAGE
  if (
    stepOneState.state === StepOneState.PROFILES &&
    stepOneState.showProfiles
  ) {
    return (
      <Fade>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              marginTop: "25px",
            }}
          >
            <div className={styles.SignatorStepContainer}>
              <div className={styles.SignatorStepTitle}>
                {i18next.t("select_signature_label")}
              </div>
            </div>
            {stepOneState.loadingProfiles ? (
              <Spin
                size="large"
                style={{ marginTop: "150px", width: "100%" }}
              />
            ) : (
              <>
                <div className={styles.SignatorStepSubtitle}>
                  {!keycloak.authenticated && (
                    <div
                      onClick={() => {
                        setStepOneState((prevState) => ({
                          ...prevState,
                          state: StepOneState.BASIC,
                        }));
                        props.addProfileStateHandler(true);
                      }}
                      className={styles.SignatorStepSubtitleButton}
                    >
                      <AddPhotoAlternateIcon /> {i18next.t("add_new_signature")}
                    </div>
                  )}
                  {/*<div
                    style={{ color: "red", fontSize: "10px" }}
                    className={styles.SignatorStepSubtitleButton}
                    onClick={() => {
                      setStepOneState((prevState) => ({
                        ...prevState,
                        state: StepOneState.BASIC,
                      }));
                      removeAllSavedProfiles();
                    }}
                  >
                    {i18next.t("delete_all_profiles")}
                  </div>*/}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {stepOneState.profiles
                    .filter((item) => item.txtType === "IMAGE")
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "65%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          className={styles.Signator_SignatureProfileWithImage}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "3px",
                            }}
                          >
                            <span
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              {item.txtSigImageProfileName}
                            </span>
                            {!keycloak.authenticated && (
                              <span
                                onClick={() =>
                                  removeSavedSignatureProfileHandler(
                                    item.profileId
                                  )
                                }
                              >
                                <DeleteIcon
                                  style={{ color: "red", cursor: "pointer" }}
                                />
                              </span>
                            )}
                          </div>
                          <div
                            className={`SignatorSignatureProfileCard${
                              item.txtSignatureLevel ?? "Local"
                            }`}
                            draggable={true}
                            onClick={() => signWithProfileHandler(item)}
                            onDrag={() => signWithProfileHandler(item)}
                          >
                            <div
                              className={styles.SignatorSignatureProfileCardTop}
                            >
                              <img
                                src={
                                  keycloak.authenticated
                                    ? withoutImage
                                    : "data:image/png;base64, " +
                                      item.txtSignatureImagePicture
                                }
                                width={150}
                                height={75}
                                alt={"Signature image"}
                                className={
                                  styles.Signator_SignatureImageInProfile
                                }
                              ></img>
                              <div
                                className={
                                  styles.SignatorSignatureProfileDetails
                                }
                              >
                                <div
                                  className={
                                    styles.SignatorSignatureProfileBasicDetails
                                  }
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.txtName ?? "N/A"}
                                  </span>
                                  {item.txtSignatureProvider !==
                                    "ExternalLocalComponent" && (
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.txtMobitel}
                                    </span>
                                  )}
                                </div>
                                <div
                                  className={
                                    styles.SignatorSignatureProfileBasicDetailsPlus
                                  }
                                >
                                  {item.txtPosition && (
                                    <span
                                      style={{ fontSize: "11px" }}
                                      className={
                                        styles.Signator_SignatureProfileSignatureDetails_Text
                                      }
                                    >
                                      {item.txtPosition}
                                    </span>
                                  )}
                                  {item.txtDepartment && (
                                    <span
                                      style={{ fontSize: "11px" }}
                                      className={
                                        styles.Signator_SignatureProfileSignatureDetails_Text
                                      }
                                    >
                                      {item.txtDepartment}
                                    </span>
                                  )}
                                  {item.txtCompanyName && (
                                    <span
                                      style={{ fontSize: "11px" }}
                                      className={
                                        styles.Signator_SignatureProfileSignatureDetails_Text
                                      }
                                    >
                                      {item.txtCompanyName}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <SignatureProfileSettings profile={item} />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/*<div className={styles.SignatorStepContainer}>
                  <div
                      className={styles.SignatorStepSubtitle}
                      onClick={() => {
                        setStepOneState((prevState) => ({
                          ...prevState,
                          state: StepOneState.BASIC,
                        }));
                        props.addProfileStateHandler(true);
                      }}
                  >
                    <AddPhotoAlternateIcon/> {i18next.t("add_new_signature")}
                  </div>
                </div>*/}
              </>
            )}
          </div>
        </div>
      </Fade>
    );
  }

  //DEFAULT RETURN RETURNS CREATE LOCAL PROFILE FORM
  return (
    <Fade>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <div className={styles.SignatorStepContainer}>
            {props.requiredFieldNotEmpty ? (
              <div className={styles.SignatorStepTitle}>
                {i18next.t("define_sig_image")}
              </div>
            ) : (
              <div className={styles.SignatorStepTitleError}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ErrorOutlineIcon style={{ marginRight: "4px" }} />
                  {i18next.t("define_sig_image")}
                </div>
                <span style={{ fontSize: "11px" }}>
                  {i18next.t("define_sig_image_error")}
                </span>
              </div>
            )}
          </div>
          {stepOneState.state === StepOneState.BASIC && (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginLeft: "29%",
                  flexDirection: "column",
                }}
              >
                <p>{i18next.t("first_and_last_name")}:</p>
                <Input
                  style={{ width: "80%" }}
                  placeholder={i18next.t("first_and_last_name")}
                  onBlur={typedSignatureImageHandler}
                  onChange={(e) => {
                    setTypedSignatureValue(e.target.value);
                    formState.values["txtName"] = e.target.value;
                  }}
                  value={typedSignatureValue}
                />
              </div>
              <div className={styles.SignatorSignatureImageContainer}>
                <div style={{ width: "27%" }}>
                  <div
                    className={
                      chosenSigImageOption === ChosenSignatureImageOption.TEXT
                        ? styles.SignatureImageOptionsActive
                        : styles.SignatureImageOptions
                    }
                    style={{ marginBottom: "20px" }}
                    onClick={() =>
                      setChosenSigImageOption(ChosenSignatureImageOption.TEXT)
                    }
                  >
                    {i18next.t("text_image_label")}
                  </div>
                  <div
                    className={
                      chosenSigImageOption === ChosenSignatureImageOption.DRAW
                        ? styles.SignatureImageOptionsActive
                        : styles.SignatureImageOptions
                    }
                    style={{ marginBottom: "20px" }}
                    onClick={() =>
                      setChosenSigImageOption(ChosenSignatureImageOption.DRAW)
                    }
                  >
                    {i18next.t("draw_image_label")}
                  </div>
                  <div
                    className={
                      chosenSigImageOption === ChosenSignatureImageOption.IMAGE
                        ? styles.SignatureImageOptionsActive
                        : styles.SignatureImageOptions
                    }
                    onClick={() =>
                      setChosenSigImageOption(ChosenSignatureImageOption.IMAGE)
                    }
                  >
                    <label
                      htmlFor="signatorSignatureImage"
                      style={{ cursor: "pointer" }}
                    >
                      {i18next.t("upload_image_label")}
                    </label>
                  </div>
                  <input
                    name="signatorSignatureImage"
                    id="signatorSignatureImage"
                    className={styles.Signator_FileUploadInput}
                    type="file"
                    onChange={signaturePictureChangeHandler}
                    accept="image/png, image/jpeg"
                  />
                </div>
                {chosenSigImageOption === ChosenSignatureImageOption.TEXT && (
                  <div
                    className={styles.SignatorImageWrap}
                    id={"signator-image-wrap-element"}
                  >
                    {typedSignatureValue ? (
                      <canvas
                        id="signator_text_signature_canvas"
                        height={windowWidth < 540 ? 120 : 158}
                      ></canvas>
                    ) : (
                      <div className={styles.SignatorTypedSignature}>
                        {i18next.t("type_your_name")}
                      </div>
                    )}
                  </div>
                )}
                {chosenSigImageOption === ChosenSignatureImageOption.DRAW && (
                  <div className={styles.SignatorImageWrap}>
                    <SignaturePad
                      ref={sigCanvas as LegacyRef<ReactSignatureCanvas>}
                      penColor="black"
                      backgroundColor="transparent"
                      canvasProps={{
                        className: "sigCanvas",
                      }}
                      onEnd={saveSignatureImageHandler}
                    />
                  </div>
                )}
                {chosenSigImageOption === ChosenSignatureImageOption.IMAGE &&
                  !stepOneState.imageLoading && (
                    <div
                      className={styles.SignatorImageWrap}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      {signatureImagePictureValue ? (
                        <img
                          src={signatureImagePictureValue}
                          style={{
                            height: "auto",
                            width: "auto",
                            maxWidth: "100%",
                            aspectRatio: "2 / 1",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <label
                          htmlFor="signatorSignatureImage"
                          style={{ cursor: "pointer" }}
                        >
                          <div className={styles.SignatorTypedSignature}>
                            {i18next.t("select_picture_label")}
                          </div>
                        </label>
                      )}
                    </div>
                  )}
                {chosenSigImageOption === ChosenSignatureImageOption.IMAGE &&
                  stepOneState.imageLoading && (
                    <div
                      className={styles.SignatorImageWrap}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Spin
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        indicator={antIcon}
                      />
                    </div>
                  )}
              </div>
              {chosenSigImageOption === ChosenSignatureImageOption.DRAW && (
                <div
                  style={{
                    width: "86%",
                    textAlign: "end",
                    marginTop: "5px",
                    color: "#5bb5f5",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    (sigCanvas.current as ReactSignatureCanvas).clear()
                  }
                >
                  {i18next.t("clear_label")}
                </div>
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  color: "#5bb5f5",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "15%",
                }}
                onClick={() => {
                  setStepOneState((prevState) => ({
                    ...prevState,
                    state: StepOneState.DETAILS,
                  }));
                }}
              >
                {i18next.t("add_additional_label")}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "40px",
                  justifyContent: "space-evenly",
                }}
              >
                <button
                  className={styles.NextButton}
                  onClick={() =>
                    props.nextWithImage(
                      formState.values["txtSignatureImagePicture"]
                    )
                  }
                >
                  {i18next.t("next_label")}
                </button>
              </div>
            </div>
          )}
          {stepOneState.state === StepOneState.DETAILS && (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginLeft: "20%",
                  flexDirection: "column",
                }}
              >
                <p>{i18next.t("company_name")}:</p>
                <Input
                  style={{ width: "90%", marginBottom: "15px" }}
                  placeholder={i18next.t("company_name")}
                  onChange={(e) =>
                    (formState.values["txtCompanyName"] = e.target.value)
                  }
                  value={formState.values["txtCompanyName"] ?? undefined}
                />
                <p>{i18next.t("dept_label")}:</p>
                <Input
                  style={{ width: "90%", marginBottom: "15px" }}
                  placeholder={i18next.t("dept_label")}
                  onChange={(e) =>
                    (formState.values["txtDepartment"] = e.target.value)
                  }
                  value={formState.values["txtDepartment"] ?? undefined}
                />

                <p>{i18next.t("position_label")}:</p>
                <Input
                  style={{ width: "90%", marginBottom: "15px" }}
                  placeholder={i18next.t("position_label")}
                  onChange={(e) =>
                    (formState.values["txtPosition"] = e.target.value)
                  }
                  value={formState.values["txtPosition"] ?? undefined}
                />
                <p>{i18next.t("upload_logo_label")}:</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="signeeLogo"
                    className={styles.Signator_FileUploadLabel}
                  >
                    <FaCloudUploadAlt
                      style={{ marginRight: "6px", paddingBottom: "2px" }}
                    />
                    {i18next.t("upload_image_label")}
                  </label>
                  <input
                    name="signeeLogo"
                    id="signeeLogo"
                    className={styles.Signator_FileUploadInput}
                    type="file"
                    onChange={(e) => companyLogoToBase64Handler(e.target.files)}
                    accept="image/png, image/jpeg"
                  />
                  <p style={{ marginLeft: "10px" }}>{logoImageName}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "40px",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className={styles.PreviousStepButton}
                  onClick={() => {
                    setStepOneState((prevState) => ({
                      ...prevState,
                      state: StepOneState.BASIC,
                    }));
                  }}
                >
                  {i18next.t("back_step_label")}
                </div>

                <div
                  className={styles.NextButton}
                  onClick={() =>
                    props.nextWithImage(
                      formState.values["txtSignatureImagePicture"]
                    )
                  }
                >
                  {i18next.t("next_label")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default StepOne;
