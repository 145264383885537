import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "react-final-form";
import { Drawer } from "antd";
import { Fade } from "react-awesome-reveal";
import { DEFAULT_SIGNING_MODEL } from "../../services/signingModel";
import StepTwo from "./SigningWizardPages/StepTwo";
import StepperComponent from "./StepperComponent";
import { v4 as uuid } from "uuid";
import { ErrorModalService } from "../../components/ErrorHandling/ErrorModalService";
import i18next from "i18next";
import {
  createSignatureImage, initOffice,
  isNameEmpty, officeFunctionHandler,
} from "../../services/utilityServices";
import StepOne from "./SigningWizardPages/StepOne";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import {
  IeSignLiteSignatureArray,
  IeSignLiteSignatureOnlyArray,
  storage,
} from "../../services/indexDb";
import AuthContext from "../../store/AuthContext";
import StepThreeSuite from "./SigningWizardPages/StepThreeSuite";
import {useNavigate} from "react-router-dom";

interface IWizardState {
  currentPage: number;
}

const initialState: IWizardState = {
  currentPage: 0,
};

const SigningWizard = (props: any) => {
  const signingCtx = useContext(SignatureParametersContext);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [state, setState] = useState<IWizardState>(initialState);
  const pages = useMemo(() => [StepOne, StepTwo, StepThreeSuite], []);
  const [addProfileState, setAddProfileState] = useState(false);
  const [requiredFieldNotEmpty, setRequiredFieldNotEmpty] = useState(true);
  const [waitForSignatureImageRes, setWaitForSignatureImageRes] =
    useState(false);
  const [widthForDrawer, setWidthForDrawer] = useState<number>(
    window.innerWidth
  );

  const unique_id = uuid();

  //getting info of window width
  function handleWindowSizeChange() {
    setWidthForDrawer(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        currentPage: props.startingStep ?? 0,
      }));
      if (props.createNewProfile) {
        setAddProfileState(true);
        setRequiredFieldNotEmpty(true);
      } else {
        setAddProfileState(false);
        setRequiredFieldNotEmpty(true);
      }
  }, [props.signingDrawerOpen]);

  async function officeOnReady() {
    await Office.onReady(() => {
      if (window.parent) {
        Office.context?.ui?.messageParent("START_WF_SUCCESS", {
          targetOrigin: process.env.REACT_APP_TARGET_ORIGIN ?? "",
        });
        Office.context?.ui?.messageParent("START_WF_SUCCESS", {
          targetOrigin: process.env.REACT_APP_MICROSOFT_TARGET_ORIGIN ?? "",
        });
      }
    });
  }

  async function handleCloseIframe() {
    if (window.location === window.parent.location) {
      console.log("Application is not loaded through iframe.");
      window.location.reload();
    }

    if (window.parent) {
      window.parent.postMessage(
        {
          type: "SIGNATOR_SIGNING_SUCCESS",
        },
        "*"
      );
    }

    await officeFunctionHandler(officeOnReady);
  }

  const nextSlide = useCallback(() => {
    if (state.currentPage < pages.length) {
      setState((prevState) => ({
        ...prevState,
        currentPage: Math.min(state.currentPage + 1, pages.length),
      }));
    }
  }, [state, pages]);

  const nextWithImage = useCallback(
    (signatureImage: any) => {
      if (signatureImage === undefined) {
        setRequiredFieldNotEmpty(false);
        return;
      } else {
        setAddProfileState(true);
        nextSlide();
      }
    },
    [state, pages]
  );

  const skipHandler = useCallback(() => {
    if (state.currentPage < pages.length) {
      setState((prevState) => ({
        ...prevState,
        currentPage: Math.min(state.currentPage + 1, pages.length),
      }));
    }
    props.removeSignatureHandler();
  }, [state, pages]);

  const previousSlide = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      currentPage: Math.max(state.currentPage - 1, 0),
    }));
  }, [state, pages]);

  //this submit function handles saving profile, and opening third page of drawer where signing is being executed
  const onSubmit = useCallback(
    async (values) => {
      //setting T&C accepted values to storage
      if (values.txtAcceptTC === true) {
        localStorage.setItem("eSignLiteTCAccepted", "true");
      }

      //checking if required fields are empty and returning value
      if (state.currentPage === 0) {
        if (values.txtName === undefined) {
          setRequiredFieldNotEmpty(false);
        }
        return;
      }

      //checking if required fields are empty and returning value
      if (state.currentPage === pages.length - 1) {
        return;
      } else if(state.currentPage === 1){
          if (values.txtSignatureImagePicture) {
            storage
              .getItem<{
                eSignLiteSignature: IeSignLiteSignatureArray;
                eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
              }>("eSignLite")
              .then((result) => {
                let newProfileToAdd = {
                  ...values,
                  txtType: values.txtSignatureImagePicture
                    ? "IMAGE"
                    : "NO_IMAGE",
                  UseHandwrittenStyle: values.txtSignatureImagePicture ? values.UseHandwrittenStyle : null,
                  default: false,
                  profileId: unique_id,
                };

                if (
                  result?.eSignLiteSignature &&
                  result?.eSignLiteSignature.items &&
                  result?.eSignLiteSignature.items.length > 0
                ) {
                  let updatedArray = result.eSignLiteSignature.items;
                  updatedArray.push(newProfileToAdd);
                  storage.setItem("eSignLite", {
                    eSignLiteSignatureOnly: {
                      ...result?.eSignLiteSignatureOnly,
                    },
                    eSignLiteSignature: { items: [...updatedArray] },
                  });
                } else {
                  newProfileToAdd = { ...newProfileToAdd };
                  storage.setItem("eSignLite", {
                    eSignLiteSignatureOnly: {
                      ...result?.eSignLiteSignatureOnly,
                    },
                    eSignLiteSignature: {
                      items: [newProfileToAdd],
                    },
                  });
                }

                if (props.signingWithSignatureImage) {
                  setWaitForSignatureImageRes(true);

                  createSignatureImage(newProfileToAdd, signingCtx.signatureToken ?? "")
                    .then((response) => {
                      setWaitForSignatureImageRes(false);

                      props.signWithCreatedSignatureImageHandler(
                        newProfileToAdd,
                        response.responseImage
                      );
                    })
                    .catch((e) => {
                      new ErrorModalService(30).showErrorModal();
                      setWaitForSignatureImageRes(false);
                      console.log(e);
                      return;
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                new ErrorModalService(29).showErrorModal();
              });
          }

          if (!values.txtSignatureImagePicture) {
            storage
              .getItem<{
                eSignLiteSignature: IeSignLiteSignatureArray;
                eSignLiteSignatureOnly: IeSignLiteSignatureOnlyArray;
              }>("eSignLite")
              .then((result) => {
                let newProfileToAdd = {
                  ...values,
                  txtType: values.txtSignatureImagePicture
                    ? "IMAGE"
                    : "NO_IMAGE",
                  default: false,
                  profileId: unique_id,
                };

                if (
                  result?.eSignLiteSignatureOnly &&
                  result?.eSignLiteSignatureOnly.items &&
                  result.eSignLiteSignatureOnly?.items.length > 0
                ) {
                  let updatedArray = result.eSignLiteSignatureOnly.items;
                  updatedArray.push(newProfileToAdd);
                  storage.setItem("eSignLite", {
                    eSignLiteSignature: {
                      ...result?.eSignLiteSignature,
                    },
                    eSignLiteSignatureOnly: { items: [...updatedArray] },
                  });
                } else {
                  newProfileToAdd = { ...newProfileToAdd };
                  storage.setItem("eSignLite", {
                    eSignLiteSignature: {
                      ...result?.eSignLiteSignature,
                    },
                    eSignLiteSignatureOnly: {
                      items: [newProfileToAdd],
                    },
                  });
                }

                props.closeSigningDrawer();
                return;
              })
              .catch((e) => {
                console.log(e);
                new ErrorModalService(29).showErrorModal();
              });
          }
      } else {
        nextSlide();
      }
    },
    [state, pages.length, nextSlide]
  );

  const signWithSavedProfile = () => {
    authCtx.setIsSigningActive(true);
    setState((prevState) => ({
      ...prevState,
      currentPage: 2,
    }));
  };

  const onDrawerClose = () => {
    window.postMessage({ status: "SIGNATOR_DRAWER_CLOSED" });
    authCtx.setUpdateBalance(new Date());

    if (signingCtx.loadedInIframe) {
      authCtx.setIsSigningActive(false);
      if (state.currentPage === 2) {
        props.closeSigningDrawer();
        handleCloseIframe().then(_ => {});
      } else {
        let resetFormButton = document.getElementById(
          "signator-reset-form-button"
        ) as HTMLButtonElement;

        setState((prevState) => ({
          ...prevState,
          currentPage: 0,
        }));
        resetFormButton.click();

        props.closeSigningDrawer();
      }
    } else {
      authCtx.setIsSigningActive(false);

      if (state.currentPage === 2) {
        window.open(window.location.origin, "_self")
      } else {
        let resetFormButton = document.getElementById(
          "signator-reset-form-button"
        ) as HTMLButtonElement;

        setState((prevState) => ({
          ...prevState,
          currentPage: 0,
        }));
        resetFormButton.click();

        props.closeSigningDrawer();
      }
    }
  };

  const CurrentPage = pages[state.currentPage];
  return (
    <Drawer
      title={
        authCtx.isSigningActive
          ? i18next.t("document_signing_active")
          : i18next.t("define_sig_header")
      }
      placement="right"
      onClose={onDrawerClose}
      visible={props.signingDrawerOpen}
      destroyOnClose={true}
      maskClosable={false}
      width={
        authCtx.isSigningActive
           ? widthForDrawer < 700 ? "100%" : "50%"
          : widthForDrawer < 1450
          ? widthForDrawer < 700
            ? "95%"
            : "55%"
          : "50%"
      }
      push={{
        distance: authCtx.isSigningActive
          ? 450
          : widthForDrawer < 1200
          ? widthForDrawer < 700
            ? 65
            : 250
          : 450,
      }}
    >
      <Fade>
        <StepperComponent currentPage={state.currentPage} />
        <Form onSubmit={onSubmit} initialValues={DEFAULT_SIGNING_MODEL}>
          {({ handleSubmit, form }) => {
            return (
              <form id="main-form" onSubmit={handleSubmit}>
                <CurrentPage
                  numPages={pages.length}
                  currentPage={state.currentPage}
                  closeDrawer={props.closeSigningDrawer}
                  removeSignatureHandler={props.removeSignatureHandler}
                  signingWithSignatureImage={props.signingWithSignatureImage}
                  requiredFieldNotEmpty={requiredFieldNotEmpty}
                  onNext={nextSlide}
                  nextWithImage={nextWithImage}
                  onPrevious={previousSlide}
                  pagesLength={pages.length}
                  skipHandler={skipHandler}
                  signatureImage={props.signatureImage}
                  signWithCreatedSignatureImageHandler={
                    props.signWithCreatedSignatureImageHandler
                  }
                  waitingForSignatureImage={waitForSignatureImageRes}
                  signWithImageContinue={props.signWithImageContinue ?? false}
                  directSignWithoutImage={props.directSignWithoutImage ?? false}
                  signingImageProfile={props.signingImageProfile}
                  signWithSavedProfile={signWithSavedProfile}
                  addProfileStateHandler={setAddProfileState}
                  addProfileState={addProfileState}
                  widthParam={widthForDrawer}
                />
                <button
                  hidden={true}
                  type={"button"}
                  id={"signator-reset-form-button"}
                  onClick={(e: any) => {
                    e.preventDefault();
                    form.reset();
                  }}
                />
              </form>
            );
          }}
        </Form>
      </Fade>
    </Drawer>
  );
};

export default SigningWizard;
